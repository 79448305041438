import React, { useCallback, useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import Button from '../Elements/Button'
import FormField from '../Elements/FormField'
import Loader from '../Elements/Loader'
import Textarea from '../Elements/Textarea'
import ConnectionRelation from '../ConnectionRelation'

import MediaRowUploadProgress from './MediaRowUploadProgress'
import MediaRowUploadPreview from './MediaRowUploadPreview'

import model from '../../model'
import utilStr from '../../utility/str'

export default function MediaRowUpload({ handleRemoveMedia, ind, media }) {

    const {  name, type } = media

    const [loading, setLoading] = useState(false)
    const [jobs, setJobs] = useState([])

    const [rec, setRec] = useState({
        name: utilStr.removeExtension({ str: name }),
        type,
    })

    const recStore = useSelector(state => model.selector('subject.rec', { state, subject:{
        model: 'media',
        id: media.rec ? media.rec._id : null
    } })) || {}

    const doJobs = useCallback(async () => {
        if (jobs.length < 1) return 

        const job = jobs.slice(0,1)
        await job()
        setJobs(jobs.slice(1))
    }, [jobs])

    const addJob = useCallback(async (job) => {
        setJobs([
            ...jobs,
            job
        ])
    }, [jobs])

    const handlePropSet = (prop, val) => {
        setRec({
            ...rec,
            [prop]: val
        })
    }

    //const sizeReadable = utilFile.getSizeReadable({ bytes: file.size, si: true })

    const handleCancel = ()=>{
        setRec(recStore)
    }

    const handleSave = async () => {
        
        try {
            if (!media.rec || !media.rec._id) throw new Error('media not yet uploaded - cannot save')
            setLoading(true)
            await model.action('media.update', {
                id: media.rec ? media.rec._id : null,
                caption: rec.caption,
                name: rec.name,
            })
            await model.action('app.setAlert', {
                message: 'media saved',
                theme: 'success'
            })

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })

        }
    }

    const handleMediaUploadFail = async () => {
        handleRemoveMedia({ ind })
    }

    const handleConnectionAdd = async ({ relation, subject })=>{
        if (!media.rec){
            addJob(async ()=>{
                await handleConnectionAdd({ relation, subject })
            })
        }

        try {
            setLoading(true)
            await model.action('media.connectionAdd', {
                id: media.rec._id,
                relation,
                subject,
            })
            await model.action('app.setAlert', {
                message: 'connection added',
                theme: 'success'
            })
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })
        }
    }

    const handleConnectionRemove = async ({ relation, subject })=>{
        if (!media.rec){
            addJob(async ()=>{
                await handleConnectionAdd({ relation, subject })
            })
        }

        try {
            setLoading(true)
            await model.action('media.connectionRemove', {
                id: media.rec._id,
                relation,
                subject,
            })
            await model.action('app.setAlert', {
                message: 'connection removed',
                theme: 'success'
            })
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })
        }
    }

    useEffect(()=>{
        if (media.rec){
            doJobs()
        }
    }, [doJobs, jobs, media.rec])

    const connectionRelations = model.helper('media.getRelationOptions')

    const isChanged = rec.name !== recStore.name || (rec.caption && rec.caption !== recStore.caption)

    /*
        <div className="grid grid-cols-2">
                                <div className="flex flex-col items-stretch px-6 gap-1.5">
                                    <div className="text-slate-800 font-normal text-sm">
                                        size
                                    </div>
                                    <div className="text-slate-800 font-semibold text-base">
                                        {sizeReadable}
                                    </div>
                                </div>
                                <div className="flex flex-col items-stretch px-6 gap-1.5">
                                    <div className="text-slate-800 font-normal text-sm">
                                        type
                                    </div>
                                    <div className="text-slate-800 font-semibold text-base">
                                        {type}
                                    </div>
                                </div>
                            </div>
    */

    return (
        <div className="flex flex-col lg:flex-row w-full border-b-2 border-slate-900 relative">
            <MediaRowUploadPreview media={media}/>
            <div className="flex-grow w-full lg:max-w-[500px] relative bg-slate-900 flex flex-col justify-stretch">

                <MediaRowUploadProgress
                    ind={ind}
                    handleRemoveMedia={handleRemoveMedia}
                    media={media} />

                <div className="flex flex-col grow bg-white py-6">
                    <div className="2xl:col-span-2">
                        <div className="flex flex-col items-stretch gap-6">
                            <div className="flex flex-col items-stretch gap-6 px-6">
                            <FormField
                                label="name"
                                name="name"
                                size="md">
                                <Textarea placeholder="media name" setValue={val => handlePropSet('name', val)} size="md" value={rec.name} />
                            </FormField>
                            <FormField
                                label="caption"
                                name="caption"
                                size="md">
                                <Textarea placeholder="media caption" setValue={val => handlePropSet('caption', val)} size="md" value={rec.caption} />
                            </FormField>
                            </div>

                            {connectionRelations.map(relation => {
                                return (
                                    <ConnectionRelation
                                        isEditable={relation.value !== 'uploader'}
                                        handleConnectionAdd={handleConnectionAdd}
                                        handleConnectionRemove={handleConnectionRemove}
                                        key={relation.value}
                                        relation={relation.value}
                                        subjects={recStore.connections? recStore.connections[relation.value]: []}
                                        theme={'dark'} />
                                )
                            })}
                        </div>
                    </div>
                </div>
                {isChanged && (
                    <div className={`w-full h-16 sticky bottom-0 z-20 flex flex-row gap-4 px-6 items-center grow bg-slate-950`}>
                        <Button className="ml-auto" disabled={!isChanged} onClick={handleCancel} theme="">CANCEL</Button>
                        <Button className="" disabled={!isChanged} onClick={handleSave} theme="primary">SAVE</Button>
                    </div>
                )}
            </div>
            {media.status === 'error' && (
                <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-slate-800/70">
                    <div className="bg-white rounded-lg">
                        <div className="text-white font-semibold text-lg rounded-t-lg bg-rose-800 px-5 py-5">
                            Media Upload Failed
                        </div>
                        <div className="flex flex-col items-stretch gap-5 py-5">
                            <div className="text-slate-800 px-5 font-normal">
                                We're not sure why this happened but here's what the system is telling us:
                            </div>
                            <div className="text-slate-800 px-5 font-semibold">
                                {media.error}
                            </div>
                            <div className="text-slate-800 px-5 font-normal">
                                We'll have to cancel this download. Sorry about that but feel free to try again.
                            </div>
                            <div className="flex flex-row justify-end px-5" onClick={handleMediaUploadFail}>
                                <Button className="">OK</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className={`${loading ? 'opacity-100' : 'opacity-0 pointer-events-none'} flex flex-row items-center justify-center absolute top-0 bottom-0 left-0 right-0 bg-slate-900/80`}>
                <Loader />
            </div>
        </div>
    )
}