let actions = {}, actionsLambda = {}, actionsLambdaPrivate = {}, helpers = {}, selectors = {}

const action = async (action, payload)=>{
	if (!actions[action]) throw new Error(`model.action: no action subscribed for ${action}`)
	return await actions[action](payload)
}
const actionLambda = async (action, payload)=>{
	if (!actionsLambda[action]) throw new Error(`model.actionLambda: no actionLambda subscribed for ${action}`)
	return await actionsLambda[action](payload)
}
const actionLambdaPrivate = async (action, payload)=>{
	if (!actionsLambdaPrivate[action]) throw new Error(`model.actionLambdaPrivate: no actionLambdaPrivate subscribed for ${action}`)
	return await actionsLambdaPrivate[action](payload)
}
const helper = (helper, payload)=>{
	if (!helpers[helper]) throw new Error(`model.helper: no helper subscribed for ${helper}`)
	return helpers[helper](payload)
}
const selector = (selector, payload)=>{
	if (!selectors[selector]) throw new Error(`model.selector: no selector subscribed for ${selector}`)
	return selectors[selector](payload)
}

const registerActions = (model, acts = {})=>{
	if (!model) throw new Error('model.registerActions: no model')
	for (let i in acts){
		actions[`${model}.${i}`] = acts[i]
	}
}
const registerActionsLambda = (model, acts = {})=>{
	if (!model) throw new Error('model.registerActionsLambda: no model')
	for (let i in acts){
		actionsLambda[`${model}.${i}`] = acts[i]
	}
}
const registerActionsLambdaPrivate = (model, acts = {})=>{
	if (!model) throw new Error('model.registerActionsLambdaPrivate: no model')
	for (let i in acts){
		actionsLambdaPrivate[`${model}.${i}`] = acts[i]
	}
}
const registerHelpers = (model, register = {})=>{
	if (!model) throw new Error('model.registerHelpers: no model')
	for (let i in register){
		helpers[`${model}.${i}`] = register[i]
	}
}
const registerSelectors = (model, register = {})=>{
	if (!model) throw new Error('model.registerSelectors: no model')
	for (let i in register){
		selectors[`${model}.${i}`] = register[i]
	}
}

const model = {
	action,
	actionLambda,
	actionLambdaPrivate,
	helper,
	selector,
	registerActions,
	registerActionsLambda,
	registerActionsLambdaPrivate,
	registerHelpers,
	registerSelectors,
}

export default model