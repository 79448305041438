import React from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import model from '../../model'

import { ReactComponent as CloseIcon } from '../../icon/close.svg'
import { ReactComponent as MemberIcon } from '../../icon/member.svg'

import ArrowGo from '../Elements/ArrowGo'
import ButtonIcon from '../Elements/ButtonIcon'
import DotMenu from '../Elements/DotMenu'
import GrixRowConnections from '../GrixRowConnections'
import MarkdownText from '../Elements/MarkdownText'

export default function MemberGrixRow({ handleClose, pageSubject, setView, subject, view }) {
    const rec = useSelector(state => model.selector('subject.rec', { state, subject })) || {}

    const isAdmin = useSelector(state => model.selector('member.isAdmin', { state }))
    const isSelf = useSelector(state => model.selector('member.isSelf', { state, memberId: subject.id }))

    const navigate = useNavigate()

    const title = model.helper('member.getTitle', { rec })

    const path = model.helper('member.getPathFromSubject', {subject})

    const handleGo = () => {
        const path = model.helper('member.getPathFromSubject', { subject })
        navigate(path)
    }

    let options = []

    if (isSelf || isAdmin) options.push({
        label: 'edit',
        onClick: () => {
            navigate(`${path}/edit`)
        },
    })

    if (isSelf || isAdmin) options.push({
        label: 'edit connections',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'connectionsEdit',
                connections: rec && rec.connections ? rec.connections : {},
                subject,
            })
        }
    });

    if (isAdmin) options.push({
        label: 'invite',
        onClick: async () => {
            await model.action('app.setModal', {
                member: rec,
                modal: 'memberInvite'
            })
        },
    })
    if (isSelf || isAdmin) options.push({
        label: 'display media',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'displayMediaSelect',
                subject
            })

        }
    })

    if (isAdmin) options.push({
        label: 'remove',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'subjectRemove',
                subject
            })

        }
    })

    return (

        <div className="bg-slate-900 flex flex-col items-stretch w-full relative col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-4 2xl:col-span-4">
            <div className="w-full h-14 flex flex-row gap-4 px-6 bg-slate-900 justify-start items-center">
                <MemberIcon className="w-4 h-4 text-lime-400" />
                <div className="text-white font-semibold text-base">
                    member
                </div>
                <ArrowGo onClick={handleGo}/>
                <DotMenu direction="right" options={options} theme="light" />
            </div>

            <div className="w-full bg-slate-900 flex flex-col md:flex-row justify-center items-start pt-6 pb-12">
                <div className="w-full md:w-2/3 px-6 shrink-0 flex flex-col gap-6">
                    <div className="text-2xl xl:text-3xl font-bold text-white mb-0 leading-12">{title}</div>
                    {rec.about && (
                        <MarkdownText className="font-regular text-base xl:text-lg text-slate-100">{rec.about}</MarkdownText>
                    )}
                </div>
                <div className="w-full md:w-1/3 shrink-0">
                    <GrixRowConnections pageSubject={pageSubject} subject={subject}/>
                </div>
            </div>

            <div className="absolute top-3 right-3 flex flex-row items-center gap-1.5">
                {handleClose && (
                    <ButtonIcon onClick={handleClose} theme="transparent">
                        <CloseIcon className="text-white/50 group-hover:text-white w-6 h-6" />
                    </ButtonIcon>
                )}
            </div>

        </div>
    )
}