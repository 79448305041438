import React from 'react'

import { useSelector } from 'react-redux'

import model from '../../model'

import ChapterNugget from '../ChapterNugget'
import LocationNugget from '../LocationNugget'
import MediaNugget from '../MediaNugget'
import MemberNugget from '../MemberNugget'
import ProjectNugget from '../ProjectNugget'

export default function SubjectRow(payload) {
    const { subject } = payload

    const rec = useSelector(state => model.selector('subject.rec', { state, subject }))

    if (!rec) {
        return <div />
    }

    switch (subject.model) {
        case 'chapter':
            return <ChapterNugget rec={rec} {...payload} />
        case 'location':
            return <LocationNugget rec={rec} {...payload} />
        case 'media':
            return <MediaNugget rec={rec} {...payload} />
        case 'member':
            return <MemberNugget rec={rec} {...payload} />
        case 'project':
            return <ProjectNugget rec={rec} {...payload} />
        default:
            return <div>No subject nugget for: {subject.model}</div>
    }
}


