const model = 'event'
const collection = 'event'

// input, output, overview, steps, title

const eventHandles = {
	jamberry: '64bf3ca2013ec01118ed7cc1',
	minga: '6452ca69c0dca426e6df5db4',
}

const fields = {
	_id:{
		type: 'obj',
	},
	connections:{
		type: 'obj', 
		key: {
			type: 'str', 
			label: 'relation'
		}, // uploader, featured (members in media), associated (chapters / events),
		value:{
			type:'ary',
			item: {
				type:'obj',
				shape:{
					model: {
						type: 'str'
					},
					id: {
						type: 'obj'
					}
				}
			},
		}
	},
	created: {
		type: 'obj',
		shape: {
			when: {
				type: 'date',
			},
			who: {
				type: 'obj',
			}
		}
	},
	hero:{
		type: 'obj',
		shape: {
			url: {
				type: 'str'
			},
			mediaId: {
				type: 'obj',
			},
		},
	},
	overview: {
		type: 'str',
		len: [1,5000],
	},
	passes: {
		type: 'ary',
		item: {
			type: 'obj',
			shape: {
				label: {
					type: 'str',
				},
				handle: {
					type: 'str',
				},
				price: {
					type: 'num',
				},
				spots: {
					type: 'num',
				},
				stripe: {
					type: 'obj',
					shape: {
						productId: {
							type: 'str',
						},
						priceId: {
							type: 'str',
						}
					},
				},
				booked: {
					type: 'num',
				},
				body: {
					type: 'str',
				},
			},
		},
	},
	published:{
		type: 'bool',
	},
	// 'requested', 'approved', ''
	status: {
		type: 'str',
	},
	title: {
		type: 'str',
		len: [1,500],
	},
	updated: {
		type: 'obj',
		shape: {
			when: {
				type: 'date',
			},
			who: {
				type: 'obj',
			}
		}
	},
	when: {
		type: 'obj',
		shape: {
			from: {
				type: 'date',
			},
			to: {
				type: 'date',
			},
		},
	},
	where: {
		type: 'obj',
		shape: {
			address: {
				type: 'obj',
				shape: {
					street: 'str',
					city: 'str',
					state: 'str',
					country: 'str',
					postal: 'str',
				},
			},
			label: {
				type: 'str',
			},
			link: {
				type: 'str',
			},
		}
	},
	who: {
		type: 'ary',
		item: {
			type: 'obj',
			shape: {
				display: {
					type: 'str',
				},
				handle: {
					type: 'str',
				},
				icon: {
					type: 'str',
				},
				label: {
					type: 'str',
				},
				members: {
					type: 'ary',
					item: {
						type: 'obj',
						shape: {
							memberId: {
								type: 'str',
							},
							title: {
								type: 'str',
							},
							description: {
								type: 'str',
							},
						},
					}
				}
			},
		},
	},
}

const reducerPrefix = 'EVENT'
const reduxStore = 'event'

const exp = {
	collection,
	eventHandles,
	fields,
	model,
	reducerPrefix,
	reduxStore,
}

export default exp