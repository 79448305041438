import constants from './constants'
import model from '../index'

const state = {
	pageIds: {},
	recs: {},
	subjectListByConnectionPage: {},
}

export const reducer = (st = state, action) => {
	switch (action.type) {
		case `${constants.reducerPrefix}_SUBJECTS_SET_BY_CONNECTION_PAGE`:
			const { connection, page, subjects } = action.payload

			const key = model.helper('media.getConnectionPageKey', { connection, page })

			const list = subjects.map(subject => {
				return {
					model: subject.model,
					id: subject.id,
				}
			})

			const subjectListByConnectionPage = {
				...st.subjectListByConnectionPage,
				[key]: list
			}
			return { ...st, subjectListByConnectionPage }
		case `${constants.reducerPrefix}_REMOVE`: {
			const { id } = action.payload

			const { [id]: _, ...recs } = st.recs

			return { ...st, recs }
		}
		case `${constants.reducerPrefix}_REC_SET`: {
			const { rec } = action.payload

			const recs = {
				...st.recs,
				[rec._id]: rec,
			}

			return { ...st, recs }
		}
		case `${constants.reducerPrefix}_SEARCH_RECS_SET`: {
			const { recs } = action.payload

			let write = {},
				listNew = []
			recs.forEach(rec => {
				write[rec._id] = rec
				listNew.push(rec._id)
			})
			const recsNew = {
				...st.recs,
				...write,
			}
			return { ...st, recs: recsNew, searchList: listNew }
		}
		case `${constants.reducerPrefix}_SET_RECS_BY_PAGE`: {
			const { page, recs } = action.payload

			let write = {},
				ids = []

			recs.forEach(rec => {
				write[rec._id] = rec
				ids.push(rec._id)
			})

			const pageIdsNew = {
				...st.pageIds,
				[page]: ids
			}

			const recsNew = {
				...st.recs,
				...write,
			}
			return { ...st, recs: recsNew, pageIds: pageIdsNew }
		}
		default:
			return st
	}
}