import React from 'react'

import { useSelector } from 'react-redux'

import model from '../../model'

import ChapterGrix from '../ChapterGrix'
import LocationGrix from '../LocationGrix'
import MediaGrix from '../MediaGrix'
import MemberGrix from '../MemberGrix'
import ProjectGrix from '../ProjectGrix'

export default function SubjectGrix(payload) {
    const { subject } = payload

    const rec = useSelector(state => model.selector('subject.rec', { state, subject }))

    switch (subject.model) {
        case 'chapter':
            return <ChapterGrix rec={rec} {...payload} />
        case 'location':
            return <LocationGrix rec={rec} {...payload} />
        case 'media':
            return <MediaGrix rec={rec} {...payload} />
        case 'member':
            return <MemberGrix rec={rec} {...payload} />
        case 'project':
            return <ProjectGrix rec={rec} {...payload} />
        default:
            return <div>No subject grix for: {subject.model}</div>
    }
}


