import { applyMiddleware, combineReducers, createStore } from 'redux'

import {reducer as app} from './model/app/reducer'
import {reducer as chapter} from './model/chapter/reducer'
import {reducer as event} from './model/event/reducer'
import {reducer as location} from './model/location/reducer'
import {reducer as media} from './model/media/reducer'
import {reducer as member} from './model/member/reducer'
import {reducer as project} from './model/project/reducer'
import {reducer as reservation} from './model/reservation/reducer'
import {reducer as story} from './model/story/reducer'
import {reducer as subject} from './model/subject/reducer'

export const reducers = combineReducers({
	app,
	chapter,
	event,
	location,
	media,
	member,
	project,
	reservation,
	story,
	subject,
})

const middleware = applyMiddleware()

export default createStore(reducers, middleware)