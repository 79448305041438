import constants from './constants'
import model from '../index'

import utilObj from '../../utility/obj'

const recById = ({state, id})=>{
	if (!state.reservation.recs[id]) return null
	return state.reservation.recs[id]
}

const recsByParent = ({state, parent})=>{
	if (utilObj.isEmpty(state.reservation.recs)) return []
	if (!parent) return []

	return state.reservation.byParent.map(reservationId => {
		return state.reservation.recs[reservationId]
	})
}

model.registerSelectors(constants.model, {
	recById,
	recsByParent,
})