import React from 'react'

import ButtonIcon from '../Elements/ButtonIcon'

import {ReactComponent as CloseIcon} from '../../icon/close.svg'
import {ReactComponent as BackIcon} from '../../icon/back-arrow.svg'

export default function ModalTitle({ children, handleBack, handleClose }) {

    return (
        <div className="flex flex-col py-6 bg-slate-800 relative">
            <div className="px-6">
                {children}
            </div>
            <div className="absolute top-5 right-5">
                {handleBack && (
                    <ButtonIcon className="" onClick={handleBack} theme="transparent" shape="round" size="sm-symmetric">
                        <BackIcon className="w-5 h-5 text-gray-500 group-hover/button:text-white" />
                    </ButtonIcon>
                )}
                <ButtonIcon className="ml-auto" onClick={handleClose} theme="transparent" shape="round" size="sm-symmetric">
                    <CloseIcon className="w-5 h-5 text-gray-500 group-hover/button:text-white" />
                </ButtonIcon>
            </div>
        </div>
    )
}