import React from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import model from '../../model'

import { ReactComponent as CloseIcon } from '../../icon/close.svg'
import { ReactComponent as MediaIcon } from '../../icon/media.svg'
import { ReactComponent as LoveIcon } from '../../icon/heart-empty.svg'
import { ReactComponent as LovedIcon } from '../../icon/heart-solid.svg'

import ArrowGo from '../Elements/ArrowGo'
import ButtonIcon from '../Elements/ButtonIcon'
import GrixRowConnections from '../GrixRowConnections'
import DotMenu from '../Elements/DotMenu'
import MarkdownText from '../Elements/MarkdownText'
import MediaGrixRowContent from './MediaGrixRowContent'

export default function MediaGrixRow({ handleClose, handleRemoved, pageSubject, setView, subject, view }) {

    const navigate = useNavigate()

    const rec = useSelector(state => model.selector('subject.rec', { state, subject }))

    const isAdmin = useSelector(state => model.selector('member.isAdmin', { state }))
    const memberAuthed = useSelector(state => model.selector('member.authedMember', { state }))
    const isOwner = model.helper('media.isOwner', { memberAuthed, rec })

    const isLoved = model.helper('subject.isLoved', { memberAuthed, rec, subject })

    const path = model.helper('media.getPathFromSubject', { subject })

    let actions = []
    const handleGo = () => {
        const path = model.helper('media.getPathFromSubject', { subject })
        navigate(path)
    }

    const handleShowLove = async (e) => {
        e.stopPropagation()

        if (!memberAuthed){
            // ask user to login or sign up
            await model.action('app.setModal', {
                modal: 'access',
                handleComplete: async ()=>{
                    // show love
                    
                }
            })
            return
        }

        await model.action(`${subject.model}.connectionAdd`, {
            id: subject.id,
            relation: 'loves',
            subject: {
                model: 'member',
                id: memberAuthed._id
            }
        })
    }

    const handleUnLove = async (e) => {
        e.stopPropagation()

        await model.action(`${subject.model}.connectionRemove`, {
            id: subject.id,
            relation: 'loves',
            subject: {
                model: 'member',
                id: memberAuthed._id
            }
        })
    }

    const renderLove = () => {
        if (isLoved) {
            return (
                <div className="cursor-pointer group rounded-full w-8 h-8 flex flex-row items-center justify-center hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-rose-500 focus:outline-2 focus:outline-offset-2 focus:outline-rose-500 transition relative" onClick={handleUnLove}>
                    <LovedIcon className="w-4 h-4 text-rose-700 group-hover:text-rose-600 transition" />
                    <div className="hidden group-hover:flex absolute h-8 px-2 items-center -left-2 bg-slate-950 rounded top-9">
                        <div className="text-sm font-normal text-white whitespace-nowrap">
                            Loved (Unlove)
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="cursor-pointer group rounded-full w-8 h-8 flex flex-row items-center justify-center hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-rose-500 focus:outline-2 focus:outline-offset-2 focus:outline-rose-500 transition relative" onClick={handleShowLove}>
                <LoveIcon className="w-4 h-4 text-rose-700 group-hover:text-rose-600 transition" />
                <div className="hidden group-hover:flex absolute h-8 px-2 items-center -left-2 bg-slate-950 rounded top-9">
                    <div className="text-sm font-normal text-white whitespace-nowrap">
                        Show love
                    </div>
                </div>
            </div>
        )
    }

    if (isOwner || isAdmin) actions.push({
        label: 'edit',
        onClick: () => {
            navigate(`${path}/edit`)
        },
    })
    if (isOwner || isAdmin) actions.push({
        label: 'edit connections',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'connectionsEdit',
                connections: rec && rec.connections ? rec.connections : {},
                subject,
            })
        }
    });
    if (isOwner || isAdmin) actions.push({
        label: 'remove',
        onClick: async () => {
            await model.action('app.setModal', {
                handleComplete: handleRemoved,
                modal: 'subjectRemove',
                subject,
            })
        }
    });

    if (!rec) return (
        <div className="flex flex-col lg:flex-row items-stretch w-full relative col-span-3 md:col-span-3 lg:col-span-3 xl:col-span-4 2xl:col-span-4">

        </div>
    )

    return (

        <div className="flex flex-col items-stretch w-full relative col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-4 2xl:col-span-4">
            <div className="w-full h-14 flex flex-row gap-4 px-6 bg-slate-900 justify-start items-center">
                <MediaIcon className="w-4 h-4 text-lime-400" />
                <div className="text-white font-semibold text-base">
                    media
                </div>
                <ArrowGo onClick={handleGo} />
                {renderLove()}
                <DotMenu options={actions} theme="light" />
            </div>
            <div className="w-full bg-slate-900 flex flex-col md:flex-row justify-center items-start pt-6 pb-12">
                <div className="w-full md:w-2/3 px-6 shrink-0 flex flex-col gap-2">
                    <MediaGrixRowContent subject={subject} />
                    {rec.name && (
                        <div className="flex flex-col items-stretch px-5 gap-1.5">
                            <div className="text-white font-semibold text-lg">
                                {rec.name}
                            </div>
                        </div>
                    )}
                    {rec.caption && (
                        <div className="flex flex-col items-stretch gap-1.5">
                            <MarkdownText className="text-white">
                                {rec.caption}
                            </MarkdownText>
                        </div>
                    )}
                </div>
                <div className="w-full md:w-1/3 shrink-0">
                    <GrixRowConnections pageSubject={pageSubject} subject={subject}/>
                </div>
            </div>

            <div className="absolute top-3 right-3 flex flex-row items-center gap-1.5">
                {handleClose && (
                    <ButtonIcon onClick={handleClose} theme="transparent">
                        <CloseIcon className="text-white/50 group-hover:text-white w-6 h-6" />
                    </ButtonIcon>
                )}
            </div>

        </div>
    )
}