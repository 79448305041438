import React from 'react'

export default function MediaRowUploadPreview({ media }) {

    const { blobURL, type, url } = media

    if (type === 'video') {
        return (
            <div className="flex-grow bg-slate-800 p-5 flex flex-row items-center justify-center">
                <video width="100%" height="100%" src={blobURL} controls autoplay>
                    Your browser does not support the video tag.
                </video>
            </div>
        )
    }
    if (type === 'image') {
        return (
            <div className="flex-grow bg-slate-800 p-5 flex flex-row items-center justify-center">
                <div className={`h-0 pt-[100%] flex-grow relative bg-slate-800`}>
                    <div className="absolute group top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-center bg-no-repeat bg-contain"
                        style={{ backgroundImage: `url(${url})` }} >
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex-grow bg-slate-800 p-5 flex flex-row items-center justify-center">
            <div className="text-rose-600 font-semibold text-lg">
                Type not found
            </div>
        </div>
    )
}