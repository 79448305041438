import React from 'react'

import model from '../../model'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ButtonIcon from '../Elements/ButtonIcon'

import { ReactComponent as CloseIcon } from '../../icon/close.svg'
import { ReactComponent as ProjectIcon } from '../../icon/project.svg'

export default function ProjectNugget({ handleDeselect, handleSelect, isEditable, selected, subject }) {

    const rec = useSelector(state => model.selector('subject.rec', { state, subject })) || {}

    const navigate = useNavigate()
    const title = rec.title

    const isSelectable = handleSelect ? true : false

    const displayMediaId = rec && rec.displayMediaId ? rec.displayMediaId : null
    const displayMedia = useSelector(state => model.selector('media.recById', { state, id: displayMediaId }))
    const displayMediaUrl = displayMedia && displayMedia.cloudinary && displayMedia.cloudinary.url ? displayMedia.cloudinary.url : ''

    const imageStyle = {
        backgroundImage: `url(${displayMediaUrl})`
    }

    let selectableClass = isSelectable ? 'hover:bg-slate-950' : ''
    let selectedClass = selected ? 'bg-slate-800' : 'bg-slate-800'
    let titleClass = selected ? 'text-white' : 'text-white'

    const handleSelectClick = (e) => {
        e.stopPropagation()
        if (handleSelect) return handleSelect({ subject })
        navigate(`/project/${rec._id}`)
    }
    const handleDeselectClick = (e) => {
        e.stopPropagation()
        if (handleDeselect) return handleDeselect({ subject })
    }

    return (
        <div className={`flex flex-row group relative cursor-pointer items-center gap-3 px-5 h-14 shadow-sm hover:shadow-xl transition opacity-80 hover:opacity-100 rounded ${selectableClass} ${selectedClass}`}
            onClick={handleSelectClick}>
            <div className="absolute group top-0 bottom-0 left-0 right-0 bg-center bg-no-repeat bg-cover"
                style={imageStyle} >
            </div>
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-slate-800/80 group-hover:bg-slate-800/70" />
            <div className="flex flex-row items-center gap-3 relative">
                <ProjectIcon className="w-5 h-5 text-lime-400" />
                <span className={`group-hover:text-white ${titleClass} font-semibold text-base transition`}>{title}</span>
                {selected && isEditable && (
                    <ButtonIcon
                        className="ml-auto mr-2"
                        onClick={handleDeselectClick}
                        theme="transparent">
                        <CloseIcon className="w-6 h-6 text-white/50 group-hover:text-white" />
                    </ButtonIcon>
                )}
            </div>
        </div>
    )
}


