import constants from './constants'
import model from '../index'
import store from '../../store'

const setAlert = async (alert)=>{
	store.dispatch({
		type: `${constants.reducerPrefix}_ALERT_SET`,
		payload: alert,
	})
}

const setLoading = async (loading)=>{
	store.dispatch({
		type: `${constants.reducerPrefix}_LOADING_SET`,
		payload: loading,
	})
}

const setModal = async (modal)=>{
	store.dispatch({
		type: `${constants.reducerPrefix}_MODAL_SET`,
		payload: modal,
	})
}

model.registerActions(constants.model, {
	setAlert,
	setLoading,
	setModal,
})