const model = 'app'

const reducerPrefix = 'APP'
const reduxStore = 'app'

const exp = {
	model,
	reducerPrefix,
	reduxStore,
}

export default exp