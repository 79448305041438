import constants from './constants'
import model from '../index'
import store from '../../store'

import utilApi from '../../utility/api'

const add = async ({holders, parent, paymentIntentId})=>{
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.add: no authToken found`)

	const data = {
		action: 'reservation.add',
		authToken,
		holders,
		parent,
		paymentIntentId,
	}

	const added = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type:'RESERVATION_REC_SET',
		payload: {
			rec: added,
		}
	})

	return added
}

const getById = async ({id})=>{
	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: 'reservation.getById',
		authToken,
		id,
	}

	const {rec} = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type:'RESERVATION_REC_SET',
		payload: {
			rec,
		}
	})

	return rec
}

const getByParent = async ({parent})=>{

	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: 'reservation.getByParent',
		authToken,
		parent,
	}

	const {recs} = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type:'RESERVATION_BY_PARENT_SET',
		payload: {
			recs,
		}
	})
}

const remove = async ({id})=>{
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.add: no authToken found`)

	const data = {
		action: 'reservation.remove',
		authToken,
		id,
	}

	const added = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type:'RESERVATION_REMOVE',
		payload: {
			id,
		}
	})

	return added
}

const update = async ({id, holders, parent, paymentIntentId, status})=>{
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.update: no authToken found`)

	const data = {
		action: 'reservation.update',
		authToken,
		id,
		holders,
		parent,
		paymentIntentId,
		status,
	}

	const updated = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type:'RESERVATION_REC_SET',
		payload: {
			rec: updated,
		}
	})
}

model.registerActions(constants.model, {
	add,
	getById,
	getByParent,
	remove,
	update,
})