import React from 'react'

export default function MediaPageContent({ rec, subject }) {

    const url = rec.cloudinary && rec.cloudinary.url ? rec.cloudinary.url : ''

    const renderContent = () => {
        if (!rec) return (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-rose-200">
                Rec not found
            </div>
        )

        if (rec.type === 'video') {
            return (
                <video width="100%" height="100%" src={url} controls autoplay>
                    Your browser does not support the video tag.
                </video>

            )
        }
        if (rec.type === 'image') {
            return (
                <div className="absolute group top-0 bottom-0 left-0 right-0 lg:top-7 lg:bottom-7 lg:left-7 lg:right-7 flex flex-row items-center justify-center bg-center bg-no-repeat bg-contain"
                    style={{ backgroundImage: `url(${url})` }} >
                </div>

            )
        }

        return (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-rose-900">
                <div className="font-semibold text-white text-base">
                    type not found
                </div>
            </div>
        )
    }

    return (
        <div className="flex-grow bg-slate-950 p-5 flex flex-row items-center justify-center">
            <div className={`h-0 pt-[100%] lg:pt-[60%] flex-grow relative`}>
                {renderContent()}
            </div>
        </div>
    )
}