const model = 'member'
const authTokenName = 'memberAuthToken'
const collection = 'member'
const collectionRequestJoin = 'memberRequestJoin'
const collectionRequestMessage = 'memberRequestMessage'
const collectionRequestLotusCounterUpdates = 'memberRequestLotusCounterUpdates'
const collectionRequestUpdates = 'memberRequestUpdates'

const memberHandles = {
	palmer: '630bb30b18151c72e2e53796',
	sam: '6328c970a6347539984c91ee',
	will: '632ce49ae6b0c3379e73c084',
	sean: '632ce911e6b0c3379e73c089',
	shane: '632ceb68e6b0c3379e73c08a',
	wonder: '632cfd91e6b0c3379e73c08b',
	lauren: '632d18c8a3f0aa8601fd68bd',
	aevind: '632d1a86a3f0aa8601fd68bf',
	damon: '632d1c02a3f0aa8601fd68c0',
	russ: '632ce839e6b0c3379e73c087',
}

const allRoles = {
	activated: {
		handle: 'activated',
		description: 'activation link click',
	},
	approved: {
		handle: 'approved',
		description: 'met in person and verified by leader',
	},
	admin: {
		handle: 'admin',
		description: 'site admin',
	},
}

const fields = {
	_id:{
		type: 'obj',
	},
	about:{
		type:'str',
		len: [2,500],
	},
	connections:{
		type: 'obj', 
		key: {
			type: 'str', 
			label: 'relation'
		}, // uploader, featured (members in media), associated (chapters / events),
		value:{
			type:'ary',
			item: {
				type:'obj',
				shape:{
					model: {
						type: 'str'
					},
					id: {
						type: 'obj'
					}
				}
			},
		}
	},
	created: {
		type: 'obj',
		shape: {
			when: {
				type: 'date',
			},
			who: {
				type: 'obj',
			}
		}
	},
	displayMediaId: {
		type: 'obj',
	},
	email:{
		type: 'str',
		pattern: 'email',
	},
	firstName:{ // deprecate in favor of name
		type: 'str',
		pattern: 'alphanumplus',
		len: [1,50],
	},
	lastName:{ // deprecate in favor of name
		type: 'str',
		pattern: 'alphanumplus',
		len: [1,50],
	},
	name:{
		type: 'str',
		pattern: 'alphanumplus',
		len: [1,100],
	},
	image:{ // deprecate in favor of mediaId
		type: 'obj',
		/*
			{
				assetId: res.data.asset_id,
				folder: res.data.folder,
				format: res.data.form,
				height: res.data.height,
				extension: res.data.original_extension,
				publicId: res.data.public_id,
				type: res.data.resource_type,
				url: res.data.secure_url,
				method: res.data.type,
				version: res.data.version,
				width: res.data.width
			}
		*/
	},
	roles:{
		type: 'ary',
		item: {
			type: 'enum',
			options: ['activated', 'approved', 'admin']
		},
	},
	updated: {
		type: 'obj',
		shape: {
			when: {
				type: 'date',
			},
			who: {
				type: 'obj',
			}
		}
	},
}

const reducerPrefix = 'MEMBER'
const reduxStore = 'member'

const roles = {
	anon: 0,
	member: 1,
	activated: 2,
	approved: 3,
	sudo: 8,
	admin: 9,
	self: 10,
}

const exp = {
	allRoles,
	authTokenName,
	collection,
	collectionRequestJoin,
	collectionRequestLotusCounterUpdates,
	collectionRequestMessage,
	collectionRequestUpdates,
	fields,
	memberHandles,
	model,
	reducerPrefix,
	reduxStore,
	roles,
}

export default exp