import constants from './constants'
import model from '../index'

const getParentIndex = ({parent})=>{
	if (!parent) return null
	return `${parent.model}-${parent.id}`
}

const getCountForPass = ({reservation, passHandle})=>{
	if (!reservation || !reservation.holders) return 0
	return reservation.holders.reduce((tot, holder)=>{
		return (holder.pass === passHandle) ? tot + 1: tot
	}, 0)
}

const getCountsForPasses = ({reservation})=>{
	if (!reservation || !reservation.holders) return 0
	return reservation.holders.reduce((tot, holder)=>{
		tot[holder.pass] = tot[holder.pass] || 0
		tot[holder.pass]++
		return tot
	}, {})
}

const getCountsForShuttles = ({reservation})=>{
	if (!reservation || !reservation.holders) return {}
	let shuttles = {}
	reservation.holders.forEach((holder)=>{
		if (holder?.airportShuttle?.arrival){ 
			shuttles.arrival = shuttles.arrival? shuttles.arrival + 1: 1
		}
		if (holder?.airportShuttle?.departure){
			shuttles.departure = shuttles.departure? shuttles.departure + 1: 1
		}
	})
	return shuttles
}

const getTotalForHolders = ({event, reservation})=>{
	if (!reservation || !reservation.holders) return 0
	let passPrices = {}
	event.passes.forEach(pass => {
		passPrices[pass.handle] = pass.price
	})
	return reservation.holders.reduce((tot, holder)=>{
		tot += passPrices[holder.pass]
		if (holder.airportShuttle){
			if (holder.airportShuttle.arrival) tot += 50
			if (holder.airportShuttle.departure) tot += 50
		}
		return tot
	}, 0)
}

const setDefaultHolderProps = ({reservation, props})=>{
	let reservationNew = {
		...reservation,
	}
	if (!reservationNew.holders){
		reservationNew.holders = [{
			firstName: '',
			lastName: '',
			email: '',
			pass: '',
		}]
	}
	for (let i in props){
		if (!reservationNew.holders[0][i]) reservationNew.holders[0][i] = props[i]
	}
	return reservationNew
}

model.registerHelpers(constants.model, {
	getCountForPass,
	getCountsForPasses,
	getCountsForShuttles,
	getParentIndex,
	getTotalForHolders,
	setDefaultHolderProps,
})