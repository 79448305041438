import React from 'react'

import { useSelector } from 'react-redux'
import model from '../../model'

export default function MediaRowContent({ handleClose, setView, subject, view }) {
    const rec = useSelector(state => model.selector('subject.rec', { state, subject })) || {}

    const isAdmin = useSelector(state => model.selector('member.isAdmin', { state }))

    const url = rec.cloudinary && rec.cloudinary.url ? rec.cloudinary.url : ''

    let options = [{
        label: 'edit',
        onClick: () => {
            setView('edit')
        },
    }, {
        label: 'delete',
        theme: 'danger',
        onClick: () => {
            setView('remove')
        },
    }]

    if (isAdmin) {
        options.push({
            label: 'add to home',
            onClick: () => {
            },
        })
    }


    const renderContent = () => {
        if (!rec) return (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-rose-200">
                Rec not found
            </div>
        )

        if (rec.type === 'video') {
            return (
                <video width="100%" height="100%" src={url} controls autoplay>
                    Your browser does not support the video tag.
                </video>

            )
        }
        if (rec.type === 'image') {
            return (
                <div className="absolute group top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-center bg-no-repeat bg-contain"
                    style={{ backgroundImage: `url(${url})` }} >
                </div>

            )
        }

        return (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-rose-900">
                <div className="font-semibold text-white text-base">
                    type not found
                </div>
            </div>
        )
    }

    return (
        <div className="flex-grow bg-slate-900 flex flex-row items-center justify-center">
            <div className={`h-0 pt-[88%] flex-grow relative`}>
                {renderContent()}
            </div>
        </div>
    )
}