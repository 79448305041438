import constants from './constants'
import model from '../index'

const state = {
	authId: null,
	list: {},
	metrics: null,
	recs: {},
	searchList: null,
}

export const reducer = (st = state, action)=>{
	switch (action.type){
		case `${constants.reducerPrefix}_AUTH_CLEAR`: {
			return {...st, authId: null}
		}
		case `${constants.reducerPrefix}_AUTH_SET`: {
			const {authId} = action.payload
			return {...st, authId}
		}
		case `${constants.reducerPrefix}_METRICS_SET`: {
			const {metrics} = action.payload
			return {...st, metrics}
		}
		case `${constants.reducerPrefix}_REC_SET`: {
			const {rec} = action.payload
			const recs = {
				...st.recs,
				[rec._id]: rec
			}
			return {...st, recs}
		}
		case `${constants.reducerPrefix}_LIST_SET`: {
			const {list} = action.payload

			const listKey = model.helper('member.getListIndex', {...action.payload})
			let write = {}
			let listNew = {
				...st.list,
				[listKey]: [],
			}
			list.forEach(rec => {
				write[rec._id] = rec
				listNew[listKey].push(rec._id)
			})
			const recsNew = {
				...st.recs,
				...write,
			}
			return {...st, recs: recsNew, list: listNew}
		}
		case `${constants.reducerPrefix}_ALL_SET`: {
			const {recs} = action.payload
			let write = {}
			recs.forEach(rec => {
				write[rec._id] = rec
			})
			const recsNew = {
				...st.recs,
				...write,
			}
			return {...st, recs: recsNew}
		}
		case `${constants.reducerPrefix}_SEARCH_RECS_SET`: {
			const {recs} = action.payload

			let write = {},
				listNew = []
			recs.forEach(rec => {
				write[rec._id] = rec
				listNew.push(rec._id)
			})
			const recsNew = {
				...st.recs,
				...write,
			}
			return {...st, recs: recsNew, searchList: listNew}
		}
		case `${constants.reducerPrefix}_SET_RECS_BY_PAGE`: {
			const { page, recs } = action.payload

			let write = {},
				ids = []

			recs.forEach(rec => {
				write[rec._id] = rec
				ids.push(rec._id)
			})

			const pageIdsNew = {
				...st.pageIds,
				[page]: ids
			}

			const recsNew = {
				...st.recs,
				...write,
			}
			return { ...st, recs: recsNew, pageIds: pageIdsNew }
		}
		default:
			return st
  }
}