import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

//import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { ReactComponent as EllipsisIcon } from '../../icon/ellipsis-vertical.svg'

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

export default function DotMenu({ className, direction, handleChange, label, options, theme }) {

    let activeOptionTextClass, inactiveOptionTextClass, menuClassName, optionClassName, themeClassName
    switch (theme) {
        case 'dark':
            activeOptionTextClass = 'text-lime-600 bg-white'
            inactiveOptionTextClass = 'text-slate-800'
            menuClassName = 'ring-white'
            optionClassName = 'bg-slate-50'
            themeClassName = 'text-white group-hover:text-lime-400'
            break
        default:
            activeOptionTextClass = 'text-white bg-slate-950'
            inactiveOptionTextClass = 'text-slate-100'
            menuClassName = 'ring-white bg-slate-950'
            optionClassName = 'bg-slate-50'
            themeClassName = 'text-white group-hover:text-lime-500'
    }

    let dirClass
    switch (direction) {
        case 'right':
            dirClass = '-left-2 right-auto'
            break
        default:
            dirClass = '-right-2 left-auto'
    }

    if (!options || options.length < 1) return <div/>

    return (
        <Menu as="div" className={classNames('relative inline-block text-left cursor-pointer', 
                className)} 
            onClick={e => e.stopPropagation()}>
            <Menu.Button className="group inline-flex rounded-full w-8 h-8 flex-row items-center justify-center font-semibold hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-lime-400 focus:outline-2 focus:outline-offset-2 focus:outline-lime-400 transition"
                onClick={e => e.stopPropagation()}>
                <EllipsisIcon className={classNames('h-4 w-4 relative', themeClassName)} aria-hidden="true" />
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className={classNames(menuClassName, dirClass, 'absolute right-0 px-1 z-10 mt-2 w-48 origin-top-right shadow-lg rounded rounded-tr-noneshadow-xl ring-1 ring-opacity-5 focus:outline-none', 
                    optionClassName)}>
                    <div className="py-1">
                        {options && options.map((option, i) => {

                            return (
                                <Menu.Item className="rounded hover:outline hover:outline-2 hover:outline-lime-400 focus:outline-2 focus:outline-offset-2 focus:outline-lime-400 transition" key={option.value || i}>
                                    {({ active }) => (
                                        <div
                                            className={classNames(
                                                active ? activeOptionTextClass : inactiveOptionTextClass,

                                                'block px-4 py-2 text-sm whitespace-nowrap transition'
                                            )}
                                            onClick={(e) => {
                                                if (option.onClick) return option.onClick(e)
                                                if (handleChange) return handleChange(option)
                                            }}
                                        >
                                            {option.label}
                                        </div>
                                    )}
                                </Menu.Item>
                            )
                        })}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}