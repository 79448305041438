import React, { useState } from 'react'

import model from '../../model'

import FormField from '../Elements/FormField'
import Button from '../Elements/Button'
import Input from '../Elements/Input'

import ModalTitle from './ModalTitle'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'

export default function ModalLotusCounterSubscribe({ handleClose, handleComplete }) {

    const [mode, setMode] = useState('form')
    const [email, setEmail] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            await model.action('app.setLoading', true)
            await model.action('member.requestLotusCounterUpdates', {
                email,
            })
            await model.action('app.setLoading', false)

            if (handleComplete) await handleComplete()
            setMode('complete')
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    if (mode === 'form') return (
        <form onSubmit={handleSubmit}>
            <ModalTitle handleClose={handleClose}>
                <div className="flex flex-col items-center gap-2">
                    <div className="text-xl text-white font-semibold uppercase leading-6">
                        Get Lotus Counter Updates
                    </div>
                </div>
            </ModalTitle>
            <ModalBody>
                <div className="flex flex-col gap-4" onSubmit={handleSubmit}>
                    <p className="text-base text-slate-700 font-normal">
                    We'll notify you when the Kickstarter goes live and you'll be one of the first to secure a Lotus Counter!
                    </p>
                    <p className="text-base text-slate-700 font-semibold">
                        Leave us your email
                    </p>
                    <FormField
                        label="email"
                        name="email"
                        size="md">

                        <Input
                            autoFocus={true}
                            name="email"
                            placeholder="your@email.com"
                            value={email}
                            setValue={setEmail}
                            size="md" />
                    </FormField>

                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex flex-row">
                    <Button className="ml-auto" size="md" type="submit">Submit</Button>
                </div>
            </ModalFooter>
        </form>
    )

    return (
        <React.Fragment>
            <ModalTitle handleClose={handleClose}>
                <div className="flex flex-col items-center gap-2">
                    <div className="text-xl text-white font-semibold uppercase leading-6">
                    Get Lotus Counter Updates
                    </div>
                </div>
            </ModalTitle>
            <ModalBody>
                <div className="flex flex-col gap-4" onSubmit={handleSubmit}>
                    <p className="text-base text-slate-700 font-normal">
                        Thank you for your interest. We'll be in touch soon!
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex flex-row">
                    <Button className="ml-auto" onClick={handleClose} size="md" type="submit">Done</Button>
                </div>
            </ModalFooter>
        </React.Fragment>
    )
}