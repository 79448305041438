import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import Frame from '../Frame'

import model from '../../model'

export default function AccessPage (){

    const navigate = useNavigate()
	const {token} = useParams()
	const [error, setError] = useState(null)

	useEffect(()=>{
		(async ()=>{
			try {
				await model.action('app.setLoading', true)
				await model.action('member.activate', {token})
				await model.action('app.setLoading', false)

                navigate('/', {replace: true})
			} catch(e) {
				await model.action('app.setLoading', false)
				const error = model.helper('app.getErrorMessage', e)
				setError(error)
				await model.action('app.setAlert', {
					message: error,
					theme: 'danger'
				})
			}
		})()
	},[token]) // eslint-disable-line
	
	if (error){
		return (
			<React.Fragment>
				<Frame className="bg-rose-800 bg-cover bg-center shadow-sm mt-[78px] flex flex-row items-center justify-center">
					<div className="flex flex-col gap-4 justify-center items-center">
						<h3 className="text-2xl text-white font-semibold">ACCESS DENIED</h3>
						<p className="text-lg text-rose-200 font-normal">
							Something is wrong with our network or your access link.
						</p>
					</div>
				</Frame>
			</React.Fragment>
		)
	}

	return (
        <React.Fragment>
            <Frame className="bg-sky-800 bg-cover bg-center shadow-sm mt-[78px] flex flex-row items-center justify-center">
                <div className="flex flex-col gap-4 justify-center items-center">
                    <h3 className="text-2xl text-white font-semibold">CONFIRMING ACCESS</h3>
                    <p className="text-lg text-sky-200 font-normal">
                        We're confirming your Everspring account access. You will be logged in shortly.
                    </p>
                </div>
            </Frame>
        </React.Fragment>
	)
}