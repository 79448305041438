import React from 'react'

import { useSelector } from 'react-redux'
import model from '../../model'

import Alert from '../Elements/Alert'

export default function PageAlert() {

    const alert = useSelector(state => model.selector('app.alert', { state }))

    const handleClose = async ()=>{
        await model.action('app.setAlert', null)
    }

    return (
        <Alert {...alert} handleClose={handleClose} show={alert? true: false}/>
    )
}