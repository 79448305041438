import constants from './constants'

const state = {
	publishedList: [],
	recs: {}
}

export const reducer = (st = state, action)=>{
	switch (action.type){
		case `${constants.reducerPrefix}_REMOVE`: {
			const {id} = action.payload

			const { [id]: _, ...recs } = st.recs

			return {...st, recs}
		}
		case `${constants.reducerPrefix}_REC_SET`: {
			const {rec} = action.payload

			const recs = {
				...st.recs,
				[rec._id]: rec,
			}

			return {...st, recs}
		}
		case `${constants.reducerPrefix}_ALL_SET`: {
			const {recs} = action.payload

			let write = {}
			recs.forEach(rec => {
				write[rec._id] = rec
			})
			const recsSet = {
				...st.recs,
				...write,
			}
			return {...st, recs: recsSet}
		}
	case `${constants.reducerPrefix}_PUBLISHED_SET`: {
			const {recs} = action.payload

			let write = {}
			let list = []
			recs.forEach(rec => {
				write[rec._id] = rec
				list.push(rec._id)
			})
			const recsSet = {
				...st.recs,
				...write,
			}
			return {...st, publishedList: list, recs: recsSet}
		}
		default:
			return st
  }
}