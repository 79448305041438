import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as MemberIcon } from '../../icon/member.svg'

import Container from '../Elements/Container'
import DotMenu from '../Elements/DotMenu'
import FrameTitle from '../Frame/FrameTitle'
import MarkdownText from '../Elements/MarkdownText'

import model from '../../model'

export default function MemberPageDisplay({ id, pathSuffix, rec }) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const isAdmin = useSelector(state => model.selector('member.isAdmin', { state }))
    const isSelf = useSelector(state => model.selector('member.isSelf', { state, memberId: id }))

    const subject = {
        model: 'member',
        id,
    }

    const navigate = useNavigate()

    const handleLogout = async () => {
        try {
            await model.action('app.setLoading', true)
            await model.action('member.logout')
            await model.action('app.setLoading', false)
            navigate('/')
        } catch (e) {
            await model.action('app.setLoading', false)
            const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })
        }
    }

    if (!rec) {
        return <div />
    }

    const fullName = model.helper('member.formatFullName', rec)

    /*
    const imageStyle = {
        backgroundImage: `url(${model.helper('member.getImageUrl', { member: rec })})`
    }
    */

    let actions = []
    if (isSelf || isAdmin) actions.push({
        label: 'edit',
        onClick: () => {
            navigate(`${pathSuffix}/edit`)
        },
    })
    if (isSelf || isAdmin) actions.push({
        label: 'edit connections',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'connectionsEdit',
                connections: rec && rec.connections ? rec.connections : {},
                subject,
            })
        }
    });
    if (isAdmin) actions.push({
        label: 'invite',
        onClick: async () => {
            await model.action('app.setModal', {
                member: rec,
                modal: 'memberInvite'
            })
        },
    })
    if (isSelf || isAdmin) actions.push({
        label: 'display media',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'displayMediaSelect',
                subject
            })

        }
    })
    if (isSelf) actions.push({
        label: 'logout',
        onClick: async () => {
            await handleLogout()
        },
    })
    if (isAdmin) actions.push({
        label: 'remove',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'subjectRemove',
                subject
            })

        }
    })

    return (
        <React.Fragment>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <MemberIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    member
                </div>
                <div className="">
                    <DotMenu direction="right" options={actions} theme="light" />
                </div>
            </FrameTitle>
            <div className="w-full bg-white">
                <Container className="flex flex-col gap-4 py-6 md:py-10 lg:py-12 xl:py-14">
                    <h2 className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.5rem] leading-tight font-bold text-slate-800 mb-0">{fullName}</h2>
                    <MarkdownText className="font-regular text-base md:text-lg lg:text-xl leading-normal text-slate-800">{rec.about}</MarkdownText>
                </Container>
            </div>
        </React.Fragment>
    )
}