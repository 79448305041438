import React from 'react'

import { Transition } from '@headlessui/react'

import { useSelector } from 'react-redux'
import model from '../../model'

import Loader from '../Elements/Loader'

export default function PageLoader() {

    const loading = useSelector(state => model.selector('app.loading', { state }))

    return (
        <Transition
            className="fixed top-0 bottom-0 left-0 right-0 bg-slate-900/80 flex items-center justify-center"
            show={loading}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100="
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
        >
            <Loader />
        </Transition>
    )
}


