import constants from './constants'
import model from '../index'

import utilApi from '../../utility/api'

const getPaymentIntent = async ({amount, email})=>{
	const data = {
		action: 'stripe.getPaymentIntent',
		amount,
		email,
	}

	const {paymentIntent} = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	return paymentIntent
}

const makePayment = async ({card, paymentIntent, stripe})=>{
	const resCP = await stripe.confirmCardPayment(paymentIntent, {
		payment_method: {
			card,
		}
	})

	if (resCP.error) throw new Error(`stripe.makePayment: error ${resCP.error.message}`)

	return resCP.paymentIntent
}

const productAdd = async ({id, name, description, metadata})=>{

	const data = {
		action: 'stripe.productAdd',
		id,
		name,
		description,
		metadata,
	}

	const {product} = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	return product
}


model.registerActions(constants.model, {
	getPaymentIntent,
	makePayment,
	productAdd,
})