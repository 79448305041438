import React, { Fragment, useRef } from 'react'

import { Transition } from '@headlessui/react'
import { useSelector } from 'react-redux'

import model from '../../model'

import ModalAccess from './ModalAccess'
import ModalConnectionAdd from './ModalConnectionAdd'
import ModalConnectionsEdit from './ModalConnectionsEdit'
import ModalGetUpdates from './ModalGetUpdates'
import ModalDisplayMediaSelect from './ModalDisplayMediaSelect'
import ModalLotusCounterSubscribe from './ModalLotusCounterSubscribe'
import ModalSubjectRemove from './ModalSubjectRemove'
import ModalUploadMediaSingle from './ModalUploadMediaSingle'

const modals = {
	access: ModalAccess,
	connectionAdd: ModalConnectionAdd,
	connectionsEdit: ModalConnectionsEdit,
	displayMediaSelect: ModalDisplayMediaSelect,
	getUpdates: ModalGetUpdates,
	lotusCounterSubscribe: ModalLotusCounterSubscribe,
	subjectRemove: ModalSubjectRemove,
	uploadMediaSingle: ModalUploadMediaSingle,
}

export default function Modal() {

	const modalStore = useSelector(state => model.selector('app.modal', { state }))

	const modalRef = useRef(modalStore)
	if (modalStore){
		modalRef.current = modalStore
	}
	const modal = modalRef.current

	const handleClose = async () => {
		await model.action('app.setModal', null)
	}

	const ModalClass = modal ? modals[modal.modal] : null

	return (
		<Transition.Root show={modalStore? true: false} as={Fragment}>
			<div className="relative">
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="z-30 fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity pointer-events-none" />
				</Transition.Child>

				<div className="fixed inset-0 z-40 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center text-center sm:items-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative transform overflow-hidden rounded-xl bg-white p-0 text-left shadow-xl transition-all w-[95%] sm:w-[90%] md:w-[80%] lg:w-[65%] xl:w-[50%]">
								{ModalClass && <ModalClass handleClose={handleClose} {...modal} />}
							</div>
						</Transition.Child>
					</div>
				</div>
			</div>
		</Transition.Root>
	)
}