// import model from '../index'

const state = {
	recs: {}
}

export const reducer = (st = state, action)=>{
	switch (action.type){
		case 'COVER_REC_SET': {
			const {rec} = action.payload

			const recs = {
				...st.recs,
				[rec._id]: rec,
			}

			return {...st, recs}
		}
		default:
			return st
  }
}