import React from 'react'

import { useSelector } from 'react-redux'

import model from '../../model'

import Button from '../Elements/Button'

import ModalTitle from './ModalTitle'
import ModalBody from './ModalBody'

import SubjectGrix from '../SubjectGrix'

export default function ModalDisplayMediaSelect({ handleClose, handleComplete, subject }) {

    const rec = useSelector(state => model.selector('subject.rec', { state, subject }))

    const handleSelect = async ({ subject: selected }) => {
        try {
            //setLoading(true)
            await model.action('subject.update', {
                subject,
                displayMediaId: selected.id,
            })
            handleClose()
            await model.action('app.setAlert', {
                message: 'media added',
                theme: 'success'
            })
            //setLoading(false)
        } catch (e) {
            //setLoading(false)
            const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })
        }
    }

    const handleUploadMediaDone = async ({ rec }) => {
        const mediaSubject = {
            model: 'media',
            id: rec._id,
        }
        try {
            //setLoading(true)
            await model.action('subject.connectionAdd', {
                subject,
                connection: {
                    relation: 'media',
                    subject: mediaSubject,
                }
            })
            await model.action('subject.update', {
                subject,
                displayMediaId: rec._id,
            })
            await model.action('app.setAlert', {
                message: 'media added',
                theme: 'success'
            })
            //setLoading(false)
        } catch (e) {
            //setLoading(false)
            const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })
        }
    }

    const handleUploadMedia = async () => {
        await model.action('app.setModal', {
            handleDone: handleUploadMediaDone,
            modal: 'uploadMediaSingle',
            subject
        })
    }

    return (
        <div>
            <ModalTitle handleClose={handleClose}>
                <div className="flex flex-col items-center gap-2">
                    <div className="text-xl text-white font-semibold uppercase leading-6">
                        Select Display Media
                    </div>
                </div>
            </ModalTitle>
            <ModalBody>
                <div className="flex flex-col items-stretch gap-6">
                    <div className="flex flex-row items-center">
                        <div className="text-base font-semibold text-slate-800 mr-auto">Upload New Media</div>
                        <Button onClick={handleUploadMedia}>Upload Media</Button>
                    </div>
                    <div className="flex flex-col items-stretch gap-2">
                        <div className="flex flex-row items-center">
                            <div className="text-base font-semibold text-slate-800">Or Select Media</div>
                        </div>
                        <div className="grid grid-cols-3 gap-1 items-stretch">
                            {rec && rec.connections && rec.connections.media && rec.connections.media.map(subject => {
                                return (
                                    <SubjectGrix
                                        key={rec._id}
                                        handleSelect={handleSelect}
                                        subject={subject} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </ModalBody>
        </div>
    )
}
