
const capitalize = (str)=>{
	if (!str || str.length < 1) return str
	return str.charAt(0).toUpperCase()+str.slice(1)
}

const convertNewlinesToTags = (str)=>{
	return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

const normalizeEmail = (email)=>{
	return trimSpace(email).toLowerCase()
}

const trimSpace = (str)=>{
	return str.replace(/^\s\s*/, '').replace(/\s\s*$/, '')
}

const exp = {
	capitalize,
	convertNewlinesToTags,
	normalizeEmail,
	trimSpace,
}

export default exp