import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Transition } from '@headlessui/react'

import { useSelector } from 'react-redux'

import { ReactComponent as HomeIcon } from '../../icon/home.svg'
import { ReactComponent as LogoNav } from '../../icon/logo-nav.svg'
import { ReactComponent as LocationIcon } from '../../icon/location.svg'
import { ReactComponent as LogoutIcon } from '../../icon/logout.svg'
import { ReactComponent as ProjectIcon } from '../../icon/project.svg'
import { ReactComponent as MediaIcon } from '../../icon/media.svg'
import { ReactComponent as MemberIcon } from '../../icon/member.svg'
import { ReactComponent as StoryIcon } from '../../icon/story.svg'

import { ReactComponent as PromptArrow } from '../../icon/arrow-right.svg'
import { ReactComponent as UploadIcon } from '../../icon/upload.svg'

import Input from '../Elements/Input'

import PageMember from './PageMember'

import model from '../../model'

export default function PageNav() {

    const [active, setActive] = useState(null)

    const memberAuthed = useSelector(state => model.selector('member.authedMember', { state }))

    const handleLogout = async () => {
        try {
            await model.action('app.setLoading', true)
            await model.action('member.logout')
            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })
        }
    }

    let memberNavItems = []
    if (memberAuthed) {
        memberNavItems = [{
            href: `/member/${memberAuthed._id}`,
            label: 'my profile',
            icon: MemberIcon,
        }, {
            href: `/upload`,
            label: 'upload media',
            icon: UploadIcon,
        }, {
            onClick: handleLogout,
            label: 'logout',
            icon: LogoutIcon,
        }]
    }

    const navItems = [{
        href: '/',
        label: 'home',
        icon: HomeIcon,
    }, {
        href: '/projects',
        label: 'projects',
        icon: ProjectIcon,
    }, /*{
        href: '/events',
        label: 'events',
        icon: EventIcon,
    }, */ {
        href: '/story',
        label: 'story',
        icon: StoryIcon,
    }, {
        href: '/members',
        label: 'members',
        icon: MemberIcon,
    }, {
        href: '/medias',
        label: 'media',
        icon: MediaIcon,
    }, {
        href: '/locations',
        label: 'locations',
        icon: LocationIcon,
    },]

    const navItemSelection = () => {
        setActive(null)
    }

    const handleNavItemActive = (value) => {
        if (value === active) {
            setActive(null)
        } else {
            setActive(value)
        }
    }

    return (
        <React.Fragment>

            <Transition
                className="fixed top-0 bottom-0 left-0 right-0 z-30 bg-slate-800/70"
                enter="transition ease-in-out duration-300"
                enterFrom="opacity-50"
                enterTo="opacity-100"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
                onClick={() => setActive(null)}
                show={active ? true : false}>
            </Transition>

            <div className="h-16 z-30 fixed top-0 right-0 left-0 md:left-auto w-full md:w-[130px]">
                <Transition
                    className="absolute right-16 top-16 bg-slate-950 border-t border-slate-800 rounded-b-sm w-[240px]"
                    enter="transition ease-in-out duration-300"
                    enterFrom="opacity-0 translate-x-0 -translate-y-24 shadow-sm"
                    enterTo="translate-x-0 opacity-100 translate-y-0 shadow-xl"
                    leave="transition ease-in duration-100"
                    leaveFrom="transform opacity-100 translate-x-0 shadow-xl"
                    leaveTo="transform opacity-50 translate-x-0 -translate-y-24 shadow-sm"
                    show={active === 'main'}>


                    <div className="flex flex-col gap-1 p-1">
                        {navItems.map(item => (
                            <NavLink className={({ isActive }) => `flex flex-row gap-3 px-3.5 py-2.5 items-center rounded-sm transition hover:outline hover:outline-lime-500 ${isActive ? 'bg-slate-900' : ''}`}
                                key={item.href}
                                onClick={navItemSelection}
                                to={item.href}>
                                <item.icon className="w-5 h-5 text-white"></item.icon>
                                <span className="font-semibold text-base text-white">
                                    {item.label}
                                </span>
                            </NavLink>
                        ))}

                    </div>

                </Transition>

                <Transition
                    className="absolute right-4 top-16 bg-slate-950 border-t border-slate-800 rounded-b-sm w-[240px]"
                    enter="transition ease-in-out duration-300"
                    enterFrom="opacity-0 translate-x-0 -translate-y-24 shadow-sm"
                    enterTo="translate-x-0 opacity-100 translate-y-0 shadow-xl"
                    leave="transition ease-in duration-100"
                    leaveFrom="transform opacity-100 translate-x-0 shadow-xl"
                    leaveTo="transform opacity-50 translate-x-0 -translate-y-24 shadow-sm"
                    show={active === 'member'}>


                    <div className="flex flex-col gap-1 p-1">
                        {memberNavItems.map(item => {
                            if (item.onClick) return (
                                <div className="flex flex-row gap-3 px-3.5 py-2.5 items-center rounded-sm transition hover:outline hover:outline-lime-500 bg-slate-900 cursor-pointer"
                                    key={item.label}
                                    onClick={item.onClick}>
                                    <item.icon className="w-5 h-5 text-white"></item.icon>
                                    <span className="font-semibold text-base text-white">
                                        {item.label}
                                    </span>
                                </div>
                            )
                            return (
                                <NavLink className={({ isActive }) => `flex flex-row gap-3 px-3.5 py-2.5 items-center rounded-sm transition hover:outline hover:outline-lime-500 ${isActive ? 'bg-slate-900' : ''}`}
                                    key={item.href}
                                    onClick={navItemSelection}
                                    to={item.href}>
                                    <item.icon className="w-5 h-5 text-white"></item.icon>
                                    <span className="font-semibold text-base text-white">
                                        {item.label}
                                    </span>
                                </NavLink>
                            )
                        })}

                    </div>

                </Transition>

                <div className="w-full md:w-auto relative flex md:rounded-bl h-16 z-40 bg-slate-950 flex-row items-center py-2 px-4 gap-2">
                    <div className="cursor-pointer rounded-full hover:outline hover:outline-lime-400 group w-12 h-12 shrink-0 flex flex-row items-center justify-center transition ease-in-out"
                        onClick={() => handleNavItemActive('main')}>
                        <LogoNav className="text-sky-200 group-hover:text-white transition ease-in-out w-7 h-7" />
                    </div>
                    <div className="relative grow hidden">
                        <Input
                            autoFocus={true}
                            className="focus:bg-slate-950 text-white pl-10 placeholder-slate-500"
                            placeholder="do something"
                            size="md"
                            theme="transparent"
                        />
                        <div className="absolute top-0 left-0 w-9 h-11 ml-1 shrink-0 flex flex-row items-center justify-center">
                            <PromptArrow className="w-3.5 h-3.5 text-lime-400" />
                        </div>
                    </div>
                    <div className="cursor-pointer group w-10 h-10 shrink-0 flex flex-row items-center justify-center transition ease-in-out ml-auto"
                        onClick={() => handleNavItemActive('member')}>
                        <PageMember />
                    </div>


                </div>




            </div>

            <div className="block md:hidden h-16" />
        </React.Fragment>

    )
}

/*
<Transition
                className="fixed top-0 bottom-0 left-0 right-0 z-40 bg-slate-950/70"
                enter="transition ease-out duration-300"
                enterFrom="opacity-50"
                enterTo="opacity-100"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
                onClick={() => setShow(false)}
                show={show}>
            </Transition>
            <Transition
                className="fixed bottom-0 top-0 z-40 bg-white left-0 w-[300px]"
                enter="transition ease-out duration-300"
                enterFrom="translate-y-0 opacity-0 translate-y-0 -translate-x-24"
                enterTo="translate-y-0 opacity-100 translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100 translate-x-0"
                leaveTo="transform opacity-50 translate-y-0 -translate-x-24"
                show={show}>


                <div className="flex flex-col gap-1 p-1">
                    {navItems.map(item => (
                        <NavLink className={({ isActive }) => `flex flex-row gap-3 px-4 py-3 items-center rounded-lg hover:bg-sky-100 ${isActive ? 'bg-slate-50' : ''}`}
                            key={item.href}
                            to={item.href}>
                            <item.icon className="w-5 h-5 text-slate-600"></item.icon>
                            <span className="font-semibold text-base text-slate-800 uppercase">
                                {item.label}
                            </span>
                        </NavLink>
                    ))}
                    <Button className="absolute top-4 -right-12 z-30" onClick={() => setShow(false)} theme="transparent" shape="round" size="sm-symmetric">
                        <CloseIcon className="w-5 h-5 text-gray-500 group-hover/button:text-white" />
                    </Button>

                </div>

            </Transition>
*/