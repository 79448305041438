import constants from './constants'
import model from '../index'

import utilType from '../../utility/type'

const getConnectionKey = ({ model, relation })=>{
	return `${relation}~${model}`
}

const getCountForConnections = ({ connections })=>{
	if (!connections) return 0
	return Object.values(connections).reduce((total, connection)=>{
		if (!connection.length) return total
		return total + connection.length
	}, 0)
}

const getModelsConnectedToSubject = ({ subject })=>{
	let models = []
	
	for (let i in constants.subjectRelations){
        const relations = constants.subjectRelations[i]
        relations.forEach(rel => {
            if (constants.relations[rel] && constants.relations[rel].subjectModel){
				const model = constants.relations[rel].subjectModel
                if (model === subject.model && !models.includes(i)) models.push(i)
            }
        })
    }

	return models
}

const getOrderByPriorityForRecRelationActive = ({rec, relationActive})=>{
	let ordered = [], used = {}

	if (!rec || !rec.connections) return ordered

	const addToOrdered = ({subject})=>{
		if (!subject) return
		const usedInd = `${subject.model}~${subject.id}`
		if (used[usedInd]) return
		used[usedInd] = true
		ordered.push(subject)
	}

	if (rec.displayMediaId && (!relationActive || relationActive === 'all' || relationActive === 'media')){
		addToOrdered({subject: {
			model: 'media',
			id: rec.displayMediaId,
		}})
	}

	var i = 0
	let isResults = true

	while (isResults){
		isResults = false
		if (!relationActive || relationActive === 'all'){

			Object.values(rec.connections).forEach(connections => { //eslint-disable-line
				if (utilType.get(connections) === 'ary' && connections.length > i){
					addToOrdered({subject: connections[i]})
					isResults = true
				}
			})
		} else {
			if (rec.connections && rec.connections[relationActive] && utilType.get(rec.connections[relationActive]) === 'ary' && rec.connections[relationActive].length > i){
				addToOrdered({subject: rec.connections[relationActive][i]})
				isResults = true
			}
		}
		i++
	}

	return ordered
}

const getPageForSubjects = ({ pageSize, subjects })=>{
	if (!subjects || subjects.length < 1) return {}
	let pages = {}
	let subjectsLeft = [...subjects]
	let page = 1
	while (subjectsLeft.length > 0){
		pages[page] = subjectsLeft.slice(0, pageSize)
		subjectsLeft = subjectsLeft.slice(pageSize)
		page++
	}
	return pages
}

const getRelationLabel = ({ relation }) => {
	if (!constants.relations[relation]) return 'unknown'
	return constants.relations[relation].label
}

const getRelationOptions = () => {
	return Object.keys(constants.relations).map(relation => {
		const def = constants.relations[relation]
		return {
			label: def.label,
			value: relation
		}
	})
}

const getRelationOptionsForSubject = ({subject}) => {

	if (!constants.subjectRelations[subject.model]) return []

	const relations = constants.subjectRelations[subject.model]

	return relations.map(relation => {
		return constants.relations[relation]? {
			label: constants.relations[relation].label,
			value: relation
		}: {
			label: relation,
			value: relation,
		}
	})
}

const getRelationSubjectModel = ({ relation }) => {
	if (!constants.relations[relation]) return null
	return constants.relations[relation].subjectModel
}

const getSubjectsFromConnections = ({connections})=>{
	let subjects = []
	for (let relation in connections){
		if (utilType.get(connections[relation]) === 'ary' && connections[relation].length > 0){
			subjects = [
				...subjects,
				...connections[relation]
			]
		}
	}
	return subjects
}

const getTwinConnection = ({ model, relation })=>{

	const key = getConnectionKey({relation, model})

	let found
	if (constants.twins[key]) { // look for matching key
		found = constants.twins[key]
	} else {
		found = Object.keys(constants.twins).find( ind => constants.twins[ind] === key )
	}
	
	if (!found) return null

	const foundSplit = found.split('~')
	return {
		relation: foundSplit[0],
		model: foundSplit[1],
	}
}

const isConnectedBySubject = ({ connections, relation, subject }) => {

	if (!connections) return false

	if (relation) {
		if (!connections[relation]) return false
		const type = utilType.get(connections[relation])
		if (type === 'obj') {
			if (model.helper('subject.isMatch', { subject1: connections[relation], subject2: subject })) {
				return true
			}
		}
		if (type === 'ary') {
			for (let i = 0, len = connections[relation].length; i < len; i++) {
				if (model.helper('subject.isMatch', { subject1: connections[relation][i], subject2: subject })) {
					return true
				}
			}
		}
	}

	return false
}

model.registerHelpers(constants.model, {
	getCountForConnections,
	getTwinConnection,
	getModelsConnectedToSubject,
	getOrderByPriorityForRecRelationActive,
	getPageForSubjects,
	getRelationLabel,
	getRelationOptions,
	getRelationOptionsForSubject,
	getRelationSubjectModel,
	getSubjectsFromConnections,
	isConnectedBySubject,
})