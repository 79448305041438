import React from 'react'

import {useNavigate} from 'react-router-dom'

import Button from '../Elements/Button'
import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'

import MediaRowUpload from '../MediaRowUpload'

import { ReactComponent as UploadIcon } from '../../icon/upload.svg'

export default function UploadMediaProgress({ handleRemoveMedia, media }) {

    const navigate = useNavigate()

    const handleDone = ()=>{
        navigate('/member')
    }

    return (
        <Frame>
            <FrameTitle>
                <UploadIcon className="w-3.5 h-3.5 text-lime-400" />
                <div className="text-white font-semibold text-base">
                    uploading media
                </div>
            </FrameTitle>
            <div className="flex flex-col justify-center items-center grow">
                {media.map((item, i) => (
                    <MediaRowUpload key={i} ind={i} media={item} handleRemoveMedia={handleRemoveMedia} intent="uploading" />
                ))}
            </div>
            <div className={`w-full h-16 sticky bottom-0 z-20 flex flex-row gap-4 px-6 items-center grow bg-slate-950`}>
                <Button className="ml-auto" onClick={handleDone} theme="primary">DONE</Button>
            </div>
        </Frame>
    )
}