
import axios from 'axios'
import config from '../config'

const types = {
	image: 'image',
	video: 'video',
	raw: 'raw',
	auto: 'auto'
}

const upload = async ({ file, handleProgress, preset, publicId, type }) => {

	const uploadPreset = preset ? config.cloudinary.uploadPresets[preset] : config.cloudinary.uploadPresets[config.cloudinary.uploadPresetDefault]
	const mediaType = types[type]

	const data = new FormData()
	data.append('file', file)
	data.append('upload_preset', uploadPreset)
	if (publicId) data.append('public_id', publicId)

	const url = `https://api.cloudinary.com/v1_1/${config.cloudinary.cloudName}/${mediaType}/upload`

	const options = {
		onUploadProgress: handleProgress,
	}

	const res = await axios.post(url, data, options)

	/*
		data: {
			access_mode: "public"
			asset_id: "8f63d6ab7545d65466da7c3d2ddf7b39"
			bytes: 1646516
			created_at: "2022-09-14T06:52:18Z"
			etag: "91c167c0fc744c9fea20d75903fb1332"
			folder: "member-images"
			format: "jpg"
			height: 2576
			original_extension: "JPG"
			original_filename: "IMG_0854"
			placeholder: false
			public_id: "member-images/gu3ft1uyfbrdvnsolw8z"
			resource_type: "image"
			secure_url: "https://res.cloudinary.com/everspring-collective/image/upload/v1663138338/member-images/gu3ft1uyfbrdvnsolw8z.jpg"
			signature: "e9a284dca31006c2b44d44fd190ae6b6aa2456b3"
			tags: []
			type: "upload"
			url: "http://res.cloudinary.com/everspring-collective/image/upload/v1663138338/member-images/gu3ft1uyfbrdvnsolw8z.jpg"
			version: 1663138338
			version_id: "901619db5ab392e9475f39cf3a13f033"
			width: 1932
		}
	*/

	const formatted = {
		assetId: res.data.asset_id,
		folder: res.data.folder,
		format: res.data.format,
		height: res.data.height,
		extension: res.data.original_extension,
		publicId: res.data.public_id,
		type: res.data.resource_type,
		url: res.data.secure_url,
		method: res.data.type,
		version: res.data.version,
		width: res.data.width
	}

	return formatted
}

const exp = {
	upload
}

export default exp