import constants from './constants'
import model from '../index'
import store from '../../store'

import utilApi from '../../utility/api'

const add = async ({ title })=>{
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.add: no authToken found`)

	const data = {
		action: `${constants.model}.add`,
		authToken, 
		title
	}

	const rec = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: 'STORY_REC_SET',
		payload: {
			rec,
		}
	})

	return rec
}

const getById = async ({id})=>{
	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: 'cover.getById',
		authToken,
		id,
	}

	const {rec} = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type:'COVER_REC_SET',
		payload: {
			rec,
		}
	})

	return rec
}

model.registerActionsLambda(constants.model, {
    add,
    getById,
})