import constants from './constants'

const state = {
	alert: null,
	loading: false,
	modal: null,
}

export const reducer = (st = state, action)=>{
	switch (action.type){
		case `${constants.reducerPrefix}_ALERT_SET`:
			return {...st, alert: action.payload}
		case `${constants.reducerPrefix}_LOADING_SET`:
			return {...st, loading: action.payload}
		case `${constants.reducerPrefix}_MODAL_SET`:
			return {...st, modal: action.payload}
		default:
			return st
  }
}