import React from 'react'

import { ReactComponent as CheckIcon } from '../../icon/check.svg'
import { ReactComponent as CloseIcon } from '../../icon/close.svg'

export default function MediaRowUploadProgress ({ ind, handleRemoveMedia, media }) {
    switch (media.status) {
        case 'uploading':
            let percComplete = 0
            if (media.progress) {
                percComplete = Math.ceil((media.progress.loaded / media.progress.total) * 100)
            }
            return (
                <div className="flex flex-col items-stretch bg-slate-900">
                    <div className="flex flex-row items-center gap-2 px-5 py-5">

                        <div className="h-2.5 bg-sky-800 grow rounded-lg overflow-hidden">
                            <div className="h-2.5 animate-pulse bg-sky-300 dark:bg-sky-500 rounded-sm" style={{ width: `${percComplete}%` }}/>
                        </div>
                        <div className="flex flex-row items-center justify-center w-8 h-8 rounded-full
                                opacity-70 hover:opacity-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            onClick={() => handleRemoveMedia({ ind })}>
                            <CloseIcon className="w-6 h-6 text-white" />
                        </div>
                    </div>
                </div>

            )
        case 'added':
            return (
                <div className="flex flex-col items-stretch bg-slate-900">
                    <div className="flex flex-row items-center gap-2 px-5 py-5">

                        <div className="h-2.5 bg-lime-800 grow rounded-lg overflow-hidden">
                            <div className="h-2.5 bg-lime-400 rounded-sm" style={{ width: '100%' }}/>
                        </div>
                        <div className="flex flex-row items-center justify-center w-8 h-8 rounded-full
                                transition ease-in-out">
                            <CheckIcon className="w-6 h-6 text-lime-300" />
                        </div>
                    </div>
                </div>

            )
        case 'error':
            return (
                <div className="flex flex-col items-stretch bg-slate-900">
                    <div className="flex flex-row items-center gap-2 px-5 py-5">

                        <div className="h-2.5 bg-rose-800 grow rounded-lg overflow-hidden">
                            <div className="h-2.5 bg-rose-500 rounded-sm" style={{ width: '100%' }}/>
                        </div>
                        <div className="flex flex-row items-center justify-center w-8 h-8 rounded-full
                                opacity-70 hover:opacity-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            onClick={() => handleRemoveMedia({ ind })}>
                            <CloseIcon className="w-6 h-6 text-white" />
                        </div>
                    </div>
                </div>

            )
        default:
            return <div />
    }
}
