
import React from 'react'

import ReactMarkdown from 'react-markdown'

export default function MarkdownText({children, className = '', elementClassNames = {}, truncate}) {

    children = children && children.length > truncate ? children.slice(0,truncate)+'...': children

    return (
        <ReactMarkdown 
            components={{
                p: ({node, ...props}) => <p className={`${className} ${elementClassNames.p? elementClassNames.p : ''}`} {...props} />
            }}>
            {children}
        </ReactMarkdown>
    )
}






