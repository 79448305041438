import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as CloseIcon } from '../../icon/close.svg'
import { ReactComponent as EditIcon } from '../../icon/edit-icon.svg'
import { ReactComponent as MemberIcon } from '../../icon/member.svg'

import Container from '../Elements/Container'
import Button from '../Elements/Button'
import ButtonIcon from '../Elements/ButtonIcon'
import FormField from '../Elements/FormField'
import FrameTitle from '../Frame/FrameTitle'
import Input from '../Elements/Input'
import Textarea from '../Elements/Textarea'

import model from '../../model'

export default function MemberPageEdit({ id, pathSuffix, rec, setView }) {

    const isAdmin = useSelector(state => model.selector('member.isAdmin', { state }))
    const isSelf = useSelector(state => model.selector('member.isSelf', { state, memberId: id }))

    const [modified, setModified] = useState(rec)

    const navigate = useNavigate()

    /*
    const imageStyle = {
        backgroundImage: `url(${model.helper('member.getImageUrl', { member: rec })})`
    }
    */

    const handleClose = () => {
        navigate(`${pathSuffix}`)
    }

    const handlePropSet = (prop, val) => {
        setModified({
            ...modified,
            [prop]: val
        })
    }

    const handleCancel = () => {
        setModified(rec)
    }

    const handleSave = async () => {
        try {
            await model.action('app.setLoading', true)
            await model.action('member.update', {
                id,
                about: modified.about,
                firstName: modified.firstName,
                lastName: modified.lastName,
            })
            await model.action('app.setAlert', {
                message: 'member saved',
                theme: 'success'
            })

            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })

        }
    }

    //const fullName = model.helper('member.formatFullName', rec)

    const isChanged = JSON.stringify(rec) !== JSON.stringify(modified)

    let actions = []
    if (isSelf || isAdmin) actions.push({
        label: 'edit',
        onClick: () => {
            setView('edit')
        },
    })
    if (isAdmin) actions.push({
        label: 'invite',
        onClick: async () => {
            await model.action('app.setModal', {
                member: rec,
                modal: 'memberInvite'
            })
        },
    })
    if (isSelf) actions.push({
        label: 'logout',
        onClick: async () => {
        },
    })

    useEffect(() => {
        if (rec && !modified) {
            setModified(rec)
        }
    }, [rec]) // eslint-disable-line

    if (!rec) {
        return <div />
    }

    return (
        <React.Fragment>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <MemberIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    member
                </div>
                <div className="pl-2.5 py-0 bg-lime-600 rounded flex flex-row gap-1 items-center">
                    <div className=" text-white text-base font-semibold">
                        edit
                    </div>
                    <ButtonIcon className="" onClick={handleClose} theme="transparent">
                        <CloseIcon className="text-white/50 group-hover:text-white w-6 h-6" />
                    </ButtonIcon>
                </div>
            </FrameTitle>

            <div className="w-full bg-white relative">
                <Container className="flex flex-col gap-6 py-6 md:py-10 lg:py-12 xl:py-14">
                    <FormField
                        label="first name"
                        name="firstName"
                        size="md">
                        <Input 
                            className="text-xl" 
                            placeholder="first name" 
                            setValue={val => handlePropSet('firstName', val)} 
                            size="md" 
                            value={modified.firstName} />
                    </FormField>
                    <FormField
                        label="last name"
                        name="lastName"
                        size="md">
                        <Input 
                            className="text-xl"
                            placeholder="last name" 
                            setValue={val => handlePropSet('lastName', val)} 
                            size="md" 
                            value={modified.lastName} />
                    </FormField>
                    <FormField
                        label="about"
                        name="about"
                        size="md">
                        <Textarea 
                            placeholder="member about" 
                            setValue={val => handlePropSet('about', val)} 
                            size="md" 
                            value={modified.about} />
                    </FormField>
                    
                </Container>
                {isChanged && (
                    <div className={`w-full h-16 sticky bottom-0 z-20 flex flex-row gap-4 px-6 items-center grow bg-slate-950`}>
                        <EditIcon className="w-5 h-5 text-lime-400" />
                        <div className="text-white text-base font-semibold">save changes?</div>
                        <Button className="ml-auto" disabled={!isChanged} onClick={handleCancel} theme="">CANCEL</Button>
                        <Button className="" disabled={!isChanged} onClick={handleSave} theme="primary">SAVE</Button>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}