import React, { useRef, useState } from 'react'

import { useSelector } from 'react-redux'

import model from '../../model'
import utilFile from '../../utility/file'

import Button from '../Elements/Button'
import NotAuthorized from '../NotAuthorized'
import MediaGrix from '../MediaGrix'
import ModalTitle from './ModalTitle'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'

export default function ModalUploadMediaSingle({ handleClose, handleDone }) {

    const authedMember = useSelector(state => model.selector('member.authedMember', { state }))

    const [media, setMedia] = useState([])
    const mediaRef = useRef(media)
    const [dragActive, setDragActive] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const inputUpload = useRef(null)

    const handleSetMediaItem = ({ handle, item }) => {
        const findInd = mediaRef.current.findIndex(med => {
            return med.handle === handle
        })
        if (findInd === -1) return
        let mediaNew = [
            ...mediaRef.current
        ]
        for (let i in item) {
            mediaNew[findInd][i] = item[i]
        }
        mediaRef.current = mediaNew
        setMedia(mediaNew)
    }

    const handleChange = async (e) => {
        let files = [...e.target.files]
        handleFiles(files)
    }

    const handleClear = () => {
        mediaRef.current = []
        setMedia([])
        setIsSubmitted(false)
    }

    const handleFiles = async (files) => {
        let mediaNew = files.map(file => {
            if (file.type.includes('video')) {
                let blobURL = URL.createObjectURL(file)

                return {
                    blobURL,
                    name: file.name,
                    file,
                    type: 'video',
                }
            } else {
                return {
                    name: file.name,
                    file,
                    type: 'image',
                }
            }

        })

        for (let i = 0, len = mediaNew.length; i < len; i++) {
            let url = await utilFile.getUrl(mediaNew[i].file)
            // get media type from file
            mediaNew[i].url = url
            mediaNew[i].handle = mediaNew[i].name
            mediaNew[i].status = 'selected'
        }
        const mediaSet = [...mediaRef.current, ...mediaNew]
        mediaRef.current = mediaSet

        setMedia(mediaSet)
    }

    const handleProgressForMedia = ({ media, progress }) => {
        handleSetMediaItem({ handle: media.handle, item: { progress } })
    }

    const handleSelect = () => {
        inputUpload.current.click()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsSubmitted(true)

        Promise.all(mediaRef.current.map(async med => {

            try {
                handleSetMediaItem({ handle: med.handle, item: { status: 'uploading' } })

                const rec = await model.action('media.add', {
                    file: med.file,
                    handleProgress: progress => handleProgressForMedia({ media: med, progress }),
                    type: med.type,
                })

                if (handleDone) handleDone({ rec })

                handleSetMediaItem({ handle: med.handle, item: { rec, status: 'added' } })

            } catch (e) {
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })

                handleSetMediaItem({ handle: med.handle, item: { status: 'error', error } })
            }
        }))
    }

    // handle drag events
    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true)
        } else if (e.type === "dragleave") {
            setDragActive(false)
        }
    }

    // triggers when file is dropped
    const handleDrop = (e) => {
        console.log('drop:', e.dataTransfer.files)
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            let files = [...e.dataTransfer.files]
            handleFiles(files)
        }
    }

    const handleRemoveMedia = ({ ind }) => {
        if (media.length === 1 && ind === 0) {
            handleClear()
        }
        const mediaNew = [...media.slice(0, ind), ...media.slice(ind + 1)]
        mediaRef.current = mediaNew
        setMedia(mediaNew)
    }

    const renderProgressBar = ({item}) => {
        switch (item.status) {
            case 'uploading':
                let percComplete = 0
                if (item.progress) {
                    percComplete = Math.ceil((item.progress.loaded / item.progress.total) * 100)
                }
                return (
                    <div className="h-2.5 bg-sky-800 grow rounded-lg overflow-hidden">
                        <div className="h-2.5 animate-pulse bg-sky-300 dark:bg-sky-500 rounded-sm" style={{ width: `${percComplete}%` }} />
                    </div>
                )
            case 'added':
                return (
                    <div className="h-2.5 bg-lime-800 grow rounded-lg overflow-hidden">
                        <div className="h-2.5 bg-lime-400 rounded-sm" style={{ width: '100%' }} />
                    </div>
                )
            default:
                return (
                    <div className="h-2.5 bg-rose-800 grow rounded-lg overflow-hidden">
                        <div className="h-2.5 bg-rose-500 rounded-sm" style={{ width: '100%' }} />
                    </div>
                )
        }
    }

        /*
        {media && media.map((item, i) => {
    
            if (item.type === 'video') {
                return (
                    <video width="320" height="240" src={item.blobURL} controls autoplay>
                        Your browser does not support the video tag.
                    </video>
                )
            }
            if (item.type === 'image') {
                return (
                    <div className="relative flex flex-row items-center justify-center h-48 md:h-64 bg-slate-200 pb-[100%] bg-center bg-no-repeat bg-cover"
                        key={item.name || item.url || i}
                        style={{ backgroundImage: `url(${item.url})` }} >
    
                        {!item.url && item.name}
                    </div>
                )
            }
    
            return <div/>
        })}
    
        /*
            <Input
                            accept=""
                            className="hidden"
                            onChange={handleChange}
                            name="image"
                            ref={inputUpload}
                            theme=""
                            type="file"
                            multiple={true}
                        />
        */

        if (!authedMember) {
            return (
                <NotAuthorized />
            )
        }

        if (isSubmitted) return (
            <div>
                <ModalTitle handleClose={handleClose}>
                    <div className="flex flex-col items-center gap-2">
                        <div className="text-xl text-white font-semibold uppercase leading-6">
                            Uploading Media
                        </div>
                    </div>
                </ModalTitle>
                <ModalBody>
                    <div className="">
                        {media.map((item, i) => {

                            /*
                            let percComplete = 0
                            if (item.progress) {
                                percComplete = Math.ceil((item.progress.loaded / item.progress.total) * 100)
                            }
                            */
                            return (
                                <div className="" key={i}>
                                    <MediaGrix intent="upload" ind={i} media={item}>

                                    </MediaGrix>
                                    <div className="mt-5">
                                        {renderProgressBar({item})}
                                    </div>
                                </div>
                            )
                        })}
                    </div>


                </ModalBody>
            </div>
        )

        return (
            <div>
                <ModalTitle handleClose={handleClose}>
                    <div className="flex flex-col items-center gap-2">
                        <div className="text-xl text-white font-semibold uppercase leading-6">
                            Upload Media
                        </div>
                    </div>
                </ModalTitle>
                <ModalBody>

                    {(!media || media.length < 1) ? (

                        <div className="w-full bg-white flex flex-row items-stretch">
                            <div className="mx-auto relative min-h-[32vh] lg:min-h-[32vh] w-full shrink-0">
                                <div className={`border-2 absolute top-0 bottom-0 left-0 right-0 rounded-lg 
                                flex flex-col items-center justify-center transition ease-in-out ${dragActive ? 'bg-lime-200 border-lime-300 border-solid' : 'bg-white border-lime-300 border-dashed'}`}
                                    onSubmit={(e) => e.preventDefault()}
                                    onDragEnter={handleDrag}
                                    onDragLeave={handleDrag}
                                    onDragOver={handleDrag}
                                    onDrop={handleDrop}>

                                    <div className="font-regular text-base text-slate-800 mb-4">
                                        Drag and drop files or
                                    </div>
                                    <Button onClick={handleSelect} theme="primary">Select File(s)</Button>

                                    <input className="hidden" onChange={handleChange} ref={inputUpload} type="file" />

                                </div>
                            </div>

                        </div>
                    ) : (
                        <div className="">
                            {media.map((item, i) => (
                                <MediaGrix intent="upload" key={i} ind={i} media={item} handleRemoveMedia={handleRemoveMedia}>

                                </MediaGrix>
                            ))}
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <div className="flex flex-row">
                        <Button className="ml-auto" disabled={!media || media.length < 1} size="md" onClick={handleSubmit}>Upload</Button>
                    </div>
                </ModalFooter>
            </div>
        )
    }