import React, { useEffect } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as ProjectIcon } from '../../icon/project.svg'

import Container from '../Elements/Container'
import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'
import ProjectPageDisplay from './ProjectPageDisplay'
import ProjectPageEdit from './ProjectPageEdit'
import SubjectGrixis from '../SubjectGrixis'

import model from '../../model'

export default function ProjectPage() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { id } = useParams()

    const rec = useSelector(state => model.selector('project.recById', { state, id }))

    const pathSuffix = `/project/${id}`

    const subject = {
        model: 'project',
        id: id,
    }

    useEffect(() => {
        if (!id) return
        (async () => {
            try {
                await model.action('app.setLoading', true)
                await model.action('project.getById', { id })
                await model.action('app.setLoading', false)
            } catch (e) {
                await model.action('app.setLoading', false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, [id])

    if (!rec) {
        return (
            <Frame>
                <FrameTitle>
                    <div className="flex flex-row items-center">
                        <ProjectIcon className="w-5 h-5 text-lime-400" />
                    </div>
                    <div className="text-white font-bold text-lg">
                        project
                    </div>
                </FrameTitle>
                <div className="w-full bg-white">
                    <Container className="flex flex-col gap-6 py-6 md:py-10 lg:py-12 xl:py-14">
                        <div className="animate-pulse h-6 bg-slate-300 rounded-full dark:bg-slate-600 w-[220px]" />
                        <div className="flex flex-col gap-2">
                            <div className="animate-pulse h-3 bg-slate-300 rounded-full dark:bg-slate-600 w-[100%]" />
                            <div className="animate-pulse h-3 bg-slate-300 rounded-full dark:bg-slate-600 w-[60%]" />
                        </div>
                    </Container>
                </div>
                <SubjectGrixis key={id} subject={subject} pathSuffix={pathSuffix} />
            </Frame>
        )
    }

    return (
        <Frame>
            <Routes>
                <Route path="edit" element={<ProjectPageEdit id={id} pathSuffix={pathSuffix} rec={rec} />} />
                <Route path="*" element={<ProjectPageDisplay id={id} pathSuffix={pathSuffix} rec={rec} />} />
            </Routes>
            <SubjectGrixis key={id} subject={subject} pathSuffix={pathSuffix} />
        </Frame>
    )
}