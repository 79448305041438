import config from '../config.js'
import axios from 'axios'

class APIError extends Error {
	constructor(e, message) {
		super(message);
		this.response = e? e.response: null;
	}
}

const req = async ({endpoint, method, data, query, queryStr, token, url})=>{
	if (endpoint && !url) url = getUrl({endpoint, queryStr})

	const headers = await getHeaders({token})
	const params = query? query: undefined 

	try {
		switch (method){
			case 'delete':{
				const res = await axios.delete(url, {
					headers,
					params
				})
				return res.data
			}
			case 'get':{
				const res = await axios.get(url, {
					headers,
					params,
				})
				return res.data
			}
			case 'post':{
				const res = await axios.post(url, data, {
					headers,
					params,
				})
				return res.data
			}
			case 'put':{
				const res = await axios.put(url, data, {
					headers,
					params,
				})
				return res.data
			}
			default:
				throw new Error(`method not recognized: ${method}`)

		}
	} catch(e){
		if (!e || !e.response || !e.response.data || !e.response.data.msg){
			const err = `Something went wrong! Missing response (Please try again)`
			throw new APIError(e, err)
		}

		// compose custom error
		if (e.response.data && e.response.data.msg){
			const err = `${e.response.data.msg}`
			throw new APIError(e, err)
		}

		throw new Error(e)
	}
}

const getHeaders = async ({token})=>{
	let headers = {
		'accept': 'application/json',
		'accept-language': 'en',
		'content-type': 'application/json',
	}

	return headers
}

const getUrl = ({endpoint, queryStr})=>{
	return `${config.apiHost}/${endpoint}${queryStr? `?${queryStr}`: ''}`
}

const api = {
	getUrl,
	req,
}

export default api