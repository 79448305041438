import React from 'react'

export default function Container({ children, className, size = 'standard', style = {} }) {

    let baseClassName = 'px-4 md:px-6 w-full mx-auto lg:max-w-[900px] xl:max-w-[1000px] 2xl:max-w-[1100px] shrink-0'

    return (
        <div className={`${baseClassName} ${className}`} style={style}>
            {children}
        </div>
    )
}