import constants from './constants'
import model from '../index'
import date from '../../utility/date'

const formatWhen = ({when})=>{
	if (!when || when.length < 1) return ''
	if (when.length > 1 && when[1][0]){
		if (when[0][0] === when[1][0]) return `${date.getMonthFromInd(when[0][1])} - ${date.getMonthFromInd(when[1][1])} ${when[0][0]}`
		return `${date.getMonthFromInd(when[0][1])} ${when[0][0]} - ${date.getMonthFromInd(when[1][1])} ${when[1][0]}`
	}
	return `${date.getMonthFromInd(when[0][1])} ${when[0][0]}`
}

const getPathFromSubject = ({ subject }) => {
    return `/chapter/${subject.id}`
}

const getSubjectFromId = ({ id }) => {
    return {
        model: constants.model,
        id,
    }
}

const getSubjectFromRec = ({ rec }) => {
    if (!rec) return null
    return {
        model: constants.model,
        id: rec._id,
    }
}

const isChapterBefore = ({chap1, chap2})=>{
	if (!chap1.when || !chap2.when) return true 
	if (chap1.when[0] < chap2.when[0]) return true
	if (chap1.when[0][0] < chap2.when[0][0]) return true
	return false
}

const isOwner = ({ memberAuthed, rec }) => {
    if (!rec) return false
    const subject = getSubjectFromRec({ rec })
    return model.helper('connection.isConnectedBySubject', { connections: rec.connections, relation: 'uploader', subject })
}

model.registerHelpers(constants.model, {
	formatWhen,
	getPathFromSubject,
	getSubjectFromId,
	getSubjectFromRec,
	isChapterBefore,
	isOwner,
})