import React from 'react'
import 'normalize.css/normalize.css'
import './App.css'

import Page from './component/Page'

import {Provider} from 'react-redux'
import Store from './store'

import './model/init'

function App() {
	return (
		<Provider store={Store}>
			<Page/>
		</Provider>
	);
}

export default App