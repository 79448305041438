import constants from './constants'
import model from '../index'

const getDevice = ()=>{ // detect device
	if (window.innerWidth < 576 || window.innerHeight < 576){
		return 'mobile'
	}
	return 'desktop'
}

const getErrorMessage = (e)=>{
	return e && e.toString? e.toString().replace(/Error:/, ''): e
}

const getForwardQuery = ({data})=>{
	if (data && data.items && data.items.details && data.items.details.length === 1){
		const item = data.items.details[0]
		return item.query
	}
	return null
}

const getSubMenuItem = ({data})=>{
	if (!data) return null
	let item = {
		title: '',
		details: []
	}

	if (data.label) item.title = data.label
	if (data.details) item.details = data.details

	return item
}

const getSubMenuPage = ({data})=>{
	if (!data) return null
	let page = {
		title: '',
		subtitle: '',
		menu: []
	}

	if (data.title) page.title = data.title
	if (data.subtitle) page.subtitle = data.subtitle
	if (data.sections) page.menu = data.sections
	if (data.items && data.items.details) page.menu = data.items.details

	return page
}

const isLabelImportant = ({label})=>{
	if (['details', 'groups', 'sections'].includes(label)) return false
	return true
}

// check if the app should forward to a new page
const shouldDataForward = ({data})=>{
	if (data && data.items && data.items.details && data.items.details.length === 1){
		return true
	}
	return false
}

model.registerHelpers(constants.model, {
	getDevice,
	getErrorMessage,
	getForwardQuery,
	getSubMenuItem,
	getSubMenuPage,
	isLabelImportant,
	shouldDataForward,
})