import model from '../index'

const state = {
	byParent: {},
	recs: {},
}

export const reducer = (st = state, action)=>{
	switch (action.type){
		case 'RESERVATION_BY_PARENT_SET': {
			const {parent, recs} = action.payload
			const index = model.helper('reservation.getParentIndex', {parent})

			let list = []
			let write = {}
			recs.forEach(rec => {
				write[rec._id] = rec
				list.push(rec._id)
			})
			const recsNew = {
				...st.recs,
				...write,
			}
			const byParent = {
				...st.byParent,
				[index]: list,
			}
			return {...st, byParent, recs: recsNew}
		}
		case 'RESERVATION_REMOVE': {
			const {id} = action.payload

			const { [id]: _, ...recs } = st.recs

			return {...st, recs}
		}
		case 'RESERVATION_REC_SET': {
			const {rec} = action.payload

			const recs = {
				...st.recs,
				[rec._id]: rec,
			}

			return {...st, recs}
		}
		default:
			return st
  }
}