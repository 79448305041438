import React from 'react'

export default function Frame({ children, className, style }) {

    const baseClassName = 'w-full lg:max-w-[1000px] xl:max-w-[1200px] 2xl:max-w-[1400px] mx-auto mb-36'

    return (
        <div className={`${baseClassName} ${className}`} style={style}>
            {children}
        </div>
    )
}