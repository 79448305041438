import TextareaAutosize from 'react-textarea-autosize'

export default function Textarea({ autoFocus, className, disabled, isError, multiple, name, onChange, placeholder, setValue, size, theme, type = 'text', value }) {

    let enabledClass,
        sizeClass,
        textClass,
        themeClass

    switch (theme){
        case 'transparent':
            themeClass = `bg-transparent text-slate-800 font-normal border border-transparent hover:border-lime-400 focus:border-lime-500 focus:ring-lime-500 placeholder-slate-600`
            break
        default:
            themeClass = `text-slate-800 font-normal border-0 ring-1 ring-inset ring-slate-300 hover:ring-slate-400 focus:ring-slate-500 focus:outline focus:outline-2 focus:outline-offset-4 focus:outline-lime-400 placeholder-slate-300`
    }

    switch (size){
        case 'sm':
            sizeClass = `px-3 py-2 text-sm rounded`
            textClass = 'font-semibold'
            break
        case 'lg':
            sizeClass = `px-4 py-3 text-xl rounded`
            textClass = 'font-semibold'
            break
        default: // md
            sizeClass = `px-3.5 py-2.5 text-md rounded`
            textClass = 'font-semibold'
            break
    }

    if (disabled){
        enabledClass = 'opacity-50 focus:outline-transparent pointer-events-none'
    } else {
        enabledClass = 'shadow-sm hover:shadow-lg hover:scale-100 focus:shadow-lg focus:scale-100 transition ease-in-out'
    }

    const handleChange = (e)=>{
        if (onChange) onChange(e)
        if (setValue) setValue(e.target.value)
    }

    return (
        <TextareaAutosize 
            autoComplete="off"
            autoFocus={autoFocus}
            className={`block w-full ${disabled? 'cursor-default': ''} ${isError? 'ring-red-500 focus:ring-red-500': ''} ${enabledClass} ${sizeClass} ${themeClass} ${textClass} ${className || ''}`}
            disabled={disabled}
            key={name}
            multiple={multiple}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            style={{resize: 'none'}}
            type={type}
            value={value}
        />
    )
}