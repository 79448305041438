import constants from './constants'
import model from '../index'

import utilObj from '../../utility/obj'

const all = ({state})=>{
	if (!state[constants.reduxStore].recs) return []

	return Object.values(state[constants.reduxStore].recs).sort((chap1, chap2) => {
		if (model.helper('chapter.isChapterBefore', {chap1, chap2})) return -1
		return 1
	})
}

const recById = ({state, id})=>{
	if (!state[constants.reduxStore].recs[id]) return null
	return state[constants.reduxStore].recs[id]
}

const recsByStoryId = ({state, storyId})=>{
	if (utilObj.isEmpty(state[constants.reduxStore].recs)) return []
	if (!storyId) return []

	return Object.values(state[constants.reduxStore].recs).filter(rec => {
		if (rec.members.includes(storyId)) return true
		return false
	})
}

const searchRecs = ({state})=>{

	if (!state[constants.reduxStore].searchList) return []

	const ids = state[constants.reduxStore].searchList

	return ids.map(id => {
		if (!state[constants.reduxStore].recs[id]) return null
		return state[constants.reduxStore].recs[id]
	})
}

model.registerSelectors(constants.model, {
	all,
	recById,
	recsByStoryId,
	searchRecs,
})