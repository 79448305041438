const model = 'story'
const collection = 'story'

const fields = {
	_id:{
		type: 'obj',
	},
	chapters:{
		type: 'ary',
		shape: {
			type:'obj'
		}
	},
	connections: {
		type: 'obj',
		key: {
			type: 'str',
			label: 'relation'
		},
		value: {
			type: 'ary',
			item: {
				type: 'obj',
				shape: {
					model: {
						type: 'str'
					},
					id: {
						type: 'obj'
					}
				}
			},
		}
	},
	created: {
		type: 'obj',
		shape: {
			when: {
				type: 'date',
			},
			who: {
				type: 'obj',
			}
		}
	},
    subject: { // subject that this story is for
        type: 'obj',
        shape:{
            model: {
                type: 'str'
            },
            id: {
                type: 'obj'
            },
        },
    },
	title: {
		type: 'str',
		len: [1, 500],
	},
	updated: {
		type: 'obj',
		shape: {
			when: {
				type: 'date',
			},
			who: {
				type: 'obj',
			}
		}
	},
	
}

const exp = {
	collection,
	fields,
	model,
}

export default exp