import React from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import model from '../../model'

import ButtonIcon from '../Elements/ButtonIcon'

import { ReactComponent as CloseIcon } from '../../icon/close.svg'

export default function MediaNugget({ handleDeselect, handleSelect, isEditable, selected, subject }) {

    const rec = useSelector(state => model.selector('subject.rec', { state, subject })) || {}

    const isSelectable = handleSelect ? true : false
    const navigate = useNavigate()

    let selectableClass = isSelectable ? 'hover:bg-slate-950' : ''
    let selectedClass = selected ? 'bg-slate-800' : 'bg-slate-800'

    const url = rec.cloudinary && rec.cloudinary.url ? rec.cloudinary.url : ''

    const handleSelectClick = (e) => {
        e.stopPropagation()
        if (handleSelect) return handleSelect({ subject })

        const path = model.helper('media.getPathFromSubject', { subject })
        navigate(path)
    }
    const handleDeselectClick = (e) => {
        e.stopPropagation()
        if (handleDeselect) handleDeselect({ subject })
    }

    const renderContent = () => {
        if (!rec) return (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-rose-200">
                Rec not found
            </div>
        )

        if (rec.type === 'video') {
            return (
                <video width="100%" height="100%" src={url} controls autoplay>
                    Your browser does not support the video tag.
                </video>

            )
        }
        if (rec.type === 'image') {
            return (
                <div className="absolute group top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-center bg-no-repeat bg-cover"
                    style={{ backgroundImage: `url(${url})` }} >
                </div>

            )
        }

        return (
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-rose-900">
                <div className="font-semibold text-white text-base">
                    type not found
                </div>
            </div>
        )
    }

    if (!rec) {
        return (
            <div>

            </div>
        )
    }

    return (
        <div className={`relative flex flex-row group cursor-pointer items-center gap-3 px-5 h-14 shadow-sm hover:shadow-xl transition opacity-80 hover:opacity-100 rounded ${selectableClass} ${selectedClass}`}
            onClick={handleSelectClick}>

            {renderContent()}

            {selected && isEditable && (
                <ButtonIcon
                    className="ml-auto mr-2"
                    onClick={handleDeselectClick}
                    theme="transparent">
                    <CloseIcon className="w-6 h-6 text-white/50 group-hover:text-white" />
                </ButtonIcon>
            )}

        </div>
    )
}


