import React from 'react'

import model from '../../model'

import { ReactComponent as ChapterIcon } from '../../icon/chapter.svg'

import MarkdownText from '../Elements/MarkdownText'
import DotMenu from '../Elements/DotMenu'
import SubjectGrixis from '../SubjectGrixis'

const Chapter = ({ chapter }) => {

	const subject = {
		model: 'chapter',
		id: chapter._id,
		rec: chapter
	}

	//console.log({chapter})

	const options = []

	const formattedWhen = model.helper('chapter.formatWhen', { when: chapter.when })

	return (
		<React.Fragment>
			<div className="w-full h-14 flex flex-row gap-4 px-6 bg-slate-800 justify-start items-center">
				<ChapterIcon className="w-5 h-5 text-lime-400" />
				<div className="font-semibold text-sm xl:text-base text-lime-400">
					{formattedWhen}
				</div>
				<div className="text-white font-semibold text-base">
					chapter {chapter.num}
				</div>
				
				<DotMenu direction="right" options={options} theme="light" />
			</div>
			<div className="relative">
					<React.Fragment>
						<div className="flex flex-col items-stretch gap-7 px-5 pt-16 pb-16 lg:px-10 lgpy-10 w-full mx-auto md:max-w-[92vw] lg:max-w-[77vw] xl:max-w-[69vw] shrink-0">
							<div className="text-[3.3rem] xl:text-[4.4rem] font-bold text-slate-800 mb-0 leading-12">{chapter.title}</div>
							
							<MarkdownText className="font-regular text-lg xl:text-xl text-slate-800">{chapter.body}</MarkdownText>
						</div>

						<SubjectGrixis subject={subject}/>
					</React.Fragment>

			</div>
		</React.Fragment>
	)
}

// <MediaGallery media={chapter.media}/>

export default Chapter


