
const capitalizeFirst = (str)=>{
	return str.charAt(0).toUpperCase() + str.slice(1)
}

const deparam = (querystring)=>{ // from https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
	// remove any preceding url and split
	querystring = querystring.substring(querystring.indexOf('?')+1).split('&');
	var params = {}, pair, d = decodeURIComponent;
	// march and parse
	for (var i = querystring.length - 1; i >= 0; i--) {
		pair = querystring[i].split('=');
		params[d(pair[0])] = d(pair[1] || '');
	}

	return params;
}

const ellipsis = ({str, len = 100})=>{
	return shorten({str, len})
}

const isUrl = (str)=>{ // from https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
	var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
		'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return !!pattern.test(str);
}

const removeExtension = ({str})=>{
	const lastIndex = str.lastIndexOf('.')
	if (lastIndex === -1) return str
	return str.slice(0, lastIndex)
}

const shorten = ({str, len = 100})=>{
	if (str.length > len){
		return str.substring(0,len)+'...'
	}
	return str
}

const stripLinks = ({str})=>{
	return str.replace(urlRegex, '')
}

const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig // eslint-disable-line
// from https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript

const exp = {
	capitalizeFirst,
	deparam,
	ellipsis,
	isUrl,
	removeExtension,
	shorten,
	stripLinks,
	urlRegex,
}

export default exp