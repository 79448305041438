import React, { useEffect } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as MediaIcon } from '../../icon/media.svg'

import Container from '../Elements/Container'
import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'
import MediaPageDisplay from './MediaPageDisplay'
import MediaPageEdit from './MediaPageEdit'
import SubjectGrixis from '../SubjectGrixis'

import model from '../../model'

export default function MediaPage() {

    const { id } = useParams()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [id])

    const rec = useSelector(state => model.selector('media.recById', { state, id }))

    const subject = model.helper('media.getSubjectFromId', { id })
    const pathSuffix = model.helper('media.getPathFromSubject', { subject })

    useEffect(() => {
        if (!id) return
        (async () => {
            try {
                await model.action('app.setLoading', true)
                await model.action('media.getById', { id })
                await model.action('app.setLoading', false)
            } catch (e) {
                await model.action('app.setLoading', false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, [id])

    if (!rec) {
        return (
            <Frame>
                <FrameTitle>
                    <div className="flex flex-row items-center">
                        <MediaIcon className="w-5 h-5 text-lime-400" />
                    </div>
                    <div className="text-white font-bold text-lg">
                        media
                    </div>
                </FrameTitle>
                <div className="w-full bg-slate-950">
                    <div className="flex-grow bg-slate-950 p-5 flex flex-row items-center justify-center">
                        <div className="animate-pulse h-[300px] w-[70%] bg-slate-700 dark:bg-slate-800" />
                    </div>
                    <Container className="flex flex-col gap-6 py-6 md:py-10 lg:py-12 xl:py-14">
                        <div className="animate-pulse h-6 bg-slate-300 rounded-full dark:bg-slate-600 w-[220px]" />
                        <div className="flex flex-col gap-2">
                            <div className="animate-pulse h-3 bg-slate-300 rounded-full dark:bg-slate-600 w-[100%]" />
                            <div className="animate-pulse h-3 bg-slate-300 rounded-full dark:bg-slate-600 w-[60%]" />
                        </div>
                    </Container>
                </div>
                <SubjectGrixis key={id} subject={subject} pathSuffix={pathSuffix} />
            </Frame>
        )
    }

    return (
        <Frame>
            <Routes>
                <Route path="edit" element={<MediaPageEdit id={id} pathSuffix={pathSuffix} rec={rec} subject={subject} />} />
                <Route path="*" element={<MediaPageDisplay id={id} pathSuffix={pathSuffix} rec={rec} subject={subject}/>} />
            </Routes>
            <SubjectGrixis key={id} subject={subject} pathSuffix={pathSuffix} />
        </Frame>
    )
}