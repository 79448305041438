import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as ChapterIcon } from '../../icon/chapter.svg'
import { ReactComponent as LoveIcon } from '../../icon/heart-empty.svg'
import { ReactComponent as LovedIcon } from '../../icon/heart-solid.svg'

import Container from '../Elements/Container'
import DotMenu from '../Elements/DotMenu'
import FrameTitle from '../Frame/FrameTitle'
import MarkdownText from '../Elements/MarkdownText'

import model from '../../model'

export default function ChapterPageDisplay({ className, id, pathSuffix, rec }) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    const subject = {
        model: 'chapter',
        id,
    }

    const isAdmin = useSelector(state => model.selector('member.isAdmin', { state }))
    const isOwner = model.helper('chapter.isOwner', {rec})

    const formattedWhen = model.helper('chapter.formatWhen', { when: rec.when })

    const path = model.helper('chapter.getPathFromSubject', { subject })

    const memberAuthed = useSelector(state => model.selector('member.authedMember', { state }))
    const isLoved = model.helper('subject.isLoved', { memberAuthed, rec, subject })

    /*
    useEffect(() => {
        const onScroll = () => {
            heroBgRef.current.style.backgroundPosition = `center ${window.pageYOffset / 2}px`
        }
        window.removeEventListener('scroll', onScroll)
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [])
    */

    useEffect(() => {
        (async () => {
            try {
                await model.action('app.setLoading', true)
                await model.action('subject.getRecWithAssociations', { subject })
                await model.action('app.setLoading', false)
                /*
                setTimeout(() => {
                    scrollToChapter()
                }, 300)
                */
            } catch (e) {
                await model.action('app.setLoading', false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, [id]) // eslint-disable-line

    let actions = []

    if (isOwner || isAdmin) actions.push({
        label: 'edit',
        onClick: () => {
            navigate(`${path}/edit`)
        },
    });


    if (isOwner || isAdmin) actions.push({
        label: 'edit connections',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'connectionsEdit',
                connections: rec && rec.connections ? rec.connections : {},
                subject,
            })
        }
    });

    if (isOwner || isAdmin) actions.push({
        label: 'display media',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'displayMediaSelect',
                subject
            })
        }
    });

    const handleShowLove = async (e) => {
        e.stopPropagation()

        if (!memberAuthed){
            // ask user to login or sign up
            await model.action('app.setModal', {
                modal: 'access',
                handleComplete: async ()=>{
                    // show love
                    
                }
            })
            return
        }

        await model.action(`${subject.model}.connectionAdd`, {
            id: subject.id,
            relation: 'loves',
            subject: {
                model: 'member',
                id: memberAuthed._id
            }
        })
    }

    const handleUnLove = async (e) => {
        e.stopPropagation()

        await model.action(`${subject.model}.connectionRemove`, {
            id: subject.id,
            relation: 'loves',
            subject: {
                model: 'member',
                id: memberAuthed._id
            }
        })
    }

    const renderLove = () => {
        if (isLoved) {
            return (
                <div className="cursor-pointer group rounded-full w-8 h-8 flex flex-row items-center justify-center hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-rose-500 focus:outline-2 focus:outline-offset-2 focus:outline-rose-500 transition relative" onClick={handleUnLove}>
                    <LovedIcon className="w-4 h-4 text-rose-700 group-hover:text-rose-600 transition" />
                    <div className="hidden group-hover:flex absolute h-8 px-2 items-center -left-2 bg-slate-950 rounded top-9">
                        <div className="text-sm font-normal text-white whitespace-nowrap">
                            Loved (Unlove)
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="cursor-pointer group rounded-full w-8 h-8 flex flex-row items-center justify-center hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-rose-500 focus:outline-2 focus:outline-offset-2 focus:outline-rose-500 transition relative" onClick={handleShowLove}>
                <LoveIcon className="w-4 h-4 text-rose-700 group-hover:text-rose-600 transition" />
                <div className="hidden group-hover:flex absolute h-8 px-2 items-center -left-2 bg-slate-950 rounded top-9">
                    <div className="text-sm font-normal text-white whitespace-nowrap">
                        Show love
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <ChapterIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    chapter
                </div>
                {renderLove()}
                <DotMenu direction="right" options={actions} theme="light" />
            </FrameTitle>

            <div className="w-full bg-white">
                <Container className="flex flex-col gap-4 py-6 md:py-10 lg:py-12 xl:py-14">
                    <div className="flex flex-col">
                        <h2 className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.5rem] leading-tight font-bold text-slate-800 mb-0">{rec.title}</h2>
                        <div className="font-semibold text-xl xl:text-2xl text-lime-600">
                            {formattedWhen}
                        </div>
                    </div>
                    
                    <MarkdownText className="font-regular text-base md:text-lg lg:text-xl leading-normal text-slate-800">{rec.body}</MarkdownText>
                </Container>
            </div>
        </React.Fragment>
    )
}