import constants from './constants'
import model from '../index'

const depopulateSubjects = ({ subjects }) => {
    return subjects.map(row => {
        let rowCopy = { ...row }
        delete rowCopy.rec
        return rowCopy
    })
}

const getIndex = ({ subject }) => {
    return `${subject.model}~${subject.id}`
}

const getLoveCount = ({rec, subject}) => {
    if (rec.connections && rec.connections.loves){
        return rec.connections.loves.length
    }
    return 0
}

const getSubjectFromIndex = ({ index }) => {
    if (!index) return null
    const parts = index.split('~')
    return {
        subject: parts[0],
        id: parts[1],
    }
}

const isLoved = ({memberAuthed, rec, subject}) => {
    if (!memberAuthed){
        return false
    }
    if (!rec.connections || !rec.connections.loves){
        return false
    }
    const loved = rec.connections.loves.find(connection => {
        return connection.model === 'member' && connection.id === memberAuthed._id
    })
    if (!loved) return false
    return true
}

const getTitle = ({ rec, subjectModel }) => {
    return model.helper(`${subjectModel}.getTitle`, { rec })
}

const isMatch = ({ subject1, subject2 }) => {
    return subject1.model === subject2.model
        && ((subject1.id.equals && subject1.id.equals(subject2.id)) || (subject1.id === subject2.id))
}

/*
const storeRecMap = ({ recMap }) => {
    Object.keys(recMap).forEach(index => {
        const rec = recMap[index]
        const subject = getSubjectFromIndex({ index })
        switch (subject.model) {
            case 'chapter':
                store.dispatch({
                    type: 'CHAPTER_REC_SET',
                    payload: {
                        rec,
                    }
                })
                return
            case 'event':
                store.dispatch({
                    type: 'EVENT_REC_SET',
                    payload: {
                        rec,
                    }
                })
                return
            case 'member':
                store.dispatch({
                    type: 'MEMBER_REC_SET',
                    payload: {
                        rec,
                    }
                })
                return
            case 'project':
                store.dispatch({
                    type: 'PROJECT_REC_SET',
                    payload: {
                        rec,
                    }
                })
                return
            default:
                return null
        }
    })
}
*/


model.registerHelpers(constants.model, {
    depopulateSubjects,
    getIndex,
    getLoveCount,
    getSubjectFromIndex,
    getTitle,
    isLoved,
    isMatch,
})