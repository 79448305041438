import constants from './constants'
import model from '../index'

const recById = ({ state, id }) => {
    if (!state[constants.reduxStore].recs[id]) return null
    return state[constants.reduxStore].recs[id]
}

const recsByConnectionPage = ({ state, connection, page }) => {
    const key = model.helper('media.getConnectionPageKey', { connection, page })

    if (!state[constants.reduxStore].subjectListByConnectionPage || !state[constants.reduxStore].subjectListByConnectionPage[key]) return null

    const list = state[constants.reduxStore].subjectListByConnectionPage[key]
    return list.map(subject => {
        const rec = model.selector('subject.rec', { state, subject })
        return {
            ...subject,
            rec,
        }
    })
}

const recPages = ({ state }) => {
    if (!state[constants.reduxStore].pageIds) return {}

    const pageIds = state[constants.reduxStore].pageIds

    let page = 1, pages = {}

    while (pageIds[page]){
        pages[page] = pageIds[page].map(id => {
            if (!state[constants.reduxStore].recs[id]) return null
            return state[constants.reduxStore].recs[id]
        })
        page++
    }

    return pages
}

const searchRecs = ({ state }) => {

    if (!state[constants.reduxStore].searchList) return []

    const ids = state[constants.reduxStore].searchList

    return ids.map(id => {
        if (!state[constants.reduxStore].recs[id]) return null
        return state[constants.reduxStore].recs[id]
    })
}

model.registerSelectors(constants.model, {
    recById,
    recsByConnectionPage,
    recPages,
    searchRecs,
})