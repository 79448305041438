import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as MediaIcon } from '../../icon/media.svg'
import { ReactComponent as LoveIcon } from '../../icon/heart-empty.svg'
import { ReactComponent as LovedIcon } from '../../icon/heart-solid.svg'

import DotMenu from '../Elements/DotMenu'
import FrameTitle from '../Frame/FrameTitle'
import MediaPageContent from './MediaPageContent'
import MarkdownText from '../Elements/MarkdownText'

import model from '../../model'

export default function MemberPageDisplay({ id, pathSuffix, rec }) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const isAdmin = useSelector(state => model.selector('member.isAdmin', { state }))
    const memberAuthed = useSelector(state => model.selector('member.authedMember', { state }))
    const isOwner = model.helper('media.isOwner', { memberAuthed, rec })

    const subject = model.helper('media.getSubjectFromId', {id})
    const isLoved = model.helper('subject.isLoved', { memberAuthed, rec, subject })

    const navigate = useNavigate()

    if (!rec) {
        return <div />
    }

    /*
    const imageStyle = {
        backgroundImage: `url(${model.helper('member.getImageUrl', { member: rec })})`
    }
    */

    let actions = []
    if (isOwner || isAdmin) actions.push({
        label: 'edit',
        onClick: () => {
            navigate(`${pathSuffix}/edit`)
        },
    })

    if (isOwner || isAdmin) actions.push({
        label: 'edit connections',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'connectionsEdit',
                connections: rec && rec.connections ? rec.connections : {},
                subject,
            })
        }
    });
    if (isOwner || isAdmin) actions.push({
        label: 'remove',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'subjectRemove',
                subject,
            })
        }
    });

    const handleShowLove = async (e) => {
        e.stopPropagation()

        if (!memberAuthed){
            // ask user to login or sign up
            await model.action('app.setModal', {
                modal: 'access',
                handleComplete: async ()=>{
                    // show love
                    
                }
            })
            return
        }

        await model.action(`${subject.model}.connectionAdd`, {
            id: subject.id,
            relation: 'loves',
            subject: {
                model: 'member',
                id: memberAuthed._id
            }
        })
    }

    const handleUnLove = async (e) => {
        e.stopPropagation()

        await model.action(`${subject.model}.connectionRemove`, {
            id: subject.id,
            relation: 'loves',
            subject: {
                model: 'member',
                id: memberAuthed._id
            }
        })
    }

    const renderLove = () => {
        if (isLoved) {
            return (
                <div className="cursor-pointer group rounded-full w-8 h-8 flex flex-row items-center justify-center hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-rose-500 focus:outline-2 focus:outline-offset-2 focus:outline-rose-500 transition relative" onClick={handleUnLove}>
                    <LovedIcon className="w-4 h-4 text-rose-700 group-hover:text-rose-600 transition" />
                    <div className="hidden group-hover:flex absolute h-8 px-2 items-center -left-2 bg-slate-950 rounded top-9">
                        <div className="text-sm font-normal text-white whitespace-nowrap">
                            Loved (Unlove)
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="cursor-pointer group rounded-full w-8 h-8 flex flex-row items-center justify-center hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-rose-500 focus:outline-2 focus:outline-offset-2 focus:outline-rose-500 transition relative" onClick={handleShowLove}>
                <LoveIcon className="w-4 h-4 text-rose-700 group-hover:text-rose-600 transition" />
                <div className="hidden group-hover:flex absolute h-8 px-2 items-center -left-2 bg-slate-950 rounded top-9">
                    <div className="text-sm font-normal text-white whitespace-nowrap">
                        Show love
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <MediaIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    media
                </div>
                {renderLove()}
                <div className="">
                    <DotMenu direction="right" options={actions} theme="light" />
                </div>
            </FrameTitle>
            <div className="w-full bg-slate-950">
                <MediaPageContent rec={rec} subject={subject}/>
                {rec.caption && (
                        <div className="flex flex-col lg:flex-row items-center px-6 pb-6">
                            <div className="text-base xl:text-lg font-regular text-left text-slate-800">
                                <MarkdownText className="text-white">
                                    {rec.caption}
                                </MarkdownText>
                            </div>
                        </div>
                )}
                
            </div>


        </React.Fragment>
    )
}