const model = 'reservation'
const collection = 'reservation'

const fields = {
	_id:{
		type: 'obj',
	},
	holders:{
		type: 'ary',
		item: {
			type: 'obj',
			shape: {
				birthDate: {
					type: 'date',
				},
				email: {
					type: 'str',
				},
				firstName: {
					type: 'str',
				},
				lastName: {
					type: 'str',
				},
				memberId: {
					type: 'obj',
				},
				pass: {
					type: 'str',
				},
				phone:{
					type: 'str',
				},
				airportShuttle:{
					type: 'obj',
					shape: {
						arrival: {
							type: 'bool',
						},
						departure: {
							type: 'bool',
						} 
					},
				},
			},
		},
	},
	parent: {
		type: 'obj',
		shape: {
			model: {
				type: 'str',
			},
			id: {
				type: 'obj',
			},
		},
	},
	paymentIntentId: {
		type: 'str',
	},
	history: {
		type: 'ary',
		item: {
			type: 'obj',
			shape: {
				action: {
					type: 'str',
				},
				payload: {
					type: 'obj',
				},
				when: {
					type: 'date',
				},
				who: {
					type: 'id'
				},
			},
		},
	},
}

const exp = {
	collection,
	fields,
	model,
}

export default exp