import constants from './constants'
import model from '../index'
import store from '../../store'

import utilApi from '../../utility/api'

const connectionAdd = async ({ subject, connection }) => {
    return await model.action(`${subject.model}.connectionAdd`, { id: subject.id, relation: connection.relation, subject: connection.subject })
}

const connectionRemove = async ({ subject, connection }) => {
    return await model.action(`${subject.model}.connectionRemove`, { id: subject.id, relation: connection.relation, subject: connection.subject })
}

const getBySubject = async ({ subject }) => {
    const recs = await model.action('subject.getBySubject', { subject })

    // store list on this model
    store.dispatch({
        type: `${constants.reducerPrefix}_LIST_BY_SUBJECT_SET`,
        payload: {
            recs,
            subject,
        }
    })

    await storeSubjectRecs({ subjectRecs: recs })
}

const getRec = async ({ subject }) => {
    const res = await model.action(`${subject.model}.getById`, { id: subject.id })

    return res
}

const getRecWithAssociations = async ({ subject }) => {
    const authToken = await model.action('member.getAuthToken')

    const data = {
        action: `${constants.model}.getRecWithAssociations`,
        authToken,
        subject
    }

    const res = await utilApi.req({
        data,
        endpoint: 'action',
        method: 'post',
    })

    if (res.rec) {
        await model.action(`${subject.model}.storeRec`, { rec: res.rec })
    }
    if (res.associations) {
        await storeSubjectRecs({ subjectRecs: res.associations })
    }
}

const getSubjectRecsForConnections = async ({ connections }) => {

    const data = {
        action: 'subject.getSubjectRecsForConnections',
        connections,
    }

    const subjectRecs = await utilApi.req({
        data,
        endpoint: 'action',
        method: 'post',
    })

    await storeSubjectRecs({ subjectRecs })

    return subjectRecs
}

const getSubjectsRecs = async ({ subjects }) => {

    const data = {
        action: 'subject.getSubjectsRecs',
        subjects,
    }

    const subjectRecs = await utilApi.req({
        data,
        endpoint: 'action',
        method: 'post',
    })

    await storeSubjectRecs({ subjectRecs })

    return subjectRecs
}

const remove = async (data) => {
    const { subject } = data

    return await model.action(`${subject.model}.remove`, { id: subject.id, ...data })
}

const search = async ({ term, subjectModel }) => {

    return await model.action(`${subjectModel}.search`, {
        term
    })
}

const storeSubjectRecs = async ({ subjectRecs }) => {
    for (let i = 0, len = subjectRecs.length; i < len; i++) {
        const subject = subjectRecs[i]

        if (!subject.rec) continue

        const rec = subject.rec

        await model.action(`${subject.model}.storeRec`, { rec })

        if (rec.displayMediaRec){
            await model.action('media.storeRec', { rec: rec.displayMediaRec })
        }

        if (rec.connections) {
            for (let i in rec.connections) {
                await storeSubjectRecs({ subjectRecs: rec.connections[i] })
            }
        }
    }
}

const update = async (update) => {
    const { subject } = update

    return await model.action(`${subject.model}.update`, { id: subject.id, ...update })
}

model.registerActions(constants.model, {
    connectionAdd,
    connectionRemove,
    getBySubject,
    getSubjectRecsForConnections,
    getSubjectsRecs,
    getRec,
    getRecWithAssociations,
    remove,
    search,
    storeSubjectRecs,
    update,
})