import constants from './constants'
import model from '../index'
import store from '../../store'

import utilApi from '../../utility/api'
import utilObj from '../../utility/obj'

const add = async ({branches, family, title})=>{
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.add: no authToken found`)

	const data = {
		action: 'event.add',
		authToken, 
		branches, 
		family, 
		title,
	}

	const {rec} = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec,
		}
	})

	return rec
}

const getAll = async ({refresh})=>{

	const state = store.getState()

	if (!refresh && !utilObj.isEmpty(state.method.recs)) return

	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: 'event.getAll',
		authToken,
	}

	const {methods} = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_ALL_SET`,
		payload: {
			methods,
		}
	})
}

const getById = async ({id})=>{
	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: 'event.getById',
		authToken,
		id,
	}

	const {rec} = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type:'EVENT_REC_SET',
		payload: {
			rec,
		}
	})

	return rec
}

const getPublished = async ()=>{

	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: 'event.getPublished',
		authToken,
	}

	const {recs} = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: 'EVENT_PUBLISHED_SET',
		payload: {
			recs,
		}
	})
}

const publish = async ({id})=>{
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.update: no authToken found`)

	const data = {
		action: 'event.publish',
		authToken,
		id,
	}

	const {rec} = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: 'EVENT_REC_SET',
		payload: {
			rec,
		}
	})

	return rec
}

const remove = async ({id})=>{
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.add: no authToken found`)

	const data = {
		action: 'event.remove',
		id,
	}

	await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: 'EVENT_REMOVE',
		payload: {
			id,
		}
	})

	return id
}

const storeRec = async ({ rec }) => {
	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec,
		}
	})
}

const update = async (props)=>{
	const {id} = props

	if (!id) throw new Error(`${constants.model}.update: no id found`)

	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.update: no authToken found`)

	const data = {
		action: 'event.update',
		authToken,
		id,
		...props,
	}

	const {rec} = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: 'EVENT_REC_SET',
		payload: {
			rec,
		}
	})

	return rec
}

model.registerActions(constants.model, {
	add,
	getAll,
	getById,
	getPublished,
	publish,
	remove,
	storeRec,
	update,
})