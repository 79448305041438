import React, { useEffect } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as MemberIcon } from '../../icon/member.svg'

import Container from '../Elements/Container'
import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'
import MemberPageDisplay from './MemberPageDisplay'
import MemberPageEdit from './MemberPageEdit'
import SubjectGrixis from '../SubjectGrixis'

import model from '../../model'

export default function MemberPage() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let { id } = useParams()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [id])

    const memberAuthed = useSelector(state => model.selector('member.authedMember', { state }))
    const memberById = useSelector(state => model.selector('member.recById', { state, id }))

    let member
    if (!id){
        member = memberAuthed
        id = memberAuthed._id
    } else {
        member = memberById
    }

    const pathSuffix = `/member/${id}`

    const subject = model.helper('member.getSubjectFromId', {id})

    useEffect(() => {
        if (!id) return
        (async () => {
            try {
                await model.action('app.setLoading', true)
                await model.action('member.getById', { id })
                await model.action('app.setLoading', false)
            } catch (e) {
                await model.action('app.setLoading', false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, [id])

    if (!member) {
        return (
            <Frame>
                <FrameTitle>
                    <div className="flex flex-row items-center">
                        <MemberIcon className="w-5 h-5 text-lime-400" />
                    </div>
                    <div className="text-white font-bold text-lg">
                        member
                    </div>
                </FrameTitle>
                <div className="w-full bg-white">
                    <Container className="flex flex-col gap-6 py-6 md:py-10 lg:py-12 xl:py-14">
                        <div className="animate-pulse h-6 bg-slate-300 rounded-full dark:bg-slate-600 w-[220px]" />
                        <div className="flex flex-col gap-2">
                            <div className="animate-pulse h-3 bg-slate-300 rounded-full dark:bg-slate-600 w-[100%]" />
                            <div className="animate-pulse h-3 bg-slate-300 rounded-full dark:bg-slate-600 w-[60%]" />
                        </div>
                    </Container>
                </div>
                <SubjectGrixis key={id} subject={subject} pathSuffix={pathSuffix} />
            </Frame>
        )
    }

    return (
        <Frame>
            <Routes>
                <Route path="edit" element={<MemberPageEdit id={id} pathSuffix={pathSuffix} rec={member} />} />
                <Route path="*" element={<MemberPageDisplay id={id} pathSuffix={pathSuffix} rec={member} />} />
            </Routes>
            <SubjectGrixis key={id} subject={subject} pathSuffix={pathSuffix} />
        </Frame>
    )
}