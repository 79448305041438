import constants from './constants'
import model from '../index'
import store from '../../store'

import utilApi from '../../utility/api'
import utilObj from '../../utility/obj'

const add = async ({ description, title, when }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.add: no authToken found`)

	const data = {
		action: `${constants.model}.add`,
		authToken,
		description,
		title,
		when,
	}

	const added = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec: added,
		}
	})

	return added
}

const connectionAdd = async ({ id, relation, subject }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.update: no authToken found`)

	const data = {
		action: `${constants.model}.connectionAdd`,
		authToken,
		id,
		relation,
		subject,
	}

	const updated = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec: updated,
		}
	})
}

const connectionRemove = async ({ id, relation, subject }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.update: no authToken found`)

	const data = {
		action: `${constants.model}.connectionRemove`,
		authToken,
		id,
		relation,
		subject,
	}

	const updated = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec: updated,
		}
	})
}

const getAll = async ({ refresh }) => {

	const state = store.getState()

	if (!refresh && !utilObj.isEmpty(state.chapter.recs)) return

	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: `${constants.model}.getAll`,
		authToken,
	}

	const { recs } = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_ALL_SET`,
		payload: {
			recs,
		}
	})
}


const getById = async ({id})=>{
	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: `${constants.model}.getById`,
		authToken,

		id,
	}

	const rec = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type:`${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec,
		}
	})

	return rec
}

const getRecsForPage = async ({ page = 1 }) => {
	const authToken = await model.action('member.getAuthToken')

	const data = {
        action: `${constants.model}.getRecsForPage`,
        authToken,
        page,
    }

	const recs = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	await model.action('media.storeDisplayMediaForRecs', {recs})

	store.dispatch({
		type: `${constants.reducerPrefix}_SET_RECS_BY_PAGE`,
		payload: {
			recs,
			page,
		}
	})
}

const remove = async ({ id }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.add: no authToken found`)

	const data = {
		action: `${constants.model}.remove`,
		authToken,
		id,
	}

	const res = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REMOVE`,
		payload: {
			id,
		}
	})

	return res
}

const search = async ({ term }) => {
	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: `${constants.model}.search`,
		data: {
			term
		},
		authToken,
	}

	const recs = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_SEARCH_RECS_SET`,
		payload: {
			recs,
		}
	})
}

const storeRec = async ({ rec }) => {
	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec,
		}
	})
}

const update = async ({ id, description, displayMediaId, title }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.update: no authToken found`)

	const data = {
		action: `${constants.model}.update`,
		authToken,

		id,
		description,
        displayMediaId,
		title,
	}

	const updated = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	await storeRec({ rec: updated })
}

model.registerActions(constants.model, {
	add,
	connectionAdd,
	connectionRemove,
	getAll,
	getById,
	getRecsForPage,
	remove,
	search,
	storeRec,
	update,
})