const sec = 1000,
	min = 1000*60,
	hour = 1000*60*60,
	day = 1000*60*60*24,
	week = 1000*60*60*24*7,
	month = 1000*60*60*24*30,
	year = 1000*60*60*24*365

const monthStrs = [
	'january',
	'february',
	'march',
	'april',
	'may',
	'june',
	'july',
	'august',
	'september',
	'october',
	'november',
	'december'
]


const getAgo = (timestamp)=>{
	if (!timestamp) return 'never'
	var diff = new Date().getTime() - new Date(timestamp).getTime()
	if (diff < 2*sec) return '1 sec ago'
	if (diff < min) return Math.round(diff/sec)+' secs ago'
	if (diff < hour) return Math.round(diff/min)+' min ago'
	if (diff < 2*hour) return '1 hr ago'
	if (diff < day) return Math.round(diff/hour)+' hrs ago'
	if (diff < 2*day) return 'yesterday'
	if (diff < week) return Math.round(diff/day)+' days ago'
	if (diff < 2*week) return 'a week ago'
	if (diff < month) return Math.round(diff/week)+' weeks ago'
	if (diff < 2*month) return 'a month ago'
	if (diff < year) return Math.round(diff/month)+' months ago'
	if (diff < 2*year) return 'a year ago'
	return Math.round(diff/year)+' years ago'
}

const getPeriod = (period)=>{
	if (!period) return 'never'
	if (period < 2*sec) return 'in 1 sec'
	if (period < min) return 'in '+Math.round(period/sec)+' secs'
	if (period < hour) return 'in '+Math.round(period/min)+' min'
	if (period < 2*hour) return 'in 1 hr'
	if (period < day) return 'in '+Math.round(period/hour)+' hrs'
	if (period < 2*day) return 'tomorrow'
	if (period < week) return 'in '+Math.round(period/day)+' days'
	if (period < 2*week) return 'in a week'
	if (period < month) return 'in '+Math.round(period/week)+' weeks'
	if (period < 2*month) return 'in a month'
	if (period < year) return 'in '+Math.round(period/month)+' months'
	if (period < 2*year) return 'in a year'
	return 'in '+Math.round(period/year)+' years'
}


const monthsShort = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

const formatDateReadable = (d)=>{
	const D = new Date(d)
	return `${getMonthFromInd(D.getMonth())} ${D.getDate()} ${D.getFullYear()}`
}

const formatMDY = (d)=>{
	if (!d) return ''
	const D = new Date(d)
	return `${D.getMonth()+1}/${D.getDate()}/${D.getFullYear()}`
}

const formatTimeAmpm = (d)=>{
	if (!d) return ''
	const D = new Date(d)
	let hrs = D.getHours()
	let mins = D.getMinutes()
	const ampm = hrs >= 12 ? 'PM' : 'AM'
	hrs = hrs % 12
	hrs = hrs ? hrs : 12
	mins = mins < 10 ? '0'+mins : mins

	return `${hrs}:${mins} ${ampm}`
}

const formatReadableDateTime = (d)=>{
	return `${formatDateReadable(d)} ${formatTimeAmpm(d)}`
}

const getMonthFromInd = (monthInd)=>{
	return monthStrs[Number(monthInd)]
}
const getMonthShortFromInd = (monthInd)=>{
	return monthsShort[monthInd]
}

const exp = {
	sec,
	min,
	hour,
	day,
	week,
	month,
	year,
	monthsShort,
	monthStrs,
	formatReadableDateTime,
	formatMDY,
	getAgo,
	getMonthFromInd,
	getMonthShortFromInd,
	getPeriod,
}

export default exp