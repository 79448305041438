import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ReactComponent as LocationIcon } from '../../icon/location.svg'
import { ReactComponent as LoveIcon } from '../../icon/heart-empty.svg'
import { ReactComponent as LovedIcon } from '../../icon/heart-solid.svg'

import Container from '../Elements/Container'
import DotMenu from '../Elements/DotMenu'
import FrameTitle from '../Frame/FrameTitle'
import MarkdownText from '../Elements/MarkdownText'

import model from '../../model'

export default function LocationPageDisplay({ id, pathSuffix, rec }) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()

    const isAdmin = useSelector(state => model.selector('member.isAdmin', { state }))

    const subject = {
        model: 'location',
        id,
    }

    const memberAuthed = useSelector(state => model.selector('member.authedMember', { state }))
    const isLoved = model.helper('subject.isLoved', { memberAuthed, rec, subject })

    let actions = []

    const isOwner = model.helper('location.isOwner', {rec})

    if (isOwner || isAdmin){
        actions.push({
            label: 'edit',
            onClick: () => {
                navigate(`/location/${id}/edit`)
            }
        })
    }

    if (isOwner || isAdmin) actions.push({
        label: 'edit connections',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'connectionsEdit',
                connections: rec && rec.connections ? rec.connections : {},
                subject,
            })
        }
    });

    if (isOwner || isAdmin) actions.push({
        label: 'display media',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'displayMediaSelect',
                subject
            })
        }
    });

    if (isOwner || isAdmin) actions.push({
        label: 'remove',
        theme: 'danger',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'subjectRemove',
                subject
            })
        }
    });

    const handleShowLove = async (e) => {
        e.stopPropagation()

        if (!memberAuthed){
            // ask user to login or sign up
            await model.action('app.setModal', {
                modal: 'access',
                handleComplete: async ()=>{
                    // show love
                    
                }
            })
            return
        }

        await model.action(`${subject.model}.connectionAdd`, {
            id: subject.id,
            relation: 'loves',
            subject: {
                model: 'member',
                id: memberAuthed._id
            }
        })
    }

    const handleUnLove = async (e) => {
        e.stopPropagation()

        await model.action(`${subject.model}.connectionRemove`, {
            id: subject.id,
            relation: 'loves',
            subject: {
                model: 'member',
                id: memberAuthed._id
            }
        })
    }

    const renderLove = () => {
        if (isLoved) {
            return (
                <div className="cursor-pointer group rounded-full w-8 h-8 flex flex-row items-center justify-center hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-rose-500 focus:outline-2 focus:outline-offset-2 focus:outline-rose-500 transition relative" onClick={handleUnLove}>
                    <LovedIcon className="w-4 h-4 text-rose-700 group-hover:text-rose-600 transition" />
                    <div className="hidden group-hover:flex absolute h-8 px-2 items-center -left-2 bg-slate-950 rounded top-9">
                        <div className="text-sm font-normal text-white whitespace-nowrap">
                            Loved (Unlove)
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="cursor-pointer group rounded-full w-8 h-8 flex flex-row items-center justify-center hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-rose-500 focus:outline-2 focus:outline-offset-2 focus:outline-rose-500 transition relative" onClick={handleShowLove}>
                <LoveIcon className="w-4 h-4 text-rose-700 group-hover:text-rose-600 transition" />
                <div className="hidden group-hover:flex absolute h-8 px-2 items-center -left-2 bg-slate-950 rounded top-9">
                    <div className="text-sm font-normal text-white whitespace-nowrap">
                        Show love
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <LocationIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    location
                </div>
                {renderLove()}
                <DotMenu direction="right" options={actions} theme="light" />
            </FrameTitle>

            <div className="w-full bg-white">
                <Container className="flex flex-col gap-4 py-6 md:py-10 lg:py-12 xl:py-14">
                    <h2 className="text-[2rem] md:text-[2.5rem] lg:text-[3rem] xl:text-[3.5rem] leading-tight font-bold text-slate-800 mb-0">{rec.title}</h2>
                    <MarkdownText className="font-regular text-base md:text-lg lg:text-xl leading-normal text-slate-800">{rec.description}</MarkdownText>
                </Container>
            </div>
        </React.Fragment>
    )
}