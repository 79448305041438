import React from 'react'

import Grix from '../Grixis/Grix'
import { ReactComponent as CloseIcon } from '../../icon/close.svg'

export default function MediaGrixUpload({ handleRemoveMedia, ind, media }) {

    const { blobURL, name, type, url } = media

    if (type === 'video') {
        return (
            <Grix size={[1, 1]}>
                <video width="100%" height="100%" src={blobURL} controls autoplay>
                    Your browser does not support the video tag.
                </video>
            </Grix>
        )
    }
    if (type === 'image') {
        return (
            <Grix size={[1, 1]}>
                <div className="absolute group top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-center bg-no-repeat bg-cover"
                    key={name || url}
                    style={{ backgroundImage: `url(${url})` }} >

                    {!url && name}
                    <div className="bg-white hidden group-hover:flex flex-row items-center justify-center w-8 h-8 rounded-full shadow-sm absolute -top-3 -right-3
                        border border-slate-300 opacity-70 hover:opacity-100 cursor-pointer hover:scale-110 hover:shadow-lg transition ease-in-out hover:border-slate-800"
                        onClick={()=>handleRemoveMedia({ind})}>
                        <CloseIcon className="w-6 h-6 text-slate-800"/>
                    </div>
                </div>
            </Grix>
        )
    }

    return (
        <Grix size={[1, 1]}>
            <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-rose-200">
                Type not found
            </div>
        </Grix>
    )
}