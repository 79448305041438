import React from 'react'

export default function Block({ children, className, size = 'standard', style = {} }) {

    let baseClassName = 'items-stretch px-6 pt-16 pb-16 lg:px-10 lgpy-10 w-full mx-auto md:max-w-[92vw] lg:max-w-[77vw] xl:max-w-[69vw] shrink-0'

    return (
        <div className={`${baseClassName} ${className}`} style={style}>
            {children}
        </div>
    )
}