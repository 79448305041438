import React, {useState} from 'react'

import model from '../../model'

import FormField from '../Elements/FormField'
import Button from '../Elements/Button'
import Input from '../Elements/Input'

import ModalTitle from './ModalTitle'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'

const ModalAccess = ({handleClose, handleComplete})=>{

	const [email, setEmail] = useState('')

	const handleSubmit = async (e)=>{
		e.preventDefault()

		try {
			await model.action('app.setLoading', true)
			await model.action('member.login', {
				email,
			})
			await model.action('app.setLoading', false)

            handleClose()
            if (handleComplete) await handleComplete()

            await model.action('app.setAlert', {
				message: 'Access email sent',
				theme: 'success',
			})
		} catch(e){
			await model.action('app.setLoading', false)
			await model.action('app.setAlert', {
                message: model.helper('app.getErrorMessage', e),
                theme: 'danger'
            })
		}
	}

	return (
		<form onSubmit={handleSubmit}>
            <ModalTitle handleClose={handleClose}>
				<div className="flex flex-col items-center gap-2">
					<div className="text-xl text-white font-semibold uppercase leading-6">
						Login
					</div>
				</div>
			</ModalTitle>
            <ModalBody>
                <div className="flex flex-col gap-4" onSubmit={handleSubmit}>
                    <p className="text-base text-slate-700 font-normal">
                        Enter your email and we'll send you an access link
                    </p>
                    <FormField 
                        label="email"
                        name="email"
                        size="md">
                        
                        <Input 
                            autoFocus={true}
                            name="email"
                            placeholder="your@email.com"
                            value={email}
                            setValue={setEmail}
                            size="md"/>
                    </FormField>
                    
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex flex-row">
                    <Button className="ml-auto" size="md" type="submit">SEND EMAIL</Button>
                </div>
            </ModalFooter>
		</form>
	)
}

export default ModalAccess