import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import model from '../../model'

import DotMenu from '../Elements/DotMenu'

import Container from '../Elements/Container'
import Button from '../Elements/Button'
import ButtonIcon from '../Elements/ButtonIcon'
import FormField from '../Elements/FormField'
import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'
import Input from '../Elements/Input'
import Textarea from '../Elements/Textarea'

import { ReactComponent as ChapterIcon } from '../../icon/chapter.svg'
import { ReactComponent as CloseIcon } from '../../icon/close-icon.svg'

export default function ChapterAddPage() {

    const [rec, setRec] = useState({when: []})
    const navigate = useNavigate()

    const actions = [{
        label: 'clear',
        onClick: () => {

        }
    }]

    const handleClose = () => {
        navigate(`/story`)
    }

    const handlePropSet = (prop, val) => {
        setRec({
            ...rec,
            [prop]: val
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            await model.action('app.setLoading', true)
            const chapter = await model.action('chapter.add', {
                title: rec.title,
                description: rec.description,
            })

            await model.action('app.setLoading', false)

            navigate(`/chapter/${chapter._id}`)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setAlert', {
                message: model.helper('app.getErrorMessage', e),
                theme: 'danger'
            })
        }
    }

    const handlewhen = async ({ i, j, value }) => {
        let newWhen = [...rec.when]
        if (!newWhen[i]) newWhen[i] = []
        newWhen[i][j] = value || ''
        handlePropSet('when', newWhen)
    }

    return (
        <Frame>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <ChapterIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    chapters
                </div>
                <div className="pl-2.5 py-0 bg-lime-600 rounded flex flex-row gap-1 items-center">
                    <div className=" text-white text-base font-semibold">
                        add
                    </div>
                    <ButtonIcon className="" onClick={handleClose} theme="transparent">
                        <CloseIcon className="text-white/50 group-hover:text-white w-3 h-3" />
                    </ButtonIcon>
                </div>
                <div className="">
                    <DotMenu options={actions} direction="right" theme="light" />
                </div>
            </FrameTitle>
            <form className="w-full bg-white" onSubmit={handleSubmit}>
                <Container className="flex flex-col gap-6 py-6 md:py-10 lg:py-12 xl:py-14">
                <Container className="flex flex-col gap-6 py-6 md:py-10 lg:py-12 xl:py-14">
                    <FormField
                        label="title"
                        name="title"
                        size="md">
                        <Input className="text-2xl" placeholder="title" setValue={val => handlePropSet('title', val)} size="md" value={rec.title} />
                    </FormField>
                    <FormField
                        label="body"
                        name="body"
                        size="md">
                        <Textarea placeholder="body" setValue={val => handlePropSet('body', val)} size="md" value={rec.body} />
                    </FormField>
                    <FormField
                        label="when"
                        name="when"
                        size="md">
                        <div className="flex flex-row items-center gap-2">
                            <Input placeholder="MM" setValue={val => handlewhen({
                                    i: 0,
                                    j: 1,
                                    value: val
                                })} size="md" value={rec.when[0] && rec.when[0][1] ? rec.when[0][1] : ''} />
                            <div className="text-base ">/</div>
                            <Input placeholder="MM" setValue={val => handlewhen({
                                    i: 0,
                                    j: 0,
                                    value: val
                                })} size="md" value={rec.when[0] && rec.when[0][0] ? rec.when[0][0] : ''} />
                            <div className="text-base "> to </div>
                            <Input placeholder="MM" setValue={val => handlewhen({
                                    i: 1,
                                    j: 1,
                                    value: val
                                })} size="md" value={rec.when[1] && rec.when[1][1] ? rec.when[1][1] : ''} />
                            <div className="text-base ">/</div>
                            <Input placeholder="MM" setValue={val => handlewhen({
                                    i: 1,
                                    j: 0,
                                    value: val
                                })} size="md" value={rec.when[1] && rec.when[1][0] ? rec.when[1][0] : ''} />
                        </div>
                    </FormField>
                </Container>
                    <div className="flex flex-row justify-end">
                        <Button className="ml-auto" size="md" type="submit">add chapter</Button>
                    </div>
                </Container>
            </form>
        </Frame>
    )
}