import constants from './constants'
import model from '../index'
import format from '../../utility/format'

const formatFullName = ({firstName, lastName})=>{
	const first = firstName === null || firstName === undefined ? '' : format.capitalize(firstName)
	const last = lastName === null || lastName === undefined ? '' : format.capitalize(lastName)
	return `${first} ${last}`.trim()
}

const getImageUrl = ({member})=>{
	// https://res.cloudinary.com/everspring-collective/image/upload/v1663138338/member-images/gu3ft1uyfbrdvnsolw8z.jpg

	if (!member || !member.image) return ''
	return member.image.url
}

const getListIndex = ({role})=>{
	return `${role || 'all'}`
}

const getPathFromSubject = ({ subject }) => {
    return `/member/${subject.id}`
}


const getSubjectFromId = ({ id }) => {
    return {
        model: constants.model,
        id,
    }
}

const getSubjectFromRec = ({ rec }) => {
    if (!rec) return null
    return {
        model: constants.model,
        id: rec._id,
    }
}

const getTitle = ({rec})=>{
	if (!rec) return ''
	return formatFullName(rec)
}

const getAllRoles = ()=>{
	return constants.allRoles
}

model.registerHelpers(constants.model, {
	formatFullName,
	getAllRoles,
	getListIndex,
	getImageUrl,
	getPathFromSubject,
	getSubjectFromId,
	getSubjectFromRec,
	getTitle,
})