import React from 'react'

import { useSelector } from 'react-redux'
import model from '../../model'

import { ReactComponent as LocationIcon } from '../../icon/location.svg'
import { ReactComponent as LoveIcon } from '../../icon/heart-empty.svg'
import { ReactComponent as LovedIcon } from '../../icon/heart-solid.svg'

import Grix from '../Grixis/Grix'

export default function LocationGrix({ deselected, handleClick, handleEdit, selected, setView, subject, view }) {
    const rec = useSelector(state => model.selector('subject.rec', { state, subject })) || {}

    const displayMediaId = rec && rec.displayMediaId ? rec.displayMediaId : null
    const displayMedia = useSelector(state => model.selector('media.recById', { state, id: displayMediaId }))
    const displayMediaUrl = displayMedia && displayMedia.cloudinary && displayMedia.cloudinary.url ? displayMedia.cloudinary.url : ''

    const memberAuthed = useSelector(state => model.selector('member.authedMember', { state }))
    const isLoved = model.helper('subject.isLoved', { memberAuthed, rec, subject })
    const loveCount = model.helper('subject.getLoveCount', { memberAuthed, rec, subject })

    let size = [1, 1]

    const handleMainClick = ()=>{
        if (handleClick) handleClick({subject})
    }

    const handleShowLove = async (e) => {
        e.stopPropagation()

        if (!memberAuthed){
            // ask user to login or sign up
            await model.action('app.setModal', {
                modal: 'access',
                handleComplete: async ()=>{
                    // show love
                    
                }
            })
            return
        }

        await model.action(`${subject.model}.connectionAdd`, {
            id: subject.id,
            relation: 'loves',
            subject: {
                model: 'member',
                id: memberAuthed._id
            }
        })

    }

    const handleUnLove = async (e) => {
        e.stopPropagation()

        await model.action(`${subject.model}.connectionRemove`, {
            id: subject.id,
            relation: 'loves',
            subject: {
                model: 'member',
                id: memberAuthed._id
            }
        })

    }

    const renderLove = () => {
        if (isLoved) {
            return (
                <div className="group relative flex flex-row items-center justify-center gap-1.5 h-6 bg-slate-800 px-1.5 rounded" onClick={handleUnLove}>
                    <LovedIcon className="w-3.5 h-3.5 text-rose-700 group-hover:text-rose-600 transition" />
                    <div className="text-sm font-semibold text-white">
                        {loveCount}
                    </div>
                    <div className="hidden group-hover:flex absolute h-5 bg-slate-800 px-1.5 rounded items-center right-1 top-7">
                        <div className="text-xs font-normal text-white whitespace-nowrap">
                            Loved (Unlove)
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="group relative flex flex-row items-center justify-center gap-1.5 h-6 bg-slate-800 px-1.5 rounded" onClick={handleShowLove}>
                <LoveIcon className="w-3.5 h-3.5 text-rose-700 group-hover:text-rose-600 transition" />
                <div className="text-sm font-semibold text-white">
                    {loveCount}
                </div>
                <div className="hidden group-hover:flex absolute h-5 bg-slate-800 px-1.5 rounded items-center right-1 top-7">
                    <div className="text-xs font-normal text-white whitespace-nowrap">
                        Show love
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Grix size={size}>
            <div className={`group/grix absolute cursor-pointer top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-slate-800 ${deselected ? 'opacity-50 hover:opacity-80': 'opacity-80 hover:opacity-100'} transition`}
                onClick={handleMainClick}>

                <div className="absolute group top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center bg-center bg-no-repeat bg-cover"
                    style={{ backgroundImage: `url(${displayMediaUrl})` }} >
                </div>

                <div className="absolute top-1 left-1 bg-slate-900 gap-2.5 p-1.5 rounded flex flex-row items-center group w-6 h-6 group-hover/grix:w-[84px] transition-[width] overflow-hidden">
                    <LocationIcon className="w-3 h-3 text-lime-400 shrink-0" />
                    <div className="text-xs text-white font-semibold">
                        location
                    </div>
                </div>
  
                <div className="bg-slate-900/90 flex flex-col items-center justify-center h-8 absolute bottom-0 left-0 right-0">
                    <div className="text-sm font-semibold text-white">
                        {rec.title}
                    </div>
                </div>

                <div className="absolute top-1 right-1">
                    {renderLove()}
                </div>
            </div>
        </Grix>
    )
}