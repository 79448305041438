import model from '../index'

const state = {
	subjectLists: {},
}

export const reducer = (st = state, action) => {
	switch (action.type) {
		case 'SUBJECT_LIST_BY_SUBJECT_SET': {
			const { recs, subject } = action.payload

			const index = model.helper('subject.getIndex', {subject})

			const list = recs.map(rec => {
                return {
                    model: rec.model,
                    id: rec.id,
                }
            })

			const subjectLists = {
				...st.subjectLists,
				[index]: list
			}

			return { ...st, subjectLists }
		}
		default:
			return st
	}
}