import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { Route, Routes, useParams } from 'react-router-dom'

import { ReactComponent as ChapterIcon } from '../../icon/chapter.svg'

import Button from '../Elements/Button'
import ChapterPageDisplay from './ChapterPageDisplay'
import ChapterPageEdit from './ChapterPageEdit'
import Container from '../Elements/Container'
import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'
import SubjectGrixis from '../SubjectGrixis'

import model from '../../model'

export default function ChapterPage({ className }) {

    const { id } = useParams()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [id])

    const rec = useSelector(state => model.selector('chapter.recById', { state, id }))

    const handleGetUpdates = async () => {
        await model.action('app.setModal', {
            modal: 'getUpdates'
        })
    }

    const subject = {
        model: 'chapter',
        id,
    }

    const pathSuffix = `/chapter/${id}`

    /*
    useEffect(() => {
        const onScroll = () => {
            heroBgRef.current.style.backgroundPosition = `center ${window.pageYOffset / 2}px`
        }
        window.removeEventListener('scroll', onScroll)
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [])
    */

    useEffect(() => {
        (async () => {
            try {
                await model.action('app.setLoading', true)
                await model.action('chapter.getById', { id: subject.id })
                await model.action('app.setLoading', false)
                /*
                setTimeout(() => {
                    scrollToChapter()
                }, 300)
                */
            } catch (e) {
                await model.action('app.setLoading', false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, [id, subject.id])

    if (!rec) {
        return (
            <Frame>
                <FrameTitle>
                    <div className="flex flex-row items-center">
                        <ChapterIcon className="w-5 h-5 text-lime-400" />
                    </div>
                    <div className="text-white font-bold text-lg">
                        chapter
                    </div>
                </FrameTitle>
                <div className="w-full bg-white">
                    <Container className="flex flex-col gap-6 py-6 md:py-10 lg:py-12 xl:py-14">
                        <div className="animate-pulse h-6 bg-slate-300 rounded-full dark:bg-slate-600 w-[220px]" />
                        <div className="flex flex-col gap-2">
                            <div className="animate-pulse h-3 bg-slate-300 rounded-full dark:bg-slate-600 w-[100%]" />
                            <div className="animate-pulse h-3 bg-slate-300 rounded-full dark:bg-slate-600 w-[60%]" />
                        </div>
                    </Container>
                </div>
                <SubjectGrixis key={id} subject={subject} pageSubject={subject} pathSuffix={pathSuffix} />
            </Frame>
        )
    }

    return (
        <Frame>
            <Routes>
                <Route path="edit" element={<ChapterPageEdit id={id} pathSuffix={pathSuffix} rec={rec} />} />
                <Route path="*" element={<ChapterPageDisplay id={id} pathSuffix={pathSuffix} rec={rec} />} />
            </Routes>
            <SubjectGrixis key={id} subject={subject} pathSuffix={pathSuffix} />
            <div className="w-full bg-slate-800">
                    <div className="flex flex-col items-stretch gap-7 px-5 pt-16 pb-16 lg:px-10 lgpy-10 w-full mx-auto md:max-w-[92vw] lg:max-w-[77vw] xl:max-w-[69vw] shrink-0">
                        <div className="text-[2.1rem] xl:text-[3.3rem] font-bold text-white mb-0 leading-12">EXPERIENCE OUR JOURNEY</div>
                        <p className="font-regular text-lg xl:text-xl text-white">
                            Leave us your email to be the first to get progress updates, participate in discussion, and invited to experience the Landing with events and stays.
                        </p>
                        <div className="flex flex-row items-center">
                            <Button theme="primary" onClick={handleGetUpdates}>
                                GET UPDATES
                            </Button>
                        </div>
                    </div>
                </div>
        </Frame>
    )
}