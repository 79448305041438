import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import model from '../../model'

import AccessPage from '../AccessPage'
import ChapterAddPage from '../ChapterAddPage'
import ChapterPage from '../ChapterPage'
import HomePage from '../HomePage'
import LocationAddPage from '../LocationAddPage'
import LocationPage from '../LocationPage'
import LocationsPage from '../LocationsPage'
import LotusCounterPage from '../LotusCounterPage'
import MediaPage from '../MediaPage'
import MediasPage from '../MediasPage'
import MemberPage from '../MemberPage'
import MembersPage from '../MembersPage'
import MemberAddPage from '../MemberAddPage'
import Modal from '../Modal'
import PageAlert from './PageAlert'
import PageLoader from './PageLoader'
import PageNav from './PageNav'
import Panel from '../Panel'
import ProjectAddPage from '../ProjectAddPage'
import ProjectPage from '../ProjectPage'
import ProjectsPage from '../ProjectsPage'
import StoryPage from '../StoryPage'
import UploadPage from '../UploadPage'

export default function Page() {
    const [isStarted, setIsStarted] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                await model.action('app.setLoading', true)
                setIsStarted(true)
                await model.action('member.auth')
                await model.action('app.setLoading', false)
            } catch (e) {
                await model.action('app.setLoading', false)
                //await model.action('app.setError', e)
            }
        })()
    }, [])

    if (!isStarted){
        <div className="w-screen flex flex-col lg:flex-row items-stretch justify-stretch">
            <PageNav/>
        </div>
    }

    return (
        <BrowserRouter>
            <div className="w-screen flex flex-col lg:flex-row items-stretch justify-stretch">
                <PageNav/>
                <div className="bg-slate-900 w-full grow flex flex-col lg:flex-row justify-start relative">
                    <Routes>
                        <Route path="/access/:token" element={<AccessPage />} />
                        <Route path="/chapters/add" element={<ChapterAddPage />} />
                        <Route path="/chapter/:id/*" element={<ChapterPage />} />
                        <Route path="/locations/add" element={<LocationAddPage />} />
                        <Route path="/location/:id/*" element={<LocationPage />} />
                        <Route path="/locations/*" element={<LocationsPage />} />
                        <Route path="/lotus-counter/*" element={<LotusCounterPage />} />
                        <Route path="/media/:id/*" element={<MediaPage />} />
                        <Route path="/medias/*" element={<MediasPage />} />
                        <Route path="/member/:id/*" element={<MemberPage />} />
                        <Route path="/member" element={<MemberPage />} />
                        <Route path="/members/add" element={<MemberAddPage />} />
                        <Route path="/members/*" element={<MembersPage />} />
                        <Route path="/panel" element={<Panel />} />
                        <Route path="/projects/add" element={<ProjectAddPage />} />
                        <Route path="/project/:id/*" element={<ProjectPage />} />
                        <Route path="/projects/*" element={<ProjectsPage />} />
                        <Route path="/story/*" element={<StoryPage />} />
                        <Route path="/upload/*" element={<UploadPage />} />
                        <Route path="*" element={<HomePage />} />
                    </Routes>

                    <PageLoader/>
                    
                    <Modal />
                    <PageAlert />
                </div>
            </div>
        </BrowserRouter>
    )

    /*
        <PageMember />
                <Routes>
                    <Route path="/admin" element={<AdminPanel/>}/>
                    <Route path="/access/:token" element={<Access/>}/>
                    <Route path="upload/*" element={<Upload />} />
                    <Route path="*" element={<Home />} />
                </Routes>
                <Loader loading={loading} />
                <Modal/>
                <PageAlert handleClose={handleAlertClose} />
                <PageNav show={showNav} setShow={setShowNav}/>
    */
}