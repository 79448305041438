import React, {useState} from 'react'

import model from '../../model'

import Button from '../Elements/Button'

import ModalTitle from './ModalTitle'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'

import Selector from '../Elements/Selector'

export default function ModalConnectionAdd ({handleClose, handleComplete, subject}) {

    const [relationOption, setRelationOption] = useState(null)

    const options = model.helper('connection.getRelationOptionsForSubject', {subject})

	const handleSubmit = async (e)=>{
		e.preventDefault()

		try {
			await model.action('app.setLoading', true)

			await model.action('app.setLoading', false)

            handleClose()
            if (handleComplete) await handleComplete()

            await model.action('app.setAlert', {
				message: 'Access email sent',
				theme: 'success',
			})
		} catch(e){
			await model.action('app.setLoading', false)
			await model.action('app.setAlert', {
                message: model.helper('app.getErrorMessage', e),
                theme: 'danger'
            })
		}
	}

	return (
		<form onSubmit={handleSubmit}>
            <ModalTitle handleClose={handleClose}>
				<div className="flex flex-col items-center gap-2">
					<div className="text-xl text-white font-semibold uppercase leading-6">
						Add Connections
					</div>
				</div>
			</ModalTitle>
            <ModalBody>
                <div className="flex flex-col gap-4" onSubmit={handleSubmit}>
                    <Selector 
                        onChange={setRelationOption}
                        options={options}
                        selected={relationOption}
                    />
                    
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex flex-row">
                    <Button className="ml-auto" size="md" type="submit">Add</Button>
                </div>
            </ModalFooter>
		</form>
	)
}
