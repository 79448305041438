import constants from './constants'
import model from '../index'

const rec = ({ state, subject }) => {
    if (!subject) return null

    return model.selector(`${subject.model}.recById`, { state, id: subject.id })
}

const subjectRecsBySubject = ({ state, subject }) => {
    if (!subject || !state[constants.reduxStore]) return null

    const index = model.helper('subject.getIndex', { subject })
    if (!state[constants.reduxStore].subjectLists[index]) return null
    const list = state[constants.reduxStore].subjectLists[index]

    const subjects = list.map(sub => {
        return {
            ...sub,
            rec: rec({ state, subject })
        }
    })

    return subjects
}

const recsBySearchSubject = ({ state, subjectModel }) => {
    if (!subjectModel) return null

    return model.selector(`${subjectModel}.searchRecs`, { state })
}

model.registerSelectors(constants.model, {
    rec,
    recsBySearchSubject,
    subjectRecsBySubject,
})