import React from 'react'

export default function Grix({ bgColor, children, className = '', size = [1,1] }) {

    // pt-[33.33%] pt-[50%] pt-[66.67%] pt-[100%] pt-[200%] pt-[300%] row-span-2 row-span-1 col-span-1 col-span-2 

    let sizeClass = `h-0 pt-[${Math.round((size[1] / size[0]) * 10000)/100}%] row-span-${size[1]} col-span-${size[0]}`

    return (
        <div className={`${sizeClass} hover:shadow-lg hover:z-10 transition ease-in-out relative ${bgColor}`}>
            <div className={`absolute top-0 bottom-0 left-0 right-0 ${className}`}>
                {children}
            </div>
        </div>
    )
}