import React, { useCallback, useEffect, useRef } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { ReactComponent as StoryIcon } from '../../icon/story.svg'

import Button from '../Elements/Button'
import DotMenu from '../Elements/DotMenu'
import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'

import model from '../../model'
import Chapter from '../Chapter'

export default function StoryPage({ className }) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { chapter } = useParams()

    const navigate = useNavigate()

    const chapters = useSelector(state => model.selector('chapter.all', { state }))
    const isAdmin = useSelector(state => model.selector('member.isAdmin', { state }))

    const scrolledToRef = useRef(null)

    const scrollToChapter = useCallback(() => {
        if (chapter !== scrolledToRef.current) {
            scrolledToRef.current = chapter

            const lookupElem = () => {
                const chapterElem = document.getElementById(`chapter${chapter}`)
                if (chapterElem) chapterElem.scrollIntoView({ behavior: 'smooth' })
                else setTimeout(() => {
                    console.log('scroll 2')
                    lookupElem()
                }, 1000)
            }

            lookupElem()
        }
    }, [chapter, scrolledToRef])

    const handleGetUpdates = async () => {
        await model.action('app.setModal', {
            modal: 'getUpdates'
        })
    }

    /*
    useEffect(() => {
        const onScroll = () => {
            heroBgRef.current.style.backgroundPosition = `center ${window.pageYOffset / 2}px`
        }
        window.removeEventListener('scroll', onScroll)
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [])
    */

    useEffect(() => {
        (async () => {
            try {
                await model.action('app.setLoading', true)
                await model.action('chapter.getAll', {})
                await model.action('app.setLoading', false)
                /*
                setTimeout(() => {
                    scrollToChapter()
                }, 300)
                */
            } catch (e) {
                await model.action('app.setLoading', false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, [scrollToChapter])

    const actions = []

    if (isAdmin){
        actions.push({
            label: 'add chapter',
            onClick: () => {
                navigate('/chapters/add')
            }
        })
    }

    return (
        <Frame>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <StoryIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    story
                </div>
                <DotMenu direction="right" options={actions} theme="light" />
            </FrameTitle>

            <div className="flex flex-col items-stretch">
                <div className="w-full bg-slate-800">
                    <div className="flex flex-col items-stretch gap-7 px-5 pt-16 pb-16 lg:px-10 lgpy-10 w-full mx-auto md:max-w-[92vw] lg:max-w-[77vw] xl:max-w-[69vw] shrink-0">
                        <div className="text-[2.1rem] xl:text-[3.3rem] font-bold text-white mb-0 leading-12">EXPERIENCE OUR JOURNEY</div>
                        <p className="font-regular text-lg xl:text-xl text-white">
                            Leave us your email to be the first to get progress updates, participate in discussion, and invited to experience the Landing with events and stays.
                        </p>
                        <div className="flex flex-row items-center">
                            <Button theme="primary" onClick={handleGetUpdates}>
                                GET UPDATES
                            </Button>
                        </div>
                    </div>
                </div>

                {chapters && chapters.map((rec, i) => {
                    const num = i + 1

                    return (
                        <div className="relative bg-white" id={`chapter${num}`} key={num}>
                            <Chapter chapter={rec} key={num} num={num} />
                        </div>
                    )
                })}
            </div>

        </Frame>
    )
}