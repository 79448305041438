import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'

import {ReactComponent as CheckIcon} from '../../icon/check.svg'
import {ReactComponent as DropdownIcon} from '../../icon/drop.svg'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Selector({ className, containerClassName, dropClassName, label, onChange, options, selected, theme }) {

    let themeClassName
    switch (theme){
        case 'transparent': {
            themeClassName = 'bg-transparent ring-transparent'
            break
        }
        default: { // primary
            themeClassName = 'bg-white ring-gray-300 shadow-sm'
            
        }
    }

    return (
        <Listbox by="value" value={selected} onChange={onChange}>
            {({ open }) => (
                <>
                    <div className={`relative w-full ${containerClassName || ''}`}>
                        <Listbox.Button className={`relative w-full flex flex-row items-center cursor-pointer rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset focus:outline-none focus:outline focus:outline-2 focus:outline-offset-4 focus:outline-lime-400 ${themeClassName || ''} ${className || ''}`}>
                            <span className="block truncate text-gray-900 text-base">{selected?.label || 'Select one'}</span>
                            {selected?.count !== undefined && (
                                <span className="text-xs leading-4 font-semibold rounded-full bg-gray-100 px-2 py-1 ml-2">{selected.count}</span>
                            )}
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <DropdownIcon className="h-3 w-3 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className={`absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${dropClassName}`}>
                                {options.map((option) => {
                                    return (
                                        <Listbox.Option
                                            key={option.value}
                                            className={({ active }) =>
                                                classNames(
                                                    'flex flex-row item-center group/option',
                                                    active ? 'bg-lime-500 text-white' : option.disabled? 'text-gray-400': 'text-gray-900',
                                                    'relative select-none py-2 pl-3 pr-9 cursor-pointer',
                                                )
                                            }
                                            disabled={option.disabled}
                                            value={option}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                        {option.label}
                                                    </span>

                                                    {option.count !== undefined && (
                                                        <span className="text-gray-800 group-hover/option:bg-lime-600 group-hover/option:text-white text-xs leading-4 font-semibold rounded-full bg-gray-100 px-2 py-1 ml-3">{option.count}</span>
                                                    )}

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-lime-500',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    )
                                })}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}