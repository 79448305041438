import constants from './constants'
import model from '../index'
import config from '../../config'

import {loadStripe} from '@stripe/stripe-js'

const getStripe = ()=>{
	return loadStripe(config.stripe.publicKey)
}

model.registerHelpers(constants.model, {
	getStripe,
})