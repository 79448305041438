import constants from './constants'
import model from '../index'

const all = ({state})=>{
	if (!state[constants.reduxStore].recs) return []

	return Object.values(state[constants.reduxStore].recs)
}

const recById = ({state, id})=>{
	if (!state[constants.reduxStore].recs[id]) return null
	return state[constants.reduxStore].recs[id]
}

const recPages = ({ state }) => {
    if (!state[constants.reduxStore].pageIds) return {}

    const pageIds = state[constants.reduxStore].pageIds

    let page = 1, pages = {}

    while (pageIds[page]){
        pages[page] = pageIds[page].map(id => {
            if (!state[constants.reduxStore].recs[id]) return null
            return state[constants.reduxStore].recs[id]
        })
        page++
    }

    return pages
}

const searchRecs = ({state})=>{

	if (!state[constants.reduxStore].searchList) return []

	const ids = state[constants.reduxStore].searchList

	return ids.map(id => {
		if (!state[constants.reduxStore].recs[id]) return null
		return state[constants.reduxStore].recs[id]
	})
}

model.registerSelectors(constants.model, {
	all,
	recById,
	recPages,
	searchRecs,
})