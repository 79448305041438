import React from 'react'

export default function FrameTitle({ children, className = '' }) {

    return (
        <div className={`w-full h-16 mt-8 md:mt-24 sticky top-16 md:top-0 z-20 flex flex-row gap-4 px-6 justify-start items-center grow bg-slate-900 md:pr-[360px] ${className}`}>
            {children}
        </div>
    )
}
