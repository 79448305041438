import constants from './constants'
import model from '../index'

const alert = ({state})=>{
	return state[constants.reduxStore].alert
}
const loading = ({state})=>{
	return state[constants.reduxStore].loading
}
const modal = ({state})=>{
	return state[constants.reduxStore].modal
}
const nextBack = ({next, state})=>{
	return state[constants.reduxStore].nextBack[next]
}
const queryData = ({queryStr, state})=>{
	return state[constants.reduxStore].queryData[queryStr]
}

model.registerSelectors(constants.model, {
	alert,
	loading,
	modal,
	nextBack,
	queryData,
})