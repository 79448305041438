import React from 'react'

import model from '../../model'

import Button from '../Elements/Button'

import ModalTitle from './ModalTitle'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'

import SubjectGrix from '../SubjectGrix'

export default function ModalSubjectRemove({ handleClose, handleComplete, subject }) {

    const handleRemove = async (e) => {
        e.preventDefault()
        try {
            await model.action('app.setLoading', true)
            await model.action('subject.remove', {
                subject,
            })
            await model.action('app.setAlert', {
                message: 'subject removed',
                theme: 'success'
            })

            if (handleComplete) handleComplete()
            handleClose()

            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })
        }
    }

    return (
        <form onSubmit={handleRemove}>
            <ModalTitle handleClose={handleClose}>
                <div className="flex flex-col items-center gap-2">
                    <div className="text-xl text-white font-semibold uppercase leading-6">
                        Remove Subject
                    </div>
                </div>
            </ModalTitle>
            <ModalBody>
                <div className="col col-span-1">
                    <SubjectGrix subject={subject}/>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex flex-row">
                    <Button className="ml-auto" size="md" theme="danger" type="submit">Remove</Button>
                </div>
            </ModalFooter>
        </form>
    )
}
