const collection = 'project'

const fields = {
	_id: {
		type: 'obj',
	},
	connections: {
		type: 'obj',
		key: {
			type: 'str',
			label: 'relation'
		}, // uploader, featured (members in media), associated (chapters / events),
		value: {
			type: 'ary',
			item: {
				type: 'obj',
				shape: {
					model: {
						type: 'str'
					},
					id: {
						type: 'obj'
					}
				}
			},
		}
	},
	created: {
		type: 'obj',
		shape: {
			when: {
				type: 'date',
			},
			who: {
				type: 'obj',
			}
		}
	},
    description: {
		type: 'str',
		len: [1, 5000],
	},
	displayMediaId: {
		type: 'obj',
	},
    story: {
        type: 'ary',
        item: {
            type: 'obj',
            shape: {
                model: {
                    type: 'str'
                },
                id: {
                    type: 'obj'
                }
            }
        }
    },
	title: {
		type: 'str',
		len: [1, 500],
	},
	updated: {
		type: 'obj',
		shape: {
			when: {
				type: 'date',
			},
			who: {
				type: 'obj',
			}
		}
	},
}

const model = 'project'

const reducerPrefix = 'PROJECT'
const reduxStore = 'project'

const exp = {
	collection,
	fields,
	model,
	reducerPrefix,
	reduxStore,
}

export default exp