import constants from './constants'
import model from '../index'

const recById = ({state, id})=>{
	if (!state[constants.model].recs || !state[constants.model].recs[id]) return null
	return state[constants.model].recs[id]
}

model.registerSelectors(constants.model, {
	recById,
})