const collection = 'media'

const fields = {
	_id:{
		type: 'obj',
	},
	caption: {
		type: 'str',
		len: [1,500],
	},
	cloudinary:{
		type: 'obj',
		shape: {
			assetId: {
				type: 'str',
			},
			folder: {
				type: 'str',
			},
			format: {
				type: 'str',
			},
			height: {
				type: 'num',
			},
			extension: {
				type: 'str',
			},
			publicId: {
				type: 'str',
			},
			type: {
				type: 'str',
			},
			url: {
				type: 'str',
			},
			method: {
				type: 'str',
			},
			version: {
				type: 'str',
			},
			width: {
				type: 'num',
			},
		},
	},
	connections:{
		type: 'obj', 
		key: {
			type: 'str', 
			label: 'relation'
		}, // uploader, featured (members in media), associated (chapters / events),
		value:{
			type:'ary',
			item: {
				type:'obj',
				shape:{
					model: {
						type: 'str'
					},
					id: {
						type: 'obj'
					}
				}
			},
		}
	},
	created:{
		type: 'obj',
		shape: {
			when: {
				type: 'date',
			},
			who: {
				type: 'obj',
			}
		}
	},
	name:{
		type: 'str',
	},
	type:{
		type: 'str', // image or video
	},
	updated:{
		type: 'obj',
		shape: {
			when: {
				type: 'date',
			},
			who: {
				type: 'obj',
			}
		}
	},
}

const model = 'media'

const reducerPrefix = 'MEDIA'
const reduxStore = 'media'

const relationOptions = [{
    label: 'uploaded by',
    value: 'uploader',
    subjectModel: 'member'
}, {
    label: 'appearances by',
    value: 'appears',
    subjectModel: 'member'
}, {
    label: 'during event',
    value: 'event',
    subjectModel: 'event',
}, {
    label: 'on project',
    value: 'project',
    subjectModel: 'project',
}, {
    label: 'at location',
    value: 'location',
    subjectModel: 'location',
}]

const exp = {
	collection,
	fields,
	model,
	reducerPrefix,
	reduxStore,
	relationOptions,
}

export default exp