import constants from './constants'
import model from '../index'
import store from '../../store'

import utilApi from '../../utility/api'
import cloudinary from '../../utility/cloudinary'

const add = async ({ file, handleProgress, tags, type }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.add: no authToken found`)

	const add = {
		action: 'media.add',
		authToken,
		tags,
		type,
	}

	const added = await utilApi.req({
		data: add,
		endpoint: 'action',
		method: 'post',
	})

	// publicId - 
	const publicId = added._id

	const cloudinaryData = await cloudinary.upload({
		file,
		handleProgress,
		publicId,
		uploadPreset: 'media-upload',
		type,
	})

	const update = {
		action: 'media.update',
		authToken,

		id: added._id,
		cloudinary: cloudinaryData,
	}

	const updated = await utilApi.req({
		data: update,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec: updated,
		}
	})

	return updated
}

const connectionAdd = async ({ id, relation, subject }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.update: no authToken found`)

	const data = {
		action: 'media.connectionAdd',
		authToken,
		id,
		relation,
		subject,
	}

	const updated = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec: updated,
		}
	})
}

const connectionRemove = async ({ id, relation, subject }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.update: no authToken found`)

	const data = {
		action: 'media.connectionRemove',
		authToken,
		id,
		relation,
		subject,
	}

	const updated = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec: updated,
		}
	})
}

const getById = async ({ id }) => {
	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: 'media.getById',
		authToken,
		id,
	}

	const rec = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec,
		}
	})

	return rec
}

const getByConnectionSubject = async ({ page = 1, subject }) => {
	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: 'media.getByConnectionSubject',
		authToken,
		subject,
		page,
	}

	const subjectRecs = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_SUBJECTS_SET_BY_CONNECTION_PAGE`,
		payload: {
			connection: subject,
			subjects: subjectRecs,
			page,
		}
	})

	await model.action('subject.storeSubjectRecs', { subjectRecs })

	return subjectRecs
}

const getRecsForPage = async ({ page = 1 }) => {
	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: `${constants.model}.getRecsForPage`,
		authToken,
		page,
	}

	const recs = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_SET_RECS_BY_PAGE`,
		payload: {
			recs,
			page,
		}
	})
}

const remove = async ({ id }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.add: no authToken found`)

	const data = {
		action: 'media.remove',
		authToken,

		id,
	}

	// remove media from cloudinary


	const added = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REMOVE`,
		payload: {
			id,
		}
	})

	return added
}

const search = async ({ term }) => {
	const authToken = localStorage.get(constants.authTokenName)

	const data = {
		action: 'media.search',
		authToken,
		term,
	}

	const { recs } = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_SEARCH_RECS_SET`,
		payload: {
			recs,
		}
	})
}

const storeDisplayMediaForRec = async ({ rec }) => {
	if (rec.displayMediaRec) {
		await storeRec({ rec: rec.displayMediaRec })
	}
}

const storeDisplayMediaForRecs = async ({ recs }) => {
	Promise.all(recs.map(async rec => {
		await storeDisplayMediaForRec({ rec })
	}))
}

const storeRec = async ({ rec }) => {
	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec,
		}
	})
}

const update = async ({ id, caption, connections, name }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.update: no authToken found`)

	const data = {
		action: 'media.update',
		authToken,

		id,
		caption,
		connections,
		name,
	}

	const updated = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec: updated,
		}
	})
}

const migrateConnections = async () => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.add: no authToken found`)

	const data = {
		action: `${constants.model}.migrateConnections`,
		authToken,
	}

	await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})
}


model.registerActions(constants.model, {
	add,
	connectionAdd,
	connectionRemove,
	getById,
	getByConnectionSubject,
	getRecsForPage,
	remove,
	search,
	storeDisplayMediaForRec,
	storeDisplayMediaForRecs,
	storeRec,
	update,
	migrateConnections,
})