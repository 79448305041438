import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import model from '../../model'

import { ReactComponent as LocationIcon } from '../../icon/location.svg'
import { ReactComponent as CloseIcon } from '../../icon/close.svg'
import { ReactComponent as EditIcon } from '../../icon/edit-icon.svg'

import Button from '../Elements/Button'
import ButtonIcon from '../Elements/ButtonIcon'
import Container from '../Elements/Container'
import FormField from '../Elements/FormField'
import FrameTitle from '../Frame/FrameTitle'
import Input from '../Elements/Input'
import Textarea from '../Elements/Textarea'

export default function LocationPageEdit({ id, pathSuffix, rec }) {

    const [modified, setModified] = useState(rec)

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const subject = {
        model: 'location',
        id,
    }

    const handleClose = () => {
        navigate(`${pathSuffix}`)
    }

    const handleCancel = () => {
        setModified(rec)
    }

    const handlePropSet = (prop, val) => {
        setModified({
            ...modified,
            [prop]: val
        })
    }

    const handleSave = async (e) => {
        e.preventDefault()
        try {
            await model.action('app.setLoading', true)

            const update = {
                id,
                title: modified.title,
                description: modified.description,
            }

            await model.action('location.update', update)

            await model.action('app.setAlert', {
                message: 'location saved',
                theme: 'success'
            })

            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const isChanged = JSON.stringify(rec) !== JSON.stringify(modified)

    useEffect(() => {
        (async () => {
            try {
                await model.action('app.setLoading', true)
                await model.action('subject.getRecWithAssociations', { subject })
                await model.action('app.setLoading', false)
            } catch (e) {
                await model.action('app.setLoading', false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, [id]) // eslint-disable-line

    return (
        <React.Fragment>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <LocationIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    location
                </div>
                <div className="pl-2.5 py-0 bg-lime-600 rounded flex flex-row gap-1 items-center">
                    <div className=" text-white text-base font-semibold">
                        edit
                    </div>
                    <ButtonIcon className="" onClick={handleClose} theme="transparent">
                        <CloseIcon className="text-white/50 group-hover:text-white w-6 h-6" />
                    </ButtonIcon>
                </div>
            </FrameTitle>

            <div className="flex flex-col items-stretch bg-white">
                <Container className="flex flex-col gap-6 py-6 md:py-10 lg:py-12 xl:py-14">
                    <FormField
                        label="title"
                        name="title"
                        size="md">
                        <Input className="text-2xl" placeholder="title" setValue={val => handlePropSet('title', val)} size="md" value={modified.title} />
                    </FormField>
                    <FormField
                        label="description"
                        name="description"
                        size="md">
                        <Textarea placeholder="description" setValue={val => handlePropSet('description', val)} size="md" value={modified.description} />
                    </FormField>
                </Container>
                {isChanged && (
                    <div className={`w-full h-16 sticky bottom-0 z-20 flex flex-row gap-4 px-6 items-center grow bg-slate-950`}>
                        <EditIcon className="w-5 h-5 text-lime-400" />
                        <div className="text-white text-base font-semibold">save changes?</div>
                        <Button className="ml-auto" disabled={!isChanged} onClick={handleCancel} theme="">CANCEL</Button>
                        <Button className="" disabled={!isChanged} onClick={handleSave} theme="primary">SAVE</Button>
                    </div>
                )}
                
            </div>

        </React.Fragment>
    )
}