import React, { useEffect, useState } from 'react'
import {useSelector} from 'react-redux'

import model from '../../model'

import Input from '../Elements/Input'
import SubjectNugget from '../SubjectNugget'

export default function SubjectSelector({ handleSelect, subjectModel, subjects = [] }) {

    const [search, setSearch] = useState('')
    const recs = useSelector(state => model.selector('subject.recsBySearchSubject', {state, search, subjectModel}))

    const handleSearchChange = async (val) => {
        setSearch(val)

        //search api
        await model.action('subject.search', { term: val, subjectModel })
    }

    useEffect(() => {
        (async () => {
            await handleSearchChange('')
        })()
    }, []) // eslint-disable-line

    /*
        <div className="flex flex-col gap-1 items-stretch">
                {selected && selected.map(subject => {
                    return (
                        <SubjectSelectorSelectedRow handleDeselect={handleDeselect} key={subject.id} selected={true} setSelected={()=>{handleSetSelected({subject})}} subject={subject} />
                    )
                })}
            </div>

            <div className="flex flex-row items-center justify-end">
                <Button onClick={()=>handleAddSubjects({subjects: selected})}>SAVE</Button>
            </div>
    */

    return (
        <div className="flex flex-col gap-4 items-stretch">
            
            <div className="flex flex-row items-center">
                <Input placeholder={`search for ${subjectModel}`} value={search} setValue={handleSearchChange} />
            </div>

            <div className="flex flex-col gap-1 items-stretch">
                {recs && recs.map(rec => {
                    const found = subjects.find(subject => {
                        return subject.id === rec._id
                    })
                    const subject = {
                        id: rec._id,
                        model: subjectModel,
                    }
                    if (found) return <React.Fragment key={rec._id}/>
                    return (
                        <SubjectNugget 
                            key={rec._id} 
                            rec={rec} 
                            handleSelect={handleSelect} 
                            subject={subject} />
                    )
                })}
            </div>
        </div>
    )
}


