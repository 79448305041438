import constants from './constants'
import model from '../index'

const getConnectionPageKey = ({ connection, page }) => {
    return `${connection.model}~${connection.id}~${page}`
}

const getPathFromSubject = ({ subject }) => {
    return `/media/${subject.id}`
}

const getRelationOptions = () => {
    return constants.relationOptions
}

const getSubjectFromId = ({ id }) => {
    return {
        model: constants.model,
        id,
    }
}

const getSubjectFromRec = ({ rec }) => {
    if (!rec) return null
    return {
        model: constants.model,
        id: rec._id,
    }
}

const isOwner = ({ memberAuthed, rec }) => {
    if (!rec) return false
    const subject = getSubjectFromRec({ rec })
    return model.helper('connection.isConnectedBySubject', { connections: rec.connections, relation: 'uploader', subject })
}

model.registerHelpers(constants.model, {
    getConnectionPageKey,
    getPathFromSubject,
    getRelationOptions,
    getSubjectFromId,
    getSubjectFromRec,
    isOwner,
})