import React from 'react'

import { ReactComponent as ArrowGoIcon } from '../../icon/arrow-go.svg'

export default function ArrowGo({ className = '', onClick }) {

    const handleClick = (e)=>{
        e.stopPropagation()
        if (onClick) onClick()
    }
    return (
        <button className="cursor-pointer relative group rounded-full w-8 h-8 flex flex-row items-center justify-center hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-lime-400 focus:outline-2 focus:outline-offset-2 focus:outline-lime-400 transition"
            onClick={handleClick}>
            <ArrowGoIcon className="h-4 w-4 text-lime-500" aria-hidden="true" />
            <div className="hidden group-hover:flex absolute h-8 px-2 items-center -left-2 bg-slate-950 rounded top-9">
                <div className="text-sm font-normal text-white whitespace-nowrap">
                    View page
                </div>
            </div>
        </button>
    )
}