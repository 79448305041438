import React from 'react'

export default function ButtonIcon({ className = '', children, disabled, onClick, shape, size, theme, type }) {

    let enabledClass,
        shapeClass,
        sizeClass,
        textClass,
        themeClass,
        shadowClass = 'shadow-none hover:shadow-lg'
        
    switch (theme){
        case 'light': {
            themeClass = 'bg-slate-300 hover:bg-slate-800 text-lime-50 hover:text-white hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-lime-500 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-lime-500 shadow-sm'
            break
        }
        case 'danger': {
            themeClass = 'bg-rose-700 hover:bg-rose-600 text-rose-50 hover:text-white hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-lime-500 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-rose-500 shadow-sm'
            break
        }
        case 'primary': {
            themeClass = 'bg-lime-700 hover:bg-lime-600 text-lime-50 hover:text-white hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-lime-500 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-lime-500 shadow-sm'
            break
        }
        case 'transparent': {
            shadowClass = 'shadow-none'
            themeClass = 'bg-transparent hover:bg-transparent text-gray-600 hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-lime-500 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-lime-500'
            break
        }
        default: {
            themeClass = 'bg-slate-900 hover:bg-slate-800 text-lime-50 hover:text-white hover:outline hover:outline-2 hover:outline-offset-2 hover:outline-lime-500 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-lime-500 shadow-sm'
        }
    }

    switch (shape){
        case 'round':
            shapeClass = 'rounded-full'
            break
        default:
            shapeClass = 'rounded-full'
    }

    switch (size){
        case 'sm-symmetric':
            sizeClass = 'w-8 h-8 flex items-center justify-center'
            textClass = 'font-semibold'
            break
        default: // md
            sizeClass = 'w-8 h-8 flex items-center justify-center'
            textClass = 'font-semibold'
            break
    }

    if (disabled){
        shadowClass = 'shadow-none'
        enabledClass = 'opacity-50 focus:outline-transparent pointer-events-none'
    } else {
        enabledClass = 'cursor-pointer shadow-sm hover:shadow-xl transition ease-in-out'
    }

    return (
        <button
            disabled={disabled}
            type={type || 'button'}
            className={`group group/button flex flex-row items-center justify-center whitespace-nowrap transition ${enabledClass} ${shadowClass} ${shapeClass} ${sizeClass} ${themeClass} ${textClass} ${className}`}
            onClick={onClick}
        >{children}</button>
    )
    
}