import React, { useState } from 'react'

import ButtonIcon from '../Elements/ButtonIcon'
import SubjectNugget from '../SubjectNugget'
import SubjectSelector from '../SubjectSelector'

import model from '../../model'
import utilType from '../../utility/type'

import { ReactComponent as AddIcon } from '../../icon/add.svg'
import { ReactComponent as CloseIcon } from '../../icon/close.svg'

export default function ConnectionRelation({ isEditable, relation, relationClassName, handleConnectionAdd, handleConnectionRemove, subjects = [], theme = 'dark' }) {

    const subjectType = utilType.get(subjects)
    if (subjectType === 'obj') {
        subjects = [subjects]
    }

    const [isAddConnectionOpen, setIsAddConnectionOpen] = useState(false)

    const handleDeselect = async ({ subject }) => {

        await handleConnectionRemove({
            relation,
            subject
        })
        /*

        const foundInd = subjects.findIndex(sub => {
            return sub.id === subject.id
        })

        const subjectsNew = [
            ...subjects.slice(0, foundInd),
            ...subjects.slice(foundInd + 1)
        ]

        setRelationSubjects({ relation, subjects: subjectsNew })
        */
    }

    const handleSelect = async ({ subject }) => {

        await handleConnectionAdd({
            relation,
            subject
        })
        /*

        const foundInd = subjects.findIndex(sub => {
            return sub.id === subject.id
        })

        const subjectsNew = [
            ...subjects.slice(0, foundInd),
            ...subjects.slice(foundInd + 1)
        ]

        setRelationSubjects({ relation, subjects: subjectsNew })
        */
    }

    return (
        <div className="flex flex-col items-stretch relative px-6 gap-1.5">
            <div className="flex flex-row items-center gap-2">
                <div className={`font-semibold text-sm ${theme === 'light' ? 'text-white' : 'text-slate-800'}`}>
                    {model.helper('connection.getRelationLabel', { relation })}
                </div>
            </div>

            <div className="flex flex-col items-stretch gap-2">
                {subjects && subjects.map((subject, i) => {
                    return (
                        <SubjectNugget
                            handleDeselect={handleDeselect}
                            isEditable={isEditable}
                            key={i}
                            selected={true}
                            subject={subject}
                        />
                    )
                })}
                {isEditable && (
                    isAddConnectionOpen ? (
                        <div />
                    ) : (
                        <ButtonIcon onClick={() => setIsAddConnectionOpen(true)}>
                            <AddIcon className="text-white w-4 h-4" />
                        </ButtonIcon>
                    )
                )}
            </div>

            {isAddConnectionOpen && (
                <div className="relative">
                    <div className="bg-white z-20 rounded-lg flex flex-col">
                        <div className="flex flex-row items-center bg-slate-800 rounded-t-lg">
                            <div className="px-4 py-3 ">
                                <div className="text-base font-semibold text-slate-200 uppercase">SELECT SUBJECTS</div>
                            </div>
                            <ButtonIcon className="ml-auto mr-2" theme="transparent"
                                onClick={() => setIsAddConnectionOpen(false)}>
                                <CloseIcon className="text-white w-6 h-6" />
                            </ButtonIcon>
                        </div>

                        <div className="px-4 py-4 border-x-2 border-b-2 rounded-b-lg border-slate-800 flex flex-col gap-4">
                            <div className="flex flex-col grow items-stretch gap-1.5">
                                <SubjectSelector
                                    handleSelect={handleSelect}
                                    subjects={subjects}
                                    subjectModel={model.helper('connection.getRelationSubjectModel', {relation})} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}