import constants from './constants'
import model from '../index'

const all = ({state})=>{
	if (!state[constants.reduxStore].recs) return []
	return Object.values(state[constants.reduxStore].recs)
}

const authedMember = ({state})=>{
	if (!state[constants.reduxStore].authId) return null
	const authId = state[constants.reduxStore].authId

	if (!state[constants.reduxStore].recs[authId]) return null
	return state[constants.reduxStore].recs[authId]
}

const isAdmin = ({state})=>{
	if (!state[constants.reduxStore].authId) return false
	const authId = state[constants.reduxStore].authId
	if (!state[constants.reduxStore].recs[authId]) return false
	return state[constants.reduxStore].recs[authId].roles.includes('admin')
}

const isMember = ({state})=>{
	if (!state[constants.reduxStore].authId) return false
	return true
}

const isSelf = ({state, memberId})=>{
	if (!state[constants.reduxStore].authId) return false
	const authId = state[constants.reduxStore].authId
	if (!state[constants.reduxStore].recs[authId]) return false
	return memberId === authId
}

const members = ({state})=>{
	if (!state[constants.reduxStore].recs) return []
	return Object.values(state[constants.reduxStore].recs).filter(rec => {
		if (rec.roles && rec.roles.includes('approved')) return true
		return false
	})
}

const metrics = ({state})=>{
	return state[constants.reduxStore].metrics
}

const recById = ({state, id})=>{
	if (!state[constants.reduxStore].recs[id]) return null
	return state[constants.reduxStore].recs[id]
}

const recsByIds = ({state, ids})=>{
	if (!ids) return []

	return ids.map(id => {
		if (!state[constants.reduxStore].recs[id]) return null
		return state[constants.reduxStore].recs[id]
	})
}

const recsByList = ({state, list})=>{
	const listKey = model.helper('member.getListIndex', list)

	if (!state[constants.reduxStore].list[listKey]) return []

	const ids = state[constants.reduxStore].list[listKey]

	return ids.map(id => {
		if (!state[constants.reduxStore].recs[id]) return null
		return state[constants.reduxStore].recs[id]
	})
}

const recPages = ({ state }) => {
    if (!state[constants.reduxStore].pageIds) return {}

    const pageIds = state[constants.reduxStore].pageIds

    let page = 1, pages = {}

    while (pageIds[page]){
        pages[page] = pageIds[page].map(id => {
            if (!state[constants.reduxStore].recs[id]) return null
            return state[constants.reduxStore].recs[id]
        })
        page++
    }

    return pages
}

const searchRecs = ({state})=>{

	if (!state[constants.reduxStore].searchList) return []

	const ids = state[constants.reduxStore].searchList

	return ids.map(id => {
		if (!state[constants.reduxStore].recs[id]) return null
		return state[constants.reduxStore].recs[id]
	})
}

model.registerSelectors(constants.model, {
	all,
	authedMember,
	isAdmin,
	isMember,
	isSelf,
	members,
	metrics,
	recById,
	recsByList,
	recsByIds,
	recPages,
	searchRecs,
})