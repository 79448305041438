import React from 'react'

import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'

import { ReactComponent as MemberIcon } from '../../icon/member.svg'

import Block from '../Block'
import Button from '../Elements/Button'

import model from '../../model'

export default function Panel() {

    /*
    const handleChapterMigrateConnections = async ()=>{
        console.log('migrate chapter!')
		try {
			await model.action('app.setLoading', true)
			await model.action('chapter.migrateMedia')
			await model.action('app.setLoading', false)
		} catch(e) {
			await model.action('app.setLoading', false)
			const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })
		}
	}
    */

    const handleSendUpdate = async ()=>{
        console.log('migrate chapter!')
		try {
			await model.action('app.setLoading', true)
			await model.action('member.sendUpdate')
			await model.action('app.setLoading', false)
		} catch(e) {
			await model.action('app.setLoading', false)
			const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })
		}
	}

    return (
        <Frame>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <MemberIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    Panel
                </div>
            </FrameTitle>
            <div className="w-full bg-white flex flex-row justify-center items-center">
                <Block>
                    <Button onClick={handleSendUpdate}>Send Update</Button>
                </Block>
            </div>
        </Frame>
    )
}