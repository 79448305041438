import constants from './constants'
import model from '../index'

const all = ({state})=>{
	if (!state[constants.reduxStore].recs) return []

	return Object.values(state[constants.reduxStore].recs)
}

const recById = ({state, id})=>{
	if (!state[constants.reduxStore].recs || !state[constants.reduxStore].recs[id]) return null
	return state[constants.reduxStore].recs[id]
}

const recsPublished = ({state})=>{
	if (!state[constants.reduxStore].recs || !state[constants.reduxStore].publishedList) return []
	const recs = state[constants.reduxStore].publishedList.map(id => {
		return state[constants.reduxStore].recs[id]
	})
	return recs
}

model.registerSelectors(constants.model, {
	all,
	recById,
	recsPublished,
})