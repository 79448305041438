import React, {useEffect, useRef} from 'react'

import { ReactComponent as AlertCircleIcon } from '../../icon/alert-circle.svg'
import { ReactComponent as CircleCheckIcon } from '../../icon/circle-check.svg'
import { ReactComponent as CloseIcon } from '../../icon/close.svg'

import { Transition } from '@headlessui/react'

import Button from '../Elements/Button'

const showPeriod = 4000

export default function Alert({ className, handleClose, message, show, theme }) {
    let colors

    const alertRef = useRef({
        message, 
        theme,
    })
    if (show){
        alertRef.current = {
            message,
            theme,
        }
    }

    let alert = alertRef.current

    switch (alert.theme){
        case 'danger': {
            colors = {
                bg: 'bg-rose-50',
                icon: 'text-rose-400',
                message: 'text-rose-800',
                closeBg: 'bg-rose-50',
                closeIcon: 'text-rose-700',
                closeHoverBg: 'bg-rose-100',
                closeFocusRing: 'ring-rose-600',
                closeFocusRingOffset: 'ring-offset-rose-50'
            }
            break
        }
        case 'primary': {
            colors = {
                bg: 'bg-sky-50',
                icon: 'text-sky-400',
                message: 'text-sky-800',
                closeBg: 'bg-sky-50',
                closeIcon: 'text-sky-700',
                closeHoverBg: 'bg-sky-100',
                closeFocusRing: 'ring-sky-600',
                closeFocusRingOffset: 'ring-offset-sky-50'
            }
            break
        }
        default: {
            colors = {
                bg: 'bg-lime-50',
                icon: 'text-lime-400',
                message: 'text-lime-800',
                closeBg: 'bg-lime-50',
                closeIcon: 'text-lime-700',
                closeHoverBg: 'bg-lime-100',
                closeFocusRing: 'ring-lime-600',
                closeFocusRingOffset: 'ring-offset-lime-50'
            }
        }
    }

    let Icon
    switch (alert.theme){
        case 'danger': {
            Icon = AlertCircleIcon
            break
        }
        case 'primary': {
            Icon = CircleCheckIcon
            break
        }
        default: {
            Icon = CircleCheckIcon
        }
    }

    useEffect(()=>{
        if (!show) return
        setTimeout(()=>{
            handleClose()
        }, showPeriod)
    }, [handleClose, show])

    return (
        <Transition
            className="fixed top-20 right-5 z-50"
            enter="transition ease-out duration-300"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-24"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="transform opacity-100 sm:translate-x-0"
            leaveTo="transform opacity-0 sm:translate-y-0 sm:translate-x-2"
            show={show}
        >
            <div className={`rounded-lg shadow-lg p-2 ${colors.bg}`}>
                <div className="flex flex-row gap-3 items-center">
                    <Icon className={`h-5 w-5 ml-2 ${colors.icon}`} aria-hidden="true" />
                    <p className={`text-base font-medium ${colors.message}`}>{alert.message}</p>
                    <Button
                        type="button"
                        theme="transparent"
                        shape="round"
                        size="sm-symmetric"
                        className={`${colors.closeBg} ${colors.closeIcon} hover:${colors.closeHoverBg} focus:${colors.closeFocusRing} focus:${colors.closeFocusRingOffset}`}
                        onClick={handleClose}
                    >
                        <span className="sr-only">Dismiss</span>
                        <CloseIcon className="h-5 w-5" aria-hidden="true" />
                    </Button>
                </div>
            </div>
        </Transition>
    )
}