import React from 'react'

export default function FormField({ children, className = '', error, label, labelClassName = '', name, size, theme, type = 'text', value }) {

    const textClass = error ? 'text-rose-700': 'text-slate-800' 
    let sizeClass = '', 
        labelSizeClass = ''

    switch (size){
        case 'xl':
            sizeClass = 'rounded-xl'
            labelSizeClass = 'text-xl'
            break
        default:
            labelSizeClass = 'text-base'
            sizeClass = 'rounded-lg'
    }

    return (
        <div className={`flex flex-col md:flex-row md:items-start gap-4 bg-slate-100 ${sizeClass} ${className}`}>
            <label className={`md:w-[140px] lg:w-[180px] xl:w-[240px] pt-4 md:pt-2.5 px-4 text-slate-700 font-medium ${labelSizeClass} ${labelClassName} ${textClass}`} for={name}>
                {label}
            </label>
            {children}
        </div>
    )
}
