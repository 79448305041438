import React from 'react'

import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'

import { ReactComponent as AlertIcon } from '../../icon/alert-circle.svg'

export default function NotAuthorized() {

    return (
        <Frame>
            <FrameTitle>
                <AlertIcon className="w-6 h-6 text-rose-400" />
                <div className="text-white font-semibold text-base">
                    not authorized
                </div>
            </FrameTitle>
            <div className="w-full bg-white flex flex-row items-stretch">
                <div className="font-semibold text-base p-5">
                    you are not authorized for this area
                </div>
            </div>
        </Frame>
    )
}