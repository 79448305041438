import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Loader from '../Elements/Loader'
// import { ReactComponent as LoveIcon } from '../../icon/heart-empty.svg'
import SubjectNugget from '../SubjectNugget'

import model from '../../model'

export default function GrixRowConnections({ subject, pageSubject, pathSuffix }) {
    const [loading, setLoading] = useState(false)

    const rec = useSelector(state => model.selector('subject.rec', { state, subject }))

    const showSubjectsNum = 2

    useEffect(() => {
        //get top 3 in each connection section
        let subjects = []
        if (rec.connections){
            Object.values(rec.connections).forEach(connections => {
                if (connections && connections.length > 0){
                    for (let i=0, stop=connections.length > showSubjectsNum? showSubjectsNum: connections.length; i<stop; i++){
                        subjects.push(connections[i])
                    }
                }
            })
        }

        (async () => {
            try {
                setLoading(true)
                await model.action('subject.getSubjectsRecs', { subjects })
                setLoading(false)
            } catch (e) {
                setLoading(false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, []) // eslint-disable-line

    let sections = []
    rec && rec.connections && Object.keys(rec.connections).forEach((relation, i) => {
        const count = rec.connections[relation].length
        if (count > 0){
            const connections = rec.connections[relation]
            let subjects = []
            for (let i=0, stop=connections.length > showSubjectsNum? showSubjectsNum: connections.length; i<stop; i++){
                subjects.push(connections[i])
            }
            sections.push({
                count,
                label: model.helper('connection.getRelationLabel', { relation }),
                subjects,
                value: relation
            })
        }
    })

    return (
        <div className="flex flex-col gap-4 items-stretch relative px-6 pt-6 md:px-0 md:pt-0">
            {sections.map(section => {
                if (pageSubject){
                    const found = section.subjects.find(sub => {
                        return sub.model === pageSubject.model && sub.id === pageSubject.id
                    })
                    if (found){
                        if (section.subjects.length === 1) return <React.Fragment/>
                    }
                }
                return (
                    <div className="flex flex-col gap-1.5 items-stretch">
                        <div className="flex flex-row items-end gap-1.5">
                            <div className="font-semibold text-sm md:text-sm leading-normal text-white">
                                {section.label}
                            </div>
                            <div className="font-semibold text-lime-500 text-xs leading-normal">
                                {section.count}
                            </div>
                        </div>
                        {section.subjects.map((sub, i) => {
                            if (pageSubject && sub.model === pageSubject.model && sub.id === pageSubject.id) return <React.Fragment/>
                            return (
                                <SubjectNugget
                                    isEditable={false}
                                    key={i}
                                    selected={false}
                                    subject={sub}
                                />
                            )
                        })}
                        
                    </div>
                )
            })}

            <div className={`${loading ? 'opacity-100' : 'opacity-0 pointer-events-none'} flex flex-row items-center justify-center absolute top-0 bottom-0 left-0 right-0 bg-slate-900/80`}>
                <Loader />
            </div>
        </div>

        

            
            
    )
}