import React from 'react'

export default function Grixis({ children }) {

    /*

    const ary = ['bg-sky-200', 'bg-emerald-200', 'bg-lime-200','bg-sky-200', 'bg-emerald-200', 'bg-lime-200','bg-sky-200', 'bg-emerald-200', 'bg-lime-200','bg-sky-200', 'bg-emerald-200', 'bg-lime-200','bg-sky-200', 'bg-emerald-200', 'bg-lime-200','bg-sky-200', 'bg-emerald-200', 'bg-lime-200','bg-sky-200', 'bg-emerald-200', 'bg-lime-200','bg-sky-200', 'bg-emerald-200', 'bg-lime-200','bg-sky-200', 'bg-emerald-200', 'bg-lime-200','bg-sky-200', 'bg-emerald-200', 'bg-lime-200','bg-sky-200', 'bg-emerald-200', 'bg-lime-200','bg-sky-200', 'bg-emerald-200', 'bg-lime-200','bg-sky-200', 'bg-emerald-200', 'bg-lime-200',]

    {ary.map((a, i) => {
        let size = [1,1]
        if (i % 4 === 0) size[0] = 2
        if (i % 3 === 0) size[1] = 2

        return (
            <Grix bgColor={a} size={size}>
                hello world
            </Grix>
        )
    })}

    */

    return (
        <div className="grid grid-flow-dense grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
            {children}
        </div>
    )
}