import constants from './constants'

const state = {
	recs: {},
	searchList: null,
}

export const reducer = (st = state, action)=>{
	switch (action.type){
		case `${constants.reducerPrefix}_REMOVE`: {
			const {id} = action.payload

			const { [id]: _, ...recs } = st.recs

			return {...st, recs}
		}
		case `${constants.reducerPrefix}_REC_SET`: {
			const {rec} = action.payload

			const recs = {
				...st.recs,
				[rec._id]: rec,
			}

			return {...st, recs}
		}
		case `${constants.reducerPrefix}_ALL_SET`: {
			const {recs} = action.payload

			let write = {}
			recs.forEach(rec => {
				write[rec._id] = rec
			})
			const recsNew = {
				...st.recs,
				...write,
			}
			return {...st, recs: recsNew}
		}
		case `${constants.reducerPrefix}_SEARCH_RECS_SET`: {
			const {recs} = action.payload

			let write = {},
				listNew = []
			recs.forEach(rec => {
				write[rec._id] = rec
				listNew.push(rec._id)
			})
			const recsNew = {
				...st.recs,
				...write,
			}
			return {...st, recs: recsNew, searchList: listNew}
		}
		case `${constants.reducerPrefix}_SET_RECS_BY_PAGE`: {
			const { page, recs } = action.payload

			let write = {},
				ids = []

			recs.forEach(rec => {
				write[rec._id] = rec
				ids.push(rec._id)
			})

			const pageIdsNew = {
				...st.pageIds,
				[page]: ids
			}

			const recsNew = {
				...st.recs,
				...write,
			}
			return { ...st, recs: recsNew, pageIds: pageIdsNew }
		}
		default:
			return st
  }
}