import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import model from '../../model'

import DotMenu from '../Elements/DotMenu'

import Container from '../Elements/Container'
import Button from '../Elements/Button'
import ButtonIcon from '../Elements/ButtonIcon'
import FormField from '../Elements/FormField'
import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'
import Input from '../Elements/Input'
import Textarea from '../Elements/Textarea'

import { ReactComponent as MemberIcon } from '../../icon/member.svg'
import { ReactComponent as CloseIcon } from '../../icon/close-icon.svg'

export default function MemberAddPage() {

    const [rec, setRec] = useState({})
    const navigate = useNavigate()

    const actions = [{
        label: 'clear',
        onClick: () => {

        }
    }]

    const handleClose = () => {
        navigate(`/members`)
    }

    const handlePropSet = (prop, val) => {
        setRec({
            ...rec,
            [prop]: val
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            await model.action('app.setLoading', true)
            const member = await model.action('member.add', {
                about: rec.about,
                email: rec.email,
                firstName: rec.firstName,
                lastName: rec.lastName,
            })

            await model.action('app.setLoading', false)

            navigate(`/member/${member._id}`)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setAlert', {
                message: model.helper('app.getErrorMessage', e),
                theme: 'danger'
            })
        }
    }

    return (
        <Frame>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <MemberIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    members
                </div>
                <div className="pl-2.5 py-0 bg-lime-600 rounded flex flex-row gap-1 items-center">
                    <div className=" text-white text-base font-semibold">
                        add
                    </div>
                    <ButtonIcon className="" onClick={handleClose} theme="transparent">
                        <CloseIcon className="text-white/50 group-hover:text-white w-3 h-3" />
                    </ButtonIcon>
                </div>
                <div className="">
                    <DotMenu options={actions} direction="right" theme="light" />
                </div>
            </FrameTitle>
            <form className="w-full bg-white" onSubmit={handleSubmit}>
                <Container className="flex flex-col gap-6 py-6 md:py-10 lg:py-12 xl:py-14">
                    <FormField
                        label="firstName"
                        name="firstName"
                        size="md">
                        <Input
                            autoFocus={true}
                            className="text-2xl"
                            name="firstName"
                            placeholder="first name"
                            value={rec.firstName}
                            setValue={val => handlePropSet('firstName', val)}
                            size="md" />
                    </FormField>
                    <FormField
                        label="lastName"
                        name="lastName"
                        size="md">
                        <Input
                            autoFocus={true}
                            className="text-2xl"
                            name="lastName"
                            placeholder="last name"
                            value={rec.lastName}
                            setValue={val => handlePropSet('lastName', val)}
                            size="md" />
                    </FormField>
                    <FormField
                        label="email"
                        name="email"
                        size="md">
                        <Input
                            autoFocus={true}
                            className="text-2xl"
                            name="email"
                            placeholder="email"
                            type="email"
                            value={rec.email}
                            setValue={val => handlePropSet('email', val)}
                            size="md" />
                    </FormField>
                    <FormField
                        label="about"
                        name="about"
                        size="md">
                        <Textarea 
                            placeholder="about" 
                            setValue={val => handlePropSet('about', val)} 
                            size="md" 
                            value={rec.about} />
                    </FormField>
                    
                    <div className="flex flex-row justify-end">
                        <Button className="ml-auto" size="md" type="submit">add member</Button>
                    </div>
                </Container>
            </form>
        </Frame>
    )
}