
let cloudinary = {
	cloudName: 'everspring-collective',
	uploadPresets: {
		chapterMedia: 'chapter-media',
		leafMedia: 'leaf-media',
		memberImage: 'member-image',
		media: 'media'
	},
	uploadPresetDefault: 'memberImage',
}

let stripe = {
	publicKey: 'pk_test_51N5e7DJgJ2dO0RBuffbhSpLEMLVZHetml9KckBfkGrrf0RuNDfwt3e6GPbk2XMWyAKkDcgeo6mxKTtoovB9Kt0gg00OcrGxDEc',
}

let config = {
	cloudinary,
	stripe,
}

if (process.env.REACT_APP_ENV === 'prod'){
	config = {
		...config,
		apiHost: '/.netlify/functions',
	}
	config.stripe.publicKey = 'pk_live_51N5e7DJgJ2dO0RBubHRIEltdDwWv0uSu671ntlqk2mfbPVGxB6QQpCVzLJVl693HU2zV852lnLB0sFqe5nO0ZMZI0070qOsjYy'
} else {
	config = {
		...config,
		apiHost: '/.netlify/functions',
		//apiHost: '/api',
	}
	config.stripe.publicKey = 'pk_test_51N5e7DJgJ2dO0RBuffbhSpLEMLVZHetml9KckBfkGrrf0RuNDfwt3e6GPbk2XMWyAKkDcgeo6mxKTtoovB9Kt0gg00OcrGxDEc'
}

export default config
