import React from 'react'
import { useSelector } from 'react-redux'

import model from '../../model'

import { ReactComponent as MemberIcon } from '../../icon/member.svg'

export default function PageMember() {

    const memberAuthed = useSelector(state => model.selector('member.authedMember', { state }))

    const handleLoginClick = async (e)=>{
        e.stopPropagation()
        await model.action('app.setModal',{
            modal: 'access',
        })
    }

    const handleMemberClick = async ()=>{
        
    }

    const imageStyle = {
		backgroundImage: `url(${model.helper('member.getImageUrl', { member: memberAuthed })})`
	}

    if (memberAuthed){
        return (
            <div className={`cursor-pointer group group-hover:outline group-hover:outline-lime-400 group-hover:outline-offset-2 opacity-80 group-hover:opacity-100 bg-cover rounded-full w-11 h-11 shrink-0 flex flex-row items-center justify-center transition ease-in-out`}
                style={imageStyle}
                onClick={handleMemberClick}>
            </div>
        )
    }

    return (
        <div className="cursor-pointer group group-hover:outline group-hover:outline-lime-400 rounded-full w-11 h-11 shrink-0 flex flex-row items-center justify-center transition ease-in-out"
            onClick={handleLoginClick}>
            <MemberIcon className="text-sky-50 group-hover:text-white transition ease-in-out w-5 h-5"/>
        </div>
    )
}