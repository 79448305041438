import constants from './constants'
import model from '../index'

const getFamilyIndex = ({family})=>{
	return `${family}`
}

const getFamilyListLabel = ({family})=>{
	return constants.familyLabels[family].list
}

const getFamilyRecLabel = ({family})=>{
	return constants.familyLabels[family].rec
}

const getHandleFromId = ({id})=>{
	for (let i in constants.eventHandles){
		if (constants.eventHandles[i] === id.toString? id.toString(): id) return i
	}
	return null
}

const getIdFromHandle = ({handle})=>{
	if (constants.eventHandles[handle]) return constants.eventHandles[handle]
	return null
}

const getIdFromLink = ({link})=>{
	if (link.includes('everspringcollective.com/tree')){
		const match = link.match(/\/tree\/([a-z0-9]+)/)
		if (!match || match.length < 1) return null
		return match[1]
	}
	return null
}

const getLink = ({event})=>{
	const eventId = event._id
	const handle = getHandleFromId({id: eventId})
	return handle? `/${handle}`: `/event/${eventId}`
}

const getOverview = ({tree})=>{
	if (!tree.branches || tree.branches.length < 1) return ''
	return tree.branches[0].body
}

const getRemainingFromPass = ({pass})=>{
	if (pass.spots){
		if (pass.booked) return pass.spots - pass.booked
		else return pass.spots
	}
	return 0
}

model.registerHelpers(constants.model, {
	getFamilyIndex,
	getFamilyListLabel,
	getFamilyRecLabel,
	getHandleFromId,
	getIdFromHandle,
	getIdFromLink,
	getLink,
	getOverview,
	getRemainingFromPass,
})