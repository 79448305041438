
const get = (key)=>{
	if (localStorage[key] === undefined) return undefined
	return JSON.parse(localStorage[key])
}

const set = (key, val)=>{
	if (val === undefined){
		delete localStorage[key]
		return undefined
	}
	localStorage[key] = JSON.stringify(val)
	return localStorage[key]
}

const exp = {
	get,
	set,
}

export default exp