const collection = 'location'

const fields = {
	_id:{
		type: 'obj',
	},
	connections:{
		type: 'obj', 
		key: {
			type: 'str', 
			label: 'relation'
		}, // uploader, featured (members in media), associated (chapters / events),
		value:{
			type:'ary',
			item: {
				type:'obj',
				shape:{
					model: {
						type: 'str'
					},
					id: {
						type: 'obj'
					}
				}
			},
		}
	},
	address: {
		type: 'obj',
		shape: {
			street: {
				type:'str',
			},
			city: {
				type: 'str',
			},
			state: {
				type: 'str',
			},
			country: {
				type: 'str',
			},
			postal: {
				type: 'str',
			},
		},
	},
	label: {
		type: 'str',
	},
	link: {
		type: 'str',
	},
	history: {
		type: 'ary',
		item: {
			type: 'obj',
			shape: {
				action: {
					type: 'str',
				},
				payload: {
					type: 'obj',
				},
				when: {
					type: 'date',
				},
				who: {
					type: 'id'
				},
			},
		},
	},
}

const model = 'location'

const reducerPrefix = 'LOCATION'
const reduxStore = 'location'

const exp = {
	collection,
	fields,
	model,
	reducerPrefix,
	reduxStore,
}

export default exp