const collection = 'chapter'

const fields = {
	_id: {
		type: 'obj',
	},
	body: {
		type: 'str',
		len: [1, 5000],
	},
	connections: {
		type: 'obj',
		key: {
			type: 'str',
			label: 'relation'
		}, // uploader, featured (members in media), associated (chapters / events),
		value: {
			type: 'ary',
			item: {
				type: 'obj',
				shape: {
					model: {
						type: 'str'
					},
					id: {
						type: 'obj'
					}
				}
			},
		}
	},
	created: {
		type: 'obj',
		shape: {
			when: {
				type: 'date',
			},
			who: {
				type: 'obj',
			}
		}
	},
	mediaId: { // primary media image
		type: 'obj',
	},
	subject: { // chapter is attached to a subject (story)
		type: 'obj',
		shape: {
			model: {
				type: 'str',
			},
			id: {
				type: 'obj',
			}
		}
	},
	title: {
		type: 'str',
		len: [1, 500],
	},
	updated: {
		type: 'obj',
		shape: {
			when: {
				type: 'date',
			},
			who: {
				type: 'obj',
			}
		}
	},
	when: {
		type: 'ary', // [start [MM, YYYY], end [MM, YYYY]]
	}
}

const model = 'chapter'

const reducerPrefix = 'CHAPTER'
const reduxStore = 'chapter'

const exp = {
	collection,
	fields,
	model,
	reducerPrefix,
	reduxStore,
}

export default exp