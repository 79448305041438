import React from 'react'

import {useSelector} from 'react-redux'

import model from '../../model'

import ConnectionRelation from '../ConnectionRelation'
import Button from '../Elements/Button'

import ModalTitle from './ModalTitle'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'

export default function ModalConnectionEdit ({handleClose, handleComplete, subject}) {

    const rec = useSelector(state => model.selector('subject.rec', { state, subject }))

    const connections = rec && rec.connections ? rec.connections : {}

    const options = model.helper('connection.getRelationOptionsForSubject', {subject})

    const handleConnectionAdd = async (connection)=>{
        try {
            //setLoading(true)
            await model.action('subject.connectionAdd', {
                subject,
                connection,
            })
            await model.action('app.setAlert', {
                message: 'connection added',
                theme: 'success'
            })
            //setLoading(false)
        } catch (e) {
            //setLoading(false)
            const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })
        }
    }

    const handleConnectionRemove = async (connection)=>{
        try {
            //setLoading(true)
            await model.action('subject.connectionRemove', {
                subject,
                connection,
            })
            await model.action('app.setAlert', {
                message: 'connection removed',
                theme: 'success'
            })
            //setLoading(false)
        } catch (e) {
            //setLoading(false)
            const error = model.helper('app.getErrorMessage', e)
            await model.action('app.setAlert', {
                message: error,
                theme: 'danger'
            })
        }
    }

	const handleSubmit = async (e)=>{
        e.preventDefault()
		handleClose()
	}

	return (
		<form onSubmit={handleSubmit}>
            <ModalTitle handleClose={handleClose}>
				<div className="flex flex-col items-center gap-2">
					<div className="text-xl text-white font-semibold uppercase leading-6">
						Add Connections
					</div>
				</div>
			</ModalTitle>
            <ModalBody>
                <div className="flex flex-col gap-5">
                {options.map(relation => {
                    const subjects = connections[relation.value] ? connections[relation.value] : []
                    return (
                        <div className="bg-slate-100 py-3 rounded-md">
                            <ConnectionRelation
                                isEditable={relation.value !== 'uploader'}
                                handleConnectionAdd={handleConnectionAdd}
                                handleConnectionRemove={handleConnectionRemove}
                                key={relation.value}
                                relation={relation.value}
                                relationClassName={'text-slate-800'}
                                subjects={subjects} />
                        </div>
                    )
                })}
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex flex-row">
                    <Button className="ml-auto" size="md" type="submit">Done</Button>
                </div>
            </ModalFooter>
		</form>
	)
}
